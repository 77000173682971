import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    TextareaAutosize,
    TextField,
    DialogContentText,
    InputAdornment,
    CircularProgress,
    Switch,
    Radio,
    RadioGroup,
    FormGroup,
    Checkbox,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import SectorDataTree from '../../../Tables/ateco_table'
// import { Autocomplete } from '@material-ui/lab'
import AddIcon from 'app/components/Icons/AddIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import Sectors from '../../../Tables/sectors_simplified'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import years_ranges from '../../../Tables/years_ranges_recruiter'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import EditFieldDialog from '../FooterProfile/EditField'
import Autocomplete from 'app/components/Autocomplete/AsyncAutocomplete'
import computeYearsRequired from 'app/functions/ComputeYearsRequired'

function JobCardWorkExperience({
    offerInfo,
    explist,
    offerguid,
    getExpList,
    appState,
}) {
    // per dialog
    const [open, setOpen] = useState(false)
    // per dialog di conferma (vuoi eliminare definitivamente?)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [loadingButton, setLoadingButton] = useState(false)
    const descriptionElementRef = useRef(null)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [expList, setExpList] = useState([])
    const [listObjects, setListObjects] = useState([])
    const [showMore, setShowMore] = useState(false)
    const [isSwitchChecked, setIsSwitchChecked] = useState(expList.length === 0)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [years_exp, setYearsExp] = useState('')

    const initUserInfo = {
        offer_guid: offerguid || '',
        job_title: offerInfo?.job_name,
        sector: offerInfo?.sector ?? [],
        years_required: null,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)

    const {
        offer_guid,
        job_title,
        sector,
        years_required,
        created,
        lastUpdate,
    } = userInfo

    const defaultSectors = [
        'Commercio al dettaglio',
        'Commercio all’ingrosso',
        'Ristorazione ed alloggio',
        'Logistica e trasporti',
        'Manufatturiero',
        'Meccanici e Carrozzieri',
        'Edilizia',
    ]
    const additionalSectors = Sectors.filter(
        (category) => !defaultSectors.includes(category)
    )

    const onSelect = (event) => {
        const months = years_ranges.filter(
            (x) => x.label === event.target.value
        )[0].months
        setYearsExp(event.target.value)
        setUserInfo({
            ...userInfo,
            years_required: months / 12,
        })
        setIsEdited(true)
    }

    // const handleSwitchToggle = async () => {
    //     /////Ask user confirmation before removing!!!
    //     if (!isSwitchChecked) {
    //         debugger
    //         const res = await ApiService.delete_allofferexp(offer_guid)
    //         if (res.ok) getExpList()
    //         else return
    //     }
    //     setIsSwitchChecked((prev) => !prev)
    // }

    const handleClickOpen = () => () => {
        const newListObjects = listObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setListObjects(newListObjects)

        setDialogText('Aggiungi')
        setOpen(true)
    }

    const handleClickUpdateDialogOpen = (expInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        setUserInfo({
            ...userInfo,
            id: expInfo.id,
            job_title: expInfo.job_title,
            sector: expInfo.sector,
            years_required: expInfo.years_required,
            lastUpdate: new Date(),
        })

        setYearsExp(computeYearsRequired(expInfo.years_required))
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        // resetValue(IscedDataTree)
        resetValue(SectorDataTree)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const onSectorSelect = (event) => {
        const { name } = event.target

        setUserInfo((prev) => {
            const updatedSectors = prev.sector.includes(name)
                ? prev.sector.filter((sector) => sector !== name)
                : [...prev.sector, name]

            return { ...prev, sector: updatedSectors }
        })
        setIsEdited(true)
    }

    const handleToggleShowMore = () => {
        setShowMore((prev) => !prev)
    }

    const handleAddExp = async () => {
        setLoadingButton(true)
        const res = await ApiService.create_offerexp(userInfo)
        if (res.ok) getExpList()
        handleClose()
        setLoadingButton(false)
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_offerexp(userInfo.id)
        if (res.ok) getExpList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        setLoadingButton(true)
        const res = await ApiService.update_offerexp(userInfo)
        if (res.ok) getExpList()
        handleClose()
        setLoadingButton(false)
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}
        if (!job_title) errors.job_title = 'Questo campo non può essere nullo'
        if (!sector.length > 0)
            errors.sector = 'Questo campo non può essere nullo'
        if (!years_required)
            errors.years_required = 'Questo campo non può essere nullo'
        return errors
    }

    const toggleOpen = (index) => {
        const newListObjects = listObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setListObjects(newListObjects)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddExp()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        setExpList(explist)

        const newListObjects = explist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setListObjects(newListObjects)
    }, [explist])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (sector.length > 0)
            sector.map((sec) => findValue(SectorDataTree, sec))
        // if(isced) findValue(IscedDataTree, isced)
    }, [userInfo])

    useEffect(() => {
        setIsSwitchChecked(expList.length === 0)
    }, [expList])

    const handleConfirmProceed = () => {
        setIsSwitchChecked((prev) => !prev)
        setOpenConfirm(false)
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleSwitchToggle = () => {
        if (!isSwitchChecked) {
            setOpenConfirm(true)
        } else {
            setIsSwitchChecked(false)
        }
    }
    const handleConfirmnoskillsProceed = async () => {
        setOpenConfirm(false)
        const res = await ApiService.delete_allofferexp(offer_guid)
        if (res.ok) {
            setIsSwitchChecked(true)
            getExpList()
        } else {
            console.error('Error deleting experience entries')
        }
    }

    const handleConfirmnoskillsClose = () => {
        setOpenConfirm(false)
    }

    const renderForm = (index) => {
        // console.log(index)
        return (
            <div className="dropdown-form profilerec">
                <div className="d-flex align-center m1">
                    <h3>Esperienza richiesta al candidato</h3>
                    <button
                        onClick={handleDialogClose}
                        // onClick={() => handleClose(index)}
                        className="close-card"
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div>
                    <Autocomplete
                        parentValue={job_title}
                        onChange={(newValue) =>
                            setUserInfo({
                                ...userInfo,
                                job_title: newValue,
                                handleFieldChange,
                            })
                        }
                        label={
                            'Il tuo ruolo (es. cuoco, operaio, impiegato amministrativo..)'
                        }
                        apiRequest={ApiService.get_joblist}
                        required
                    />
                    <p className="input-error">{formErrors.job_title}</p>
                </div>
                <div className="dropdownSection">
                    <h5>Settore in cui si è maturata esperienza</h5>
                    <p className="input-error">{formErrors.sector}</p>

                    {/* Render Default Sector Checkboxes */}
                    <div className="ExpOnboardingTags recprof">
                        <FormGroup name="sector">
                            {defaultSectors.map((category, index) => (
                                <FormControlLabel
                                    key={index}
                                    label={category}
                                    name="sector"
                                    // onChange={handleFieldChange}
                                    control={
                                        <Checkbox
                                            onChange={onSectorSelect}
                                            name={category}
                                            checked={userInfo.sector.includes(
                                                category
                                            )}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </div>

                    {/* Show More Button */}
                    <div className="text-center">
                        <Button
                            onClick={handleToggleShowMore}
                            className="underline-text"
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                boxShadow: 'none',
                                outline: 'none',
                                color: '#4b4c55',
                                fontWeight: '600',
                                textTransform: 'math-auto',
                                margin: '1rem 0',
                            }}
                        >
                            Vedi altri settori
                            {showMore ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}
                        </Button>
                    </div>

                    {/* Render Additional Sector Checkboxes if showMore is true */}
                    {showMore && (
                        <div className="ExpOnboardingTags recprof">
                            <FormGroup name="sector">
                                {additionalSectors.map((category, index) => (
                                    <FormControlLabel
                                        key={index}
                                        label={category}
                                        name="sector"
                                        control={
                                            <Checkbox
                                                onChange={onSectorSelect}
                                                name={category}
                                                checked={userInfo.sector.includes(
                                                    category
                                                )}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    )}
                </div>

                <div className="dropdownSection">
                    <h5>Quanta esperienza è richiesta in questo ruolo?</h5>
                    <p className="input-error">{formErrors.years_required}</p>
                    <FormControl
                        key={index}
                        component="fieldset"
                        className="tags-check"
                        style={{
                            marginTop: '-0.5rem',
                        }}
                    >
                        <RadioGroup
                            aria-label="Anni di esperienza"
                            value={years_exp}
                            onChange={onSelect}
                            style={{
                                marginBottom: '0.5rem',
                                fontWeight: '600',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem',
                            }}
                        >
                            {years_ranges.map((category, index) => {
                                return (
                                    <div className="yearsonboarding1">
                                        <FormControlLabel
                                            className="yearsonboarding yearsexpTag"
                                            key={index}
                                            value={category.label}
                                            control={<Radio />}
                                            label={category.label}
                                        />
                                    </div>
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>

                {/* {sector.length > 0 && (
                    <div className="options-selected-container resp">
                        {sector.map((jobSectorSelected, index) => (
                            <div
                                className="d-flex align-center tags new-box"
                                key={index}
                            >
                                <p>{jobSectorSelected}</p>
                                <button
                                    className="tag-closebtn"
                                    onClick={(e) => {
                                        handleDeleteSector(e, index)
                                    }}
                                >
                                    <Closebtnblue />
                                </button>
                            </div>
                        ))}
                    </div>
                )} */}

                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                    disabled={loadingButton}
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    {loadingButton ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                left: '5%',
                            }}
                            color="inherit"
                            size={25}
                        />
                    ) : null}
                    {dialogText}
                </Button>
            </div>
        )
    }
    const handleFieldChange = (event) => {
        setIsEdited(true)
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    // const handleConfirmClose = (confirm) => {
    //     setConfirmOpen(false)
    //     if (confirm) {
    //         handleClose()
    //     }
    // }

    return (
        <>
            <div className="">
                <div className="section-title" style={{ marginBottom: '0' }}>
                    <h3>
                        Ecco l’esperienza lavorativa che il tuo candidato deve
                        avere.{' '}
                    </h3>
                </div>

                {open && renderForm()}

                {/* {!appState && expList.length <= 0 && !open && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Inserisci le tue esperienze professionali
                                    necessarie
                                </p>
                                <button
                                    onClick={handleClickOpen()}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )} */}

                <div className="box-preview">
                    {appState ? (
                        <div className="card-preview new-box">
                            <div
                                className="section-lightGray"
                                id="skeltonExperience"
                            >
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width="50%"
                                        height="35px"
                                    />
                                </div>
                                <div>
                                    <Skeleton variant="text" width="70%" />
                                </div>
                                <div>
                                    <Skeleton variant="text" width="90%" />
                                </div>

                                <div>
                                    {' '}
                                    <Skeleton variant="text" width="30%" />
                                </div>
                                <div>
                                    <Skeleton variant="text" width="30%" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* {!open &&
                                listObjects.filter((x) => x.open)?.length <=
                                    0 && (
                                    <div className="eduRecprof section-title">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: 0,
                                                marginTop: 0,
                                            }}
                                        >
                                            <FormControlLabel
                                                label="Non è richiesta esperienza pregressa"
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent:
                                                        'space-between',
                                                    '.MuiFormControlLabel-label':
                                                        {
                                                            color: '#333333',
                                                            fontWeight: 'bold',
                                                        },

                                                    '&.MuiFormControlLabel-root':
                                                        {
                                                            marginLeft: 0,
                                                        },
                                                }}
                                                control={
                                                    <Switch
                                                        checked={
                                                            isSwitchChecked
                                                        }
                                                        onChange={
                                                            handleSwitchToggle
                                                        }
                                                        sx={{
                                                            '& .MuiSwitch-switchBase.Mui-checked':
                                                                {
                                                                    color: '#FFFFFF',
                                                                    '&:hover': {
                                                                        backgroundColor:
                                                                            'rgba(255, 255, 255, 0.08)',
                                                                    },
                                                                },
                                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                                {
                                                                    backgroundColor:
                                                                        '#F06031',
                                                                },
                                                            '& .MuiSwitch-switchBase':
                                                                {
                                                                    color: '#FFFFFF',
                                                                },
                                                            '& .MuiSwitch-track':
                                                                {
                                                                    backgroundColor:
                                                                        '#EEEEEE',
                                                                },
                                                            '& .MuiSwitch-thumb':
                                                                {
                                                                    boxShadow:
                                                                        'none',
                                                                },
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>
                                        {expList?.length < 1 &&
                                            !isSwitchChecked && (
                                                <button
                                                    className="icon-btn"
                                                    onClick={handleClickOpen()}
                                                >
                                                    <AddIcon />
                                                </button>
                                            )}
                                    </div>
                                )} */}
                            {!open && expList?.length < 1 && (
                                // !isSwitchChecked &&
                                <div className="eduRecprof section-title">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: 0,
                                            marginTop: 0,
                                        }}
                                    ></Box>
                                    <button
                                        className="icon-btn"
                                        onClick={handleClickOpen()}
                                    >
                                        <AddIcon />
                                    </button>
                                </div>
                            )}
                            {expList.map((exp, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="card-preview new-box"
                                    >
                                        {listObjects[index].open ? (
                                            renderForm(index)
                                        ) : (
                                            <>
                                                <div className="required-card">
                                                    <div className="d-flex actions-btns">
                                                        <button
                                                            onClick={handleClickUpdateDialogOpen(
                                                                exp,
                                                                index
                                                            )}
                                                            className="icon-btn"
                                                        >
                                                            <EditIcon />
                                                        </button>
                                                        <button
                                                            className="icon-btn"
                                                            onClick={() =>
                                                                handleClickOpenConfirm(
                                                                    exp.id
                                                                )
                                                            }
                                                        >
                                                            <BinIcon />
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <h3 className="bold">
                                                            {exp.job_title}
                                                        </h3>
                                                        <p>
                                                            <span>📆</span>{' '}
                                                            {computeYearsRequired(
                                                                exp.years_required
                                                            )}
                                                        </p>

                                                        {/* <p className="bold">
                                                            {/* <span>
                                                                Settore di
                                                                appartenenza:
                                                            </span>{' '} 
                                                        
                                                        </p> */}
                                                        {exp.sector
                                                            ? exp.sector.map(
                                                                  (
                                                                      el,
                                                                      index
                                                                  ) => (
                                                                      <>
                                                                          <p
                                                                              key={
                                                                                  index
                                                                              }
                                                                          >
                                                                              🏭{' '}
                                                                              {
                                                                                  el
                                                                              }{' '}
                                                                          </p>
                                                                      </>
                                                                  )
                                                              )
                                                            : 'Non specificato'}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            </div>

            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={openConfirm}
                onClose={handleConfirmnoskillsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>Sicuro?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Procedendo, vedrai offerte di lavoro meno accurate. Sei
                        sicuro di voler continuare?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmnoskillsProceed}
                        color="secondary"
                    >
                        Procedi
                    </Button>
                    <Button
                        onClick={handleConfirmnoskillsClose}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardWorkExperience
