import years_ranges from 'app/components/Tables/years_ranges_recruiter'

export default function computeYearsRequired(years) {
    const monthDifference = years * 12
    let y_req = 0

    switch (true) {
        case monthDifference < 6:
            y_req = years_ranges.filter((x) => x.months < 6)[0].label
            break
        case monthDifference >= 6 && monthDifference < 12:
            y_req = years_ranges.filter(
                (x) => x.months >= 6 && x.months < 12
            )[0].label
            break
        case monthDifference >= 12 && monthDifference < 24:
            y_req = years_ranges.filter(
                (x) => x.months >= 12 && x.months < 24
            )[0].label

            break
        case monthDifference >= 24 && monthDifference < 60:
            y_req = years_ranges.filter(
                (x) => x.months >= 24 && x.months < 60
            )[0].label

            break
        case monthDifference >= 60 && monthDifference < 120:
            y_req = years_ranges.filter(
                (x) => x.months >= 12 && x.months < 24
            )[0].label

            break
        case monthDifference >= 120:
            y_req = years_ranges.filter((x) => x.months >= 120)[0].label
            break
    }

    return y_req
}
