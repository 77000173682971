const FormTitles = [
    'PARTIAMO DALLE TUE COMPETENZE',
    'PASSIAMO ALLA TUA ISTRUZIONE',
    'CONTINUIAMO CON LA TUA ESPERIENZA LAVORATIVA',
    'ANDIAMO ORA ALLE LINGUE',
    // 'Competenze tecniche',
    '',
    'Raccontaci chi sei',
    'ANCORA QUALCHE DETTAGLIO E CI SIAMO!',
    'ULTIME INFORMAZIONI (MA IMPORTANTI)',
    'ULTIME INFORMAZIONI (MA IMPORTANTI)',
    '',
]

export default FormTitles
