import React, { useRef, useState, useEffect } from 'react'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
// scroll orizzontale
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import ShowMoreIcon from '../Icons/ShowMoreIcon'
import { CompleteFormOffer } from './CompleteForm/CompleteFormOffer'
import Skeleton from '@material-ui/lab/Skeleton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import PricingPage from './Pricing/ComingSoonPremPopUp'
import ItsMatch from './ProfileComponents/Match/ItsMach'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />
})

const SettingsSkeleton = {
    autoplay: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1290,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1.6,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
            },
        },
    ],
}

function Likes() {
    const [hideCards, setHideCards] = useState(false)
    const [offers, setOffers] = useState([])
    const [likesReceived, setLikesReceived] = useState([])
    const [guid, setGuid] = useState('')
    const [imageSrc, setImageSrc] = useState('')
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('Like ricevuti')
    const [showPricing, setShowPricing] = useState(false)
    const [sub, setSub] = useState(null)
    const [actions, setActions] = useState(false)
    const { getActiveSubscription, createMatch } = useFirestore()
    const { user } = useAuth()

    const getLikesSent = async () => {
        try {
            const response = await ApiService.get_candlikesSent(user.uid)
            const body = await response.json()
            console.log(body)

            setOffers(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLikesReceived = async () => {
        try {
            const response = await ApiService.get_candlikesReceived(user.uid)
            const body = await response.json()
            console.log(body)

            setLikesReceived(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    async function getLikes() {
        setLoading(true)
        ///for loading
        const res = await getActiveSubscription(user.uid)
        setSub(res)
        // await new Promise((resolve) => setTimeout(resolve, 5000))
        await getLikesReceived()

        // await new Promise((resolve) => setTimeout(resolve, 5000))
        await getLikesSent()

        setLoading(false)
    }

    // set last direction and decrease current index
    const swiped = async (direction, offer_guid, recruiter_guid, job_name) => {
        const res = await ApiService.update_candswipe({
            offer_guid: offer_guid,
            candidate_guid: user.uid,
            direction: direction,
        }) //add direction for swype result
        console.log(res)
        if (res.ok) {
            const body = await res.json()
            if (body?.entity?.match) {
                console.log('Offerta Matchata: ', body)
                createMatch(offer_guid, user.uid, recruiter_guid, job_name)

                // fa comparire dialog con animazione
                const image2show = likesReceived.find(
                    (x) => x[0] === offer_guid
                )
                setImageSrc(
                    image2show &&
                        image2show.length > 0 &&
                        image2show[7] !== null &&
                        image2show[7] !== '' &&
                        image2show[7] !== 'None'
                        ? image2show[7]
                        : '/images/prof.png'
                )
                setHideCards(true)
            }
        }
        getLikesReceived()
    }

    useEffect(() => {
        if (user?.uid) {
            getLikes()
        }
    }, [user])

    const TabNavItem = ({ title, id, activeTab, setActiveTab }) => {
        const handleClick = () => {
            setActiveTab(id)
        }

        return (
            <li
                className={`tab-item ${activeTab === id ? 'active' : ''}`}
                onClick={handleClick}
            >
                {title}
            </li>
        )
    }
    const TabContent = ({ id, activeTab, children }) => {
        return activeTab === id ? (
            <div className="tab-content">{children}</div>
        ) : null
    }

    const getMessage = () => {
        if (
            (sub === null || !sub?.status === 'active') &&
            activeTab === 'Like ricevuti'
        ) {
            return (
                <>
                    <span style={{ color: '#4b4c55' }}>
                        Vuoi vedere a chi piaci?
                    </span>{' '}
                    <span
                        style={{
                            color: '#ea6c3a',
                            display: 'contents',
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowPricing(true)}
                    >
                        Passa a Premium.
                    </span>
                </>
            )
        } else {
            return activeTab === 'Like ricevuti' ? (
                <span style={{ color: '#4b4c55' }}>
                    Chi è interessato al tuo profilo
                </span>
            ) : (
                <span style={{ color: '#4b4c55' }}>
                    Le offerte che ti interessano
                </span>
            )
        }
    }

    return (
        <div className={hideCards ? 'AnimationContainer' : 'container'}>
            <PricingPage open={showPricing} setOpen={setShowPricing} />
            {!hideCards && (
                <div className="likes-page-scroll">
                    <main className="h-100 d-flex flex-column padding-inline likes-page">
                        <div
                            className="tab-section"
                            style={{ marginBottom: '10rem' }}
                        >
                            <ul className="tab-menu plan likes d-flex space-between">
                                <>
                                    <TabNavItem
                                        title="Like ricevuti"
                                        id="Like ricevuti"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="Like inviati"
                                        id="Like inviati"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </>
                            </ul>
                            {loading ? (
                                <div className="header-message">
                                    <Skeleton
                                        variant="text"
                                        width={500}
                                        height={50}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={400}
                                        height={50}
                                    />
                                </div>
                            ) : (
                                <div className="header-message">
                                    <h1 style={{ flexDirection: 'column' }}>
                                        {getMessage()}
                                    </h1>
                                </div>
                            )}

                            <div
                                className={`content-tab plan ${
                                    sub === null || !sub?.status === 'active'
                                        ? 'blurred'
                                        : ''
                                }`}
                            >
                                <TabContent
                                    id="Like ricevuti"
                                    activeTab={activeTab}
                                >
                                    <section className="m2">
                                        {/*<h1>I tuoi Like</h1>*/}

                                        <div>
                                            {/*<div className="section-title">
                                        <h2>Like ricevuti</h2>
                                    </div>*/}

                                            {loading ? (
                                                <div className="grid-container">
                                                    {/* <Slider
                                                        {...SettingsSkeleton}
                                                    > */}
                                                    {Array.from({
                                                        length: 5,
                                                    }).map((_, index) => (
                                                        <div
                                                            key={index}
                                                            className="grid-item"
                                                        >
                                                            <div className="like-card d-flex flex-column space-between">
                                                                <div className="d-flex overflow-hidden">
                                                                    <Skeleton
                                                                        variant="circle"
                                                                        width={
                                                                            100
                                                                        }
                                                                        height={
                                                                            100
                                                                        }
                                                                    />
                                                                    <div>
                                                                        <Skeleton
                                                                            variant="text"
                                                                            width={
                                                                                100
                                                                            }
                                                                        />
                                                                        <Skeleton
                                                                            variant="text"
                                                                            width={
                                                                                150
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    disabled
                                                                >
                                                                    <Skeleton
                                                                        variant="text"
                                                                        width={
                                                                            200
                                                                        }
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* </Slider> */}
                                                </div>
                                            ) : (
                                                <div className="grid-container">
                                                    {likesReceived.map(
                                                        (character, index) => (
                                                            <div
                                                                key={index}
                                                                className="grid-item"
                                                            >
                                                                <div className="like-card d-flex flex-column space-between">
                                                                    <div className="d-flex overflow-hidden">
                                                                        <img
                                                                            src={
                                                                                character[7] !==
                                                                                    null &&
                                                                                character[7] !==
                                                                                    '' &&
                                                                                character[7] !==
                                                                                    'None'
                                                                                    ? character[7]
                                                                                    : '/images/prof.png'
                                                                            }
                                                                            alt="Immagine profilo"
                                                                        />
                                                                        <div>
                                                                            <h3 className="text-ellipsis-1">
                                                                                {
                                                                                    character[1]
                                                                                }
                                                                            </h3>
                                                                            <h4 className="text-ellipsis-2">
                                                                                {
                                                                                    character[2]
                                                                                }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                sub ===
                                                                                    null ||
                                                                                !sub?.status ===
                                                                                    'active'
                                                                            )
                                                                                setShowPricing(
                                                                                    true
                                                                                )
                                                                            else {
                                                                                e.preventDefault()
                                                                                setActions(
                                                                                    true
                                                                                )
                                                                                setGuid(
                                                                                    character[0]
                                                                                )
                                                                                setOpen(
                                                                                    true
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        VEDI
                                                                        L'OFFERTA
                                                                        COMPLETA
                                                                        &nbsp;{' '}
                                                                        <ShowMoreIcon />
                                                                    </button>
                                                                    <div className="clip-shape"></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {!loading &&
                                                likesReceived.length === 0 && (
                                                    <div className="placeholder-card">
                                                        <div className="bg-el"></div>
                                                        <div className="d-flex flex-column justify-center align-center text-center h-100">
                                                            <p className="accent-text-color">
                                                                Non hai ancora
                                                                ricevuto nessun
                                                                like
                                                            </p>
                                                            <Link to="/jump">
                                                                Trova un match!
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </section>
                                </TabContent>
                            </div>

                            <TabContent id="Like inviati" activeTab={activeTab}>
                                <section className="m2">
                                    {/* <div className="section-title">
                                    <h2>Like inviati</h2>
                                </div>*/}

                                    {loading ? (
                                        <div className="grid-container">
                                            {/* <Slider {...SettingsSkeleton}> */}
                                            {Array.from({ length: 5 }).map(
                                                (_, index) => (
                                                    <div
                                                        key={index}
                                                        className="grid-item"
                                                    >
                                                        <div className="like-card d-flex flex-column space-between">
                                                            <div className="d-flex overflow-hidden">
                                                                <Skeleton
                                                                    variant="circle"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div>
                                                                    <Skeleton
                                                                        variant="text"
                                                                        width={
                                                                            100
                                                                        }
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        width={
                                                                            150
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button disabled>
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            {/* </Slider> */}
                                        </div>
                                    ) : (
                                        <div className="grid-container">
                                            {offers.map((character, index) => (
                                                <div
                                                    key={index}
                                                    className="grid-item"
                                                >
                                                    <div className="like-card d-flex flex-column space-between">
                                                        <div className="d-flex overflow-hidden">
                                                            <img
                                                                src={
                                                                    character[6] !==
                                                                        null &&
                                                                    character[6] !==
                                                                        '' &&
                                                                    character[6] !==
                                                                        'None'
                                                                        ? character[6]
                                                                        : '/images/prof.png'
                                                                }
                                                                alt="Immagine profilo"
                                                            />
                                                            <div>
                                                                <h3 className="text-ellipsis-1">
                                                                    {
                                                                        character[2]
                                                                    }
                                                                </h3>
                                                                <h4 className="text-ellipsis-2">
                                                                    {
                                                                        character[3]
                                                                    }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={(e) => {
                                                                setGuid(
                                                                    character[0]
                                                                )
                                                                setActions(
                                                                    false
                                                                )
                                                                setOpen(true)
                                                            }}
                                                        >
                                                            VEDI L'OFFERTA
                                                            COMPLETA &nbsp;{' '}
                                                            <ShowMoreIcon />
                                                        </button>

                                                        <div className="clip-shape"></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {!loading && offers.length === 0 && (
                                        <div className="placeholder-card">
                                            <div className="bg-el"></div>
                                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                                <p className="accent-text-color">
                                                    Non hai ancora inviato
                                                    nessun like
                                                </p>
                                                <Link to="/jump">
                                                    Trova un match!
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </section>
                            </TabContent>
                        </div>

                        <CompleteFormOffer
                            guid={guid}
                            swiped={swiped}
                            open={open}
                            setOpen={setOpen}
                            actions={actions}
                        />
                    </main>
                </div>
            )}
            {hideCards && (
                <ItsMatch imageSrc={imageSrc} setOpen={setHideCards} />
            )}
        </div>
    )
}

export default Likes
