import React from 'react'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import { useEffect } from 'react'

function IntroPage() {
    const { logout } = useAuth()

    const handleLogin = () => {
        try {
            history.push('/login')
        } catch (e) {
            // console.log(e)
        }
    }

    const handleSubscribe = () => {
        try {
            history.push('/signup')
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <>
            <main className="d-flex flex-column align-center justify-center">
                <h1 className="big-title">
                    Trovare lavoro non è più un lavoro
                </h1>
                <div className="btns-container d-flex">
                    <button onClick={handleLogin} className="btn login-btn">
                        ACCEDI
                    </button>
                    <button
                        onClick={handleSubscribe}
                        className="btn signup-btn"
                    >
                        ISCRIVITI
                    </button>
                </div>
            </main>
        </>
    )
}

export default IntroPage
