import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import KangratsLogo from 'app/components/Icons/KangratsLogo'
import history from 'history.js'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const PdfDesign = () => {
    const location = useLocation()
    const { userInfo, expList, ssList, hsList, langList, titleList, certList } =
        location.state || {}
    const componentRef = useRef()

    const handlePrint = () => {
        const printContents = componentRef.current.innerHTML
        const printWindow = window.open('', '', 'width=800,height=600')
        printWindow.document.write(`
      <html>
        <head>
          <title>nuovo_documento</title>
          <style>
          
             body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              font-family: 'Poppins', sans-serif;
              margin: 0;
              padding: 0;
              min-height: 100vh;
              display: flex;
              flex-direction: column;
            }
            .print-page {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              min-height: 100vh;
            }
            .footerpdf {
              height: 2rem;
              position: fixed; 
              bottom: 0; 
              width: 100%; 
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0;
              padding: 7px 20px;
              background: #ea6c3a;
              border-radius: 0;
              color: #fff;
              z-index: 1000;
              font-size: 12px; 
              box-sizing: border-box;
            }
            .header-pdf {
              display: grid;
              grid-template-columns: 48% 51%;
              gap: 1%;
              margin: 1rem 0;
              background: #5058ed;
              padding: 2rem;
              color: #fff;
              border-radius: 1rem;
            }
            .headerpdffont {
              font-weight: 700;
               word-wrap: break-word;
               width: 65%;

            }
            .blod-p {
              font-weight: 600;
            }
            .pdf-container {
              padding: 20px;
            }
            .expsection {
              padding: 1rem;
              background-color: #f0f0f3;
              border-radius: 1rem;
            }
            .imgpdf {
              border-radius: 50%;
              height: 6rem;
            }
            .tags.fit {
              width: fit-content;
              color: #000;
              background: #fff;
              border-radius: 0.65rem;
              padding: 0.4rem 0.75rem;
              margin: 0.5rem 0.5rem 0 0;
              font-size: 0.9375rem;
            }
            .skills_container {
              display: flex;
              flex-wrap: wrap;
            }
            .leftsidefooter {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
            .imgpdffooter {
              width: 5rem;
            }
            .rightsidefooter {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
            .print-page {
              font-family:'poppins', sans-serif;
              flex: 1; 
              display: flex;
              flex-direction: column;
              justify-content: space-evenly; 
            }
            .footerpdf {
              height: 2rem
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 1rem 0rem;
              padding: 7px 20px;
              background: #ea6c3a;
              border-radius: 30px;
              color: #fff;
            }
            .pdf-container {
              padding: 20px;
            }
            .pdf-title {
              font-size: 24px;
              margin-bottom: 20px;
            }
            .pdf-button {
              display: flex;
              padding: 10px;
              border-radius: 1rem;
              align-items: center;
              justify-content: center;
              width: auto;
              color: #fff;
              font-size: 0.9375rem;
              background-color: #5058ed;
            }
            .header-pdf {
              display: grid;
              grid-template-columns: 49% 51%;
              gap: 0%;
              margin: 1rem 0rem;
              background: #5058ed;
              padding: 0.5rem 2rem;
              color: #fff;
              border-radius: 1rem;
            }
            .headerpdffont{
              font-weight: 700;
            }
            .blod-p{
              font-weight: 600;
            }
            .footerpdf {
              position: fixed;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 1rem 0rem;
              padding: 2px 20px;
              background: #ea6c3a;
              border-radius: 30px;
              color:#fff;
            }
            .headerfontpdf {
              color: #5058ed;
              margin-bottom: 0.5rem;
              font-weight: 700;
            }
            .subhead {
              font-weight: 600;
            }
            .flexhori {
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 0rem;
            }
            ul { list-style-type: none; padding: 0; }
            .imgNameDes {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            .tags.fit {
            width: fit-content;
            color: #000;
            background:#fff;
            border-radius: 0.65rem;
            padding: 0.4rem 0.75rem;
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            font-size: 0.9375rem;
            }
            .expsection {
              padding: 1rem;
              background-color: #f0f0f3;
              border-radius: 1rem;
            }
            .expcardpdf {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }
            .girdpdf {
              display: grid;
              grid-template-columns: 49% 49%;
              gap: 2%;
              margin: 1rem 0rem;
              align-items: start;
            }
            .marbtt {
              margin-bottom: 0.5rem;
            }
            .imgpdf {
              border-radius: 50%;
              height: 6rem;
            }
            .tags{
               background:#fff;
            }
            .tags.des {
                padding: 10px 20px;
            }
            .girdup{
                gap: 1rem;
                display: grid;
            }
            .leftsidefooter{
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
            .imgpdffooter{
              width:5rem;
            }
            .flexhori2 {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 2.7rem;
              margin-bottom: 1rem;
              width: max-content;
            }
            .rightsidefooter {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
            .skills_container{
                display:flex;
                flex-wrap: wrap;
            }
            .logopdf {
                background-image:url(./logo-fff.png);
                width: 10rem;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
            }
            @media only screen and (max-width:620px){
                .tags.fit{
                    font-size:0.7rem;
                }
            }
             @media print {
              .print-page, .header-pdf, .expsection, .footerpdf {
                page-break-inside: avoid; /* Prevent breaks within elements */
              }

              .expsection {
                page-break-after: auto; /* Allow breaks after sections */
                margin-bottom: 20px;
              }

              .header-pdf {
                page-break-before: auto; /* Allow breaks before headers */
              }

              /* Avoid empty spaces at the end */
              .expsection:last-child {
                margin-bottom: 0 !important;
              }
            }
           
}}
        
          </style>
        </head>
        <body>${printContents}</body>
      </html>
    `)
        printWindow.document.close()
        printWindow.print()
    }
    const goback = () => {
        try {
            history.push('/profile')
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="pdfpage">
            <div className="flex-btuns">
                <div className="link2profile">
                    <button onClick={goback}>
                        {' '}
                        <KeyboardBackspaceIcon />{' '}
                    </button>
                    <p>Torna al tuo profilo</p>
                </div>
                <button onClick={handlePrint} className="btnprintpdf">
                    Stampa il tuo KV
                </button>
            </div>

            <div ref={componentRef} className="print-page">
                <div className="header-pdf">
                    <div className="imgNameDes">
                        <div className="flexhori">
                            <img
                                className="imgpdf"
                                src={
                                    !userInfo?.photo_url ||
                                    userInfo?.photo_url === 'None'
                                        ? '/images/prof.png'
                                        : userInfo.photo_url
                                }
                                alt="Immagine profilo"
                            />
                            <h1
                                className="headerpdffont"
                                style={{
                                    fontWeight: '700',
                                    wordWrap: 'break-word',
                                    width: '65%',
                                }}
                            >
                                {userInfo?.name} {userInfo?.surname}
                            </h1>
                        </div>
                        {userInfo?.description && (
                            <p
                                className="tags des"
                                style={{ borderRadius: '2rem', color: '#000' }}
                            >
                                {userInfo.description}
                            </p>
                        )}
                    </div>
                    <div className="connectpart">
                        <div>
                            <p className="blod-p">Contatti</p>
                            <div className="flexhori">
                                <p className="tags fit">
                                    {userInfo?.email || 'email'}
                                </p>
                                <p className="tags fit">
                                    {userInfo?.phone || 'numero'}
                                </p>
                            </div>
                        </div>
                        <div className="flexhori2">
                            <div>
                                <p className="blod-p">Cerco lavoro a</p>
                                <p className="tags fit">
                                    {userInfo?.location || 'località'}
                                </p>
                            </div>
                            <div>
                                <p className="blod-p">Patenti</p>
                                <p className="tags fit">
                                    {userInfo?.driving_license
                                        ? `${userInfo.driving_license.join(
                                              ', '
                                          )} `
                                        : 'Non Specificato'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="girdpdf">
                    {expList?.length > 0 && (
                        <div className="girdup">
                            <div className="expsection">
                                <p className="headerfontpdf">
                                    Esperienza Lavorativa
                                </p>
                                {expList.map((exp, index) => (
                                    <div key={index} className="expcardpdf">
                                        <span className="subhead">
                                            {exp.job_title}
                                        </span>
                                        <span>🏢 {exp.company_name}</span>
                                        <span>
                                            📅{' '}
                                            {format(
                                                new Date(exp.job_date_start),
                                                'MM/yyyy'
                                            )}{' '}
                                            -{' '}
                                            {format(
                                                new Date(exp.job_date_end),
                                                'MM/yyyy'
                                            )}
                                        </span>
                                        <span>📍 {exp.location}</span>
                                        <span>{exp.description}</span>
                                    </div>
                                ))}
                            </div>

                            {titleList?.length > 0 && (
                                <div className="expsection">
                                    <span className="headerfontpdf">
                                        Istruzione e Formazione
                                    </span>
                                    {titleList.map((title, index) => (
                                        <div key={index} className="expcardpdf">
                                            <span className="subhead">
                                                🎓 {title.eqf_label}
                                            </span>
                                            {title.other_title && (
                                                <span>
                                                    {' '}
                                                    📚 {title.other_title}
                                                </span>
                                            )}

                                            {title.school && (
                                                <span>🏫 {title.school}</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="girdup">
                        <div className="expsection">
                            <p className="headerfontpdf">Competenze</p>

                            {langList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">Lingue</p>
                                    <div className="skills_container">
                                        {langList.map((lang, index) => (
                                            <p key={index} className="tags fit">
                                                {lang.skill_name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {ssList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">
                                        Competenze Trasversali
                                    </p>
                                    <div className="skills_container">
                                        {ssList.map((skill, index) => (
                                            <p key={index} className="tags fit">
                                                {JSON.parse(skill.skill_name)
                                                    ?.soft_lv2 || ''}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {hsList?.length > 0 && (
                                <div className="marbtt">
                                    <p className="subhead">
                                        Competenze Tecniche
                                    </p>
                                    <div className="skills_container">
                                        {hsList.map((skill, index) => (
                                            <p key={index} className="tags fit">
                                                {skill.skill_name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {certList?.length > 0 && (
                            <div className="expsection">
                                <p className="headerfontpdf">Certificazioni</p>
                                {certList.map((cert, index) => (
                                    <div key={index} className="expcardpdf">
                                        <span className="subhead">
                                            {cert.cert_type}
                                        </span>
                                        {cert.cert_title && (
                                            <p>✍🏻 {cert.cert_title}</p>
                                        )}
                                        {cert.date_end && (
                                            <span>
                                                📅{' '}
                                                {format(
                                                    new Date(cert.date_end),
                                                    'yyyy'
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="footerpdf">
                    <div className="leftsidefooter">
                        {' '}
                        <p>Realizzato con </p>
                        <img
                            className="imgpdffooter"
                            src="/images/logo-fff.png"
                            alt="Immagine profilo"
                            style={{ width: '5rem' }}
                        />
                    </div>
                    <div className="rightsidefooter">
                        <p>Salta su kangrats.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PdfDesign
