import React, { useState, useEffect, useRef } from 'react'
import CloseIcon from '../../Icons/CloseIcon'
import Closebtnblue from '../../Icons/Closebtnblue'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import { format } from 'date-fns'
import Skeleton from '@material-ui/lab/Skeleton'

export const CompleteFormCandidate = ({
    guid,
    open,
    setOpen,
    swiped = null,
    hideCards = false,
    actions = false,
    showSalary = false,
    showSurname = false,
    user_info = [],
    ss_list = [],
    hs_list = [],
    lang_list = [],
    title_list = [],
    cert_list = [],
    exp_list = [],
}) => {
    const [hsList, setHsList] = useState([])
    const [langList, setLangList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [certList, setCertList] = useState([])
    const [expList, setExpList] = useState([])
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [appState, setAppState] = useState(false)
    const initUserInfo = {
        candidate_guid: '',
        name: '',
        surname: '',
        phone: '',
        gender: '',
        summary: '',
        birth_date: '',
        email: '',
        location: '',
        photo_url: '',
        description: '',
        driving_license: '',
        ral: '',
        ral_type: true,
        protected_category: 'false',
        job_contract: [],
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        name,
        surname,
        phone,
        gender,
        summary,
        birth_date,
        email,
        location,
        photo_url,
        description,
        driving_license,
        ral,
        ral_type,
        protected_category,
        job_contract,
        created,
        lastUpdate,
    } = userInfo

    // const handleClickOpen = () => {
    //     setOpen(true)
    //     // console.log(guid)
    //     setUserInfo({ ...userInfo, candidate_guid: guid })
    //     setAppState(true)
    // }

    const handleClose = () => {
        // setUserInfo(initUserInfo)
        setOpen(false)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const calculate_age = () => {
        const today = new Date()
        const birthDate = new Date(birth_date)
        var age_now = today.getFullYear() - birthDate.getFullYear()
        var m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--
        }
        // console.log(age_now);
        return age_now
    }

    const getUserInfo = async () => {
        const response = await ApiService.get_candidatebyId(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setUserInfo({
                ...userInfo,
                candidate_guid: body.candidate_guid,
                name: body.name,
                surname: body.surname,
                phone: body.phone,
                gender: body.gender,
                summary: body.summary,
                birth_date: new Date(body.birth_date),
                email: body.email,
                location: body.location,
                photo_url: body.photo_url,
                description: body.description,
                driving_license: JSON.parse(body.driving_license),
                ral: body.ral,
                ral_type: body?.ral_type || false,

                job_contract:
                    typeof body.job_contract === 'string'
                        ? JSON.parse(body.job_contract) || []
                        : [],

                protected_category:
                    body?.protected_category?.toString() || 'false',
                created: body.created,
                lastUpdate: body.lastUpdate,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const getHsList = async () => {
        // const response = await ApiService.get_candhsbyguid(candidate_guid)
        // const body = await response.json()
        // console.log(body)

        try {
            setHsList([])
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLangList = async () => {
        const response = await ApiService.get_candLangbyguid(candidate_guid)
        const body = await response.json()
        // console.log(body)

        try {
            setLangList(body)
        } catch (err) {
            console.error(err.message)
        }
    }
    const getSsList = async () => {
        const response = await ApiService.get_candssbyguid(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        const response = await ApiService.get_candtitlebyguid(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getCertList = async () => {
        const response = await ApiService.get_candCertbyguid(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setCertList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        const response = await ApiService.get_candexpbyguid(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    async function getData() {
        // setLoading(true)
        if (user_info.length > 0) setUserInfo(user_info)
        else await getUserInfo()

        if (hs_list.length > 0) setHsList(hs_list)
        else await getHsList()

        if (lang_list.length > 0) setLangList(lang_list)
        else await getLangList()

        if (ss_list.length > 0) setSsList(ss_list)
        else await getSsList()

        if (title_list.length > 0) setTitleList(title_list)
        else await getTitleList()

        if (cert_list.length > 0) setCertList(cert_list)
        else await getCertList()

        if (exp_list.length > 0) setExpList(exp_list)
        else await getExpList()
        setAppState(false)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (open) {
            setUserInfo({
                ...userInfo,
                candidate_guid: guid,
            })
            setAppState(true)
        }
    }, [open])

    function JobDetailsSkeleton() {
        return (
            <div className="card-layout">
                <div className="text-right">
                    <section className="accent-color">
                        <Skeleton variant="text" width="50%" height={40} />
                        <Skeleton variant="text" width="30%" height={30} />
                        <div className="two-columns">
                            <Skeleton variant="text" width="40%" height={30} />
                            <div className="hideOnMobile"></div>
                            <div>
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                            </div>
                            <div>
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                                <Skeleton
                                    variant="text"
                                    width="60%"
                                    height={30}
                                />
                            </div>
                        </div>
                        <Skeleton variant="text" width="80%" height={30} />
                    </section>
                    <section className="two-columns">
                        <div>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="40%" height={30} />
                        </div>
                        <div>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="40%" height={30} />
                        </div>
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={50} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={50} />
                    </section>
                    <div className="two-columns">
                        <section>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="rect" width="100%" height={50} />
                        </section>
                        <section>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="rect" width="100%" height={50} />
                        </section>
                    </div>
                    <section>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton variant="rect" width="100%" height={100} />
                    </section>
                </div>
            </div>
        )
    }
    return (
        <Dialog
            open={open || hideCards}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="MUIDialog job-dialog"
        >
            {appState ? (
                <div className="d-flex space-between dialog-title height">
                    <div
                        className="aline-img-text-skeleton"
                        id="header-img-text-offer"
                    >
                        <div className="img-fixed">
                            <Skeleton
                                className="profile-skeleton"
                                variant="circle"
                            />
                            <div className="text-center">
                                <Skeleton variant="text" width={40} />
                                {/* <Skeleton variant="text" width={20} /> */}
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => handleClose()}
                        color="primary"
                        className="close-dialog"
                        id="offerClosebtn"
                    >
                        <Closebtnblue />
                    </Button>
                </div>
            ) : (
                !hideCards && (
                    <div className="d-flex space-between dialog-title height">
                        <div
                            className="aline-img-text"
                            id="header-img-text-offer"
                        >
                            <DialogTitle
                            // id="scroll-dialog-title"
                            >
                                <div className="img-fixed preview">
                                    <img
                                        src={
                                            photo_url === null ||
                                            photo_url === '' ||
                                            photo_url === 'None'
                                                ? '/images/prof.png'
                                                : photo_url
                                        }
                                        alt="Immagine profilo"
                                    />
                                    <div className="text-center">
                                        {/* <h3
                                            style={{
                                                wordBreak: 'break-word',
                                                width: '11rem',
                                            }}
                                        >
                                            {name}
                                        </h3> */}
                                        <h3 style={{ wordBreak: 'break-word' }}>
                                            {summary}
                                        </h3>
                                    </div>
                                </div>
                            </DialogTitle>
                        </div>
                        <Button
                            onClick={() => handleClose()}
                            color="primary"
                            className="close-dialog"
                            id="offerClosebtn"
                        >
                            <Closebtnblue />
                        </Button>
                    </div>
                )
            )}

            <DialogContent dividers={scroll === 'paper'}>
                {appState ? (
                    <JobDetailsSkeleton />
                ) : (
                    <div className="card-layout">
                        <div className="text-right">
                            <section className="accent-color">
                                <h1 className="nameCandidate">
                                    {name} {showSurname ? surname : ''}
                                </h1>
                                <div className="container-preview">
                                    <div className="tags-container preview">
                                        <p className="tags">
                                            📌{' '}
                                            {location
                                                ? location?.split(',')[0]
                                                : 'Non Specificato'}
                                        </p>

                                        <p className="tags">
                                            🚘{' '}
                                            {driving_license?.length > 0
                                                ? driving_license.join(', ')
                                                : 'Non Specificato'}
                                        </p>
                                    </div>
                                    {showSalary && (
                                        <div className="two-columns mt1 preview">
                                            <div className="previewcardgrid">
                                                <div className="preview-right">
                                                    <p>Aspettativa salariale</p>
                                                    <p className="tags">
                                                        💰{' '}
                                                        {ral
                                                            ? ral + ' €'
                                                            : 'Non Specificato'}
                                                        <br />
                                                        {ral_type
                                                            ? 'Mensile (netto)'
                                                            : 'Annuo (lordo)'}
                                                    </p>
                                                </div>

                                                {/* <div className="preview-left">
                                                <p>Tipologia di lavoro</p>
                                                <p className="tags">In sede</p>
                                            </div> */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>

                            {description && (
                                <>
                                    <section>
                                        <p className="pre-space hyphens">
                                            {description}
                                        </p>
                                    </section>
                                </>
                            )}

                            {expList.length > 0 && (
                                <>
                                    <section>
                                        <h4>Esperienze professionali</h4>
                                        {expList.map((exp, index) => {
                                            if (!exp.isDesired)
                                                return (
                                                    <div key={index}>
                                                        <h3>{exp.job_title}</h3>
                                                        {exp.company_name && (
                                                            <p>
                                                                🏢{' '}
                                                                {
                                                                    exp.company_name
                                                                }
                                                            </p>
                                                        )}
                                                        <p>
                                                            📅 Data:{' '}
                                                            {(() => {
                                                                const locale =
                                                                    'it-IT'

                                                                const jobStart =
                                                                    new Date(
                                                                        exp.job_date_start
                                                                    )
                                                                const startMonth =
                                                                    jobStart.toLocaleDateString(
                                                                        locale,
                                                                        {
                                                                            month: 'long',
                                                                        }
                                                                    )
                                                                const startYear =
                                                                    jobStart.getFullYear()

                                                                const jobEnd =
                                                                    exp.current_job
                                                                        ? new Date()
                                                                        : new Date(
                                                                              exp.job_date_end
                                                                          )
                                                                const endMonth =
                                                                    jobEnd.toLocaleDateString(
                                                                        locale,
                                                                        {
                                                                            month: 'long',
                                                                        }
                                                                    )
                                                                const endYear =
                                                                    jobEnd.getFullYear()

                                                                const totalMonths =
                                                                    (jobEnd.getFullYear() -
                                                                        jobStart.getFullYear()) *
                                                                        12 +
                                                                    (jobEnd.getMonth() -
                                                                        jobStart.getMonth())
                                                                const years =
                                                                    Math.floor(
                                                                        totalMonths /
                                                                            12
                                                                    )
                                                                const months =
                                                                    totalMonths %
                                                                    12

                                                                if (
                                                                    startYear ===
                                                                    endYear
                                                                ) {
                                                                    return `${capitalizeFirstLetter(
                                                                        startMonth
                                                                    )} ${startYear} - ${capitalizeFirstLetter(
                                                                        endMonth
                                                                    )} ${endYear} (${totalMonths} mesi)`
                                                                } else {
                                                                    return `${capitalizeFirstLetter(
                                                                        startMonth
                                                                    )} ${startYear} - ${capitalizeFirstLetter(
                                                                        endMonth
                                                                    )} ${endYear} (${
                                                                        years >
                                                                        0
                                                                            ? `${years} ann${
                                                                                  years >
                                                                                  1
                                                                                      ? 'i'
                                                                                      : 'o'
                                                                              }`
                                                                            : ''
                                                                    }${
                                                                        years >
                                                                            0 &&
                                                                        months >
                                                                            0
                                                                            ? ' e '
                                                                            : ''
                                                                    }${
                                                                        months >
                                                                        0
                                                                            ? `${months} mes${
                                                                                  months >
                                                                                  1
                                                                                      ? 'i'
                                                                                      : 'e'
                                                                              }`
                                                                            : ''
                                                                    })`
                                                                }
                                                            })()}
                                                        </p>
                                                        {exp.location && (
                                                            <p>
                                                                📍{' '}
                                                                {exp.location}
                                                            </p>
                                                        )}
                                                        <p className="placeholder-text descrition-lines">
                                                            {exp.description}
                                                        </p>
                                                    </div>
                                                )
                                        })}
                                    </section>
                                </>
                            )}

                            {titleList.length > 0 && (
                                <>
                                    <section>
                                        <h4>Istruzione e formazione</h4>
                                        {titleList.map((title, index) => {
                                            return (
                                                <div key={index}>
                                                    <h3>{title.eqf_label}</h3>
                                                    {title.other_title && (
                                                        <p className="bold">
                                                            🎓{' '}
                                                            {title.other_title}
                                                        </p>
                                                    )}
                                                    {title.school && (
                                                        <p>📍 {title.school}</p>
                                                    )}
                                                    {title.graduation_grade ? (
                                                        <p>
                                                            ⭐ Votazione:{' '}
                                                            {
                                                                title.graduation_grade
                                                            }
                                                        </p>
                                                    ) : null}
                                                    <p>
                                                        🗓️ Tempo di
                                                        conseguimento{' '}
                                                        {format(
                                                            new Date(
                                                                title.title_date_start
                                                            ),
                                                            'yyyy'
                                                        )}{' '}
                                                        -{' '}
                                                        {title.current_title
                                                            ? 'In corso'
                                                            : format(
                                                                  new Date(
                                                                      title.title_date_end
                                                                  ),
                                                                  'yyyy'
                                                              )}
                                                    </p>
                                                    <p className="placeholder-text descrition-lines">
                                                        {title.description}
                                                    </p>
                                                    {/* <p>Erasmus: {title[7] ? "Sì" : "No"} </p> */}
                                                    {/* <p>Pubblicazioni: {title[9] ? "Sì" : "No"} </p> */}
                                                </div>
                                            )
                                        })}
                                    </section>
                                </>
                            )}
                            <section>
                                <h4>Competenze</h4>
                                {langList.length > 0 && (
                                    <>
                                        <div>
                                            <h3>Competenze Linguistiche</h3>
                                            <ul className="tags-container">
                                                {langList.map(
                                                    (skill, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="tags"
                                                            >
                                                                {
                                                                    skill.skill_name
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </>
                                )}
                                <div className="two-columns">
                                    {ssList.length > 0 && (
                                        <>
                                            <div>
                                                <h3>Competenze Trasversali</h3>
                                                <ul className="tags-container">
                                                    {ssList.map(
                                                        (skill, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="tags"
                                                                >
                                                                    {' '}
                                                                    {
                                                                        JSON.parse(
                                                                            skill.skill_name
                                                                        )
                                                                            ?.soft_lv2
                                                                    }{' '}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                    {hsList.length > 0 && (
                                        <>
                                            <div>
                                                <h3>Competenze Tecniche</h3>
                                                <ul className="tags-container">
                                                    {hsList.map(
                                                        (skill, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="tags"
                                                                >
                                                                    {
                                                                        skill.skill_name
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </section>

                            {certList.length > 0 && (
                                <>
                                    <section>
                                        <h4>Certificazioni</h4>
                                        {certList.map((title, index) => {
                                            return (
                                                <div key={index}>
                                                    <h3>{title.cert_type}</h3>
                                                    <p>✍🏻 {title.cert_title}</p>
                                                    {title.date_end ? (
                                                        <p>
                                                            📅 Data{' '}
                                                            {format(
                                                                new Date(
                                                                    title.date_end
                                                                ),
                                                                'yyyy'
                                                            )}{' '}
                                                        </p>
                                                    ) : null}
                                                    <p className="placeholder-text descrition-lines">
                                                        {title.description}
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </section>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </DialogContent>

            {actions ? (
                <DialogActions
                    className={[
                        'dialog-button-container',
                        appState ? 'hide' : 'show',
                    ]}
                >
                    <Button
                        color="primary"
                        style={{ background: '#5058ed' }}
                        onClick={() => {
                            swiped(0)
                            handleClose()
                        }}
                    >
                        <span className="dialog-button-text blue">
                            Non mi piace
                        </span>
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            swiped(1)
                            handleClose()
                        }}
                    >
                        <span className="dialog-button-text">Mi piace</span>
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions />
            )}
        </Dialog>
    )
}
