import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import SoftSkillsTable from '../../../Tables/soft_skills_table'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import EditFieldDialog from '../FooterProfile/EditField'

function JobCardSoftSkills({ sslist, offerguid, getSsList, appState }) {
    // per dialog
    const descriptionElementRef = useRef(null)
    const [open, setOpen] = useState(false)
    //  per treeselect Competenze trasversali
    const [ssList, setSsList] = useState([])
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [ssMappedList, setSsMappedList] = useState([])
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [backupSsMappedList, setBackupSsMappedList] = useState([])
    const [loadingButton, setLoadingButton] = useState(false)

    const initUserInfo = {
        offer_guid: offerguid || '',
        skill_name: '',
        created: '',
        lastUpdate: '',
        id: null,
    }

    const [userInfo, setUserInfo] = useState(initUserInfo)
    const { offer_guid, skill_name, created, lastUpdate } = userInfo

    const handleClickOpen = () => () => {
        setOpen(true)
        setUserInfo({
            ...userInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = () => {
        setOpen(false)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const onNodeSelect = (currentNode) => {
        setUserInfo({ ...userInfo, skill_name: currentNode.value })
    }

    const onSelect = (selectedSkill) => {
        setSsMappedList((prevList) => {
            const isAlreadySelected = prevList.some(
                (skill) =>
                    skill.soft_lv1 === selectedSkill.soft_lv1 &&
                    skill.soft_lv2 === selectedSkill.soft_lv2
            )

            if (isAlreadySelected) {
                return prevList.filter(
                    (skill) =>
                        skill.soft_lv1 !== selectedSkill.soft_lv1 ||
                        skill.soft_lv2 !== selectedSkill.soft_lv2
                )
            } else {
                return [...prevList, selectedSkill]
            }
        })
    }

    const handleAddSkill = async () => {
        setLoadingButton(true)
        const otherSoftLv2Set = new Set(
            ssList.map((item) => JSON.parse(item.skill_name).soft_lv2)
        )

        const updatedArray = ssMappedList.map((skill) => {
            return {
                ...skill,
                offer_guid: offer_guid,

                checked: otherSoftLv2Set.has(skill.soft_lv2),
                skill_name: {
                    soft_lv1: skill.soft_lv1,
                    soft_lv2: skill.soft_lv2,
                },
                created: new Date(),
                lastUpdate: new Date(),
            }
        })

        ssList.forEach((otherSkill) => {
            if (
                !ssMappedList.some(
                    (skill) =>
                        skill.soft_lv2 ===
                        JSON.parse(otherSkill.skill_name).soft_lv2
                )
            ) {
                updatedArray.push({
                    id: otherSkill.id,
                    offer_guid: offer_guid,

                    checked: false,
                    skill_name: {
                        soft_lv1: JSON.parse(otherSkill.skill_name).soft_lv1,
                        soft_lv2: JSON.parse(otherSkill.skill_name).soft_lv2,
                    },
                    // created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        const res = await ApiService.create_multipleofferss(updatedArray)
        // const res = await ApiService.create_candss(userInfo)
        if (res.ok) {
            await getSsList()
        }
        handleClose()
        setLoadingButton(false)
    }

    const handleDelete = (id) => async (event) => {
        event.preventDefault()

        const res = await ApiService.delete_offerss(id)
        if (res.ok) {
            // setSsList((prevSkills) =>
            //     prevSkills.filter((skill) => skill.id !== id)
            // )
            getSsList()
        }
    }

    const handleSubmit = async () => {
        if (!userInfo.id) {
            setFormErrors({ skill_name: 'Invalid ID for update' })
            return
        }

        const res = await ApiService.update_offersss(userInfo.id, userInfo)
        if (res.ok) getSsList()
        handleClose()
    }

    const validate = () => {
        const errors = {}
        // if (!skill_name) errors.skill_name = "Questo campo non può essere nullo"

        // if (!duplicatedSkill && !skill_name) {
        //     // errors.skill_name = 'Questo campo non può essere nullo'
        //     // setDuplicatedSkill(false)
        // } else if (duplicatedSkill) {
        //     errors.skill_name = 'Questa voce è già stata inserita'
        // }

        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            const errors = validate()
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
                handleAddSkill()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        setSsList(sslist)
        setSsMappedList(
            sslist.map((x) => {
                const parsedData = JSON.parse(x.skill_name)
                return { id: x.id, ...parsedData }
            })
        )
        // console.log('Updated ssMappedList:', ssMappedList)
    }, [sslist])

    useEffect(() => {
        setUserInfo({ ...userInfo, offer_guid: offerguid })
    }, [offerguid])

    const skillCategories = SoftSkillsTable.reduce((acc, skill) => {
        if (!acc[skill.soft_lv1]) acc[skill.soft_lv1] = []
        acc[skill.soft_lv1].push(skill.soft_lv2)
        return acc
    }, {})

    const handleSkillSelect = (skill) => {
        setIsEdited(true)
        onSelect(skill)
    }

    const handleDialogCloseWithConfirm = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            setSsMappedList(backupSsMappedList)
            handleClose()
        }
    }

    return (
        <>
            <div className="">
                <h3 style={{ color: '#5058ed' }}>Ecco cosa serve al lavoro.</h3>
                <div className="section-title">
                    <p>Clicca sul + per aggiungere più caratteristiche.</p>
                    <button
                        disabled={ssList?.length > 12}
                        className="icon-btn"
                        onClick={handleClickOpen()}
                    >
                        <AddIcon />
                    </button>
                </div>

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3>
                                Competenze trasversali richieste al candidato
                            </h3>
                            <button
                                onClick={handleDialogCloseWithConfirm}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div>
                            <p>
                                Seleziona fino a 10 delle seguenti
                                caratteristiche. Fai clic su quelle che desideri
                                per aggiungerle all'offerta.
                            </p>
                            {/* <p>
                                quelle che desideri per aggiungerle all'offerta.
                            </p> */}
                        </div>

                        <div className="d-flex flex-column space-between dialog-layout align-end">
                            <div>
                                <p className="input-error">
                                    {formErrors.skill_name}
                                </p>
                                {Object.keys(skillCategories).map(
                                    (category) => (
                                        <div
                                            key={category}
                                            className="skills-category-profile"
                                        >
                                            <h3 className="category-header">
                                                {category}
                                            </h3>
                                            <div className="skills-list-profile">
                                                {skillCategories[category].map(
                                                    (skillName) => {
                                                        const skill =
                                                            SoftSkillsTable.find(
                                                                (s) =>
                                                                    s.soft_lv1 ===
                                                                        category &&
                                                                    s.soft_lv2 ===
                                                                        skillName
                                                            )

                                                        const isSelected =
                                                            ssMappedList.some(
                                                                (
                                                                    selectedSkill
                                                                ) =>
                                                                    selectedSkill.soft_lv1 ===
                                                                        skill.soft_lv1 &&
                                                                    selectedSkill.soft_lv2 ===
                                                                        skill.soft_lv2
                                                            )

                                                        return (
                                                            <div className="tags-container">
                                                                <button
                                                                    key={
                                                                        skillName
                                                                    }
                                                                    className={`d-flex align-center tags TagWhite ${
                                                                        isSelected
                                                                            ? 'selected-skill'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleSkillSelect(
                                                                            skill
                                                                        )
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            color: '#4b4c55',
                                                                        }}
                                                                    >
                                                                        {
                                                                            skillName
                                                                        }
                                                                    </p>
                                                                    {isSelected && (
                                                                        <button
                                                                            className="icon-btn"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation()
                                                                                onSelect(
                                                                                    skill
                                                                                )
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </button>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>

                            <Button
                                id="wideSavebtn"
                                variant="contained"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSubmit(true)
                                }}
                                color="primary"
                                className="btn-like-mui"
                                disabled={loadingButton}
                                style={{ marginTop: '20px', color: '#fff' }}
                            >
                                {loadingButton ? (
                                    <CircularProgress
                                        style={{
                                            position: 'absolute',
                                            left: '5%',
                                        }}
                                        color="inherit"
                                        size={25}
                                    />
                                ) : null}
                                Salva
                            </Button>
                        </div>
                    </div>
                )}

                <ul className="btns-card grid-2-columns skill-container tags-container">
                    {appState ? (
                        <div className="new-box">
                            <div className="d-flex align-center tags ">
                                <div className="d-flex align-center tags ">
                                    <Skeleton
                                        variant="rect"
                                        width={100}
                                        height={30}
                                    />
                                </div>
                                <div className="d-flex align-center tags">
                                    <Skeleton
                                        variant="rect"
                                        width={100}
                                        height={30}
                                    />
                                </div>
                                <div className="d-flex align-center tags">
                                    <Skeleton
                                        variant="rect"
                                        width={100}
                                        height={30}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        sslist.map((skill) => (
                            <div key={skill.id} className="new-box">
                                <div className="d-flex align-center tags">
                                    <p>
                                        {JSON.parse(skill.skill_name).soft_lv2}
                                    </p>
                                    <button
                                        className="icon-btn"
                                        onClick={handleDelete(skill.id)}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </ul>
            </div>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardSoftSkills
