import React, { useEffect, useState } from 'react'
import useAuth from 'app/hooks/useAuth'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { DialogContentText } from '@material-ui/core'
import ApiService from 'app/ApiService'
import { authRoles } from 'app/auth/authRoles'

function UnsubscribeAccount() {
    const { user, logout, unsubscribe, role } = useAuth()
    const [userId, setUserId] = useState(null)

    const [openUnsubscribe, setOpenUnsubscribe] = React.useState(false)
    const handleClickOpenUnsubscribe = () => {
        setOpenUnsubscribe(true)
    }
    const handleCloseUnsubscribe = () => {
        setOpenUnsubscribe(false)
    }

    const handleUnsubscribe = async () => {
        const res = await unsubscribe()
        if (res) {
            let response = null
            if (authRoles.recruiter.includes(role))
                response = await ApiService.delete_recruiter(userId)
            else response = await ApiService.delete_candidate(userId)
            if (response?.ok) logout()
        }
    }

    useEffect(() => {
        if (user !== null) setUserId(user?.uid)
    }, [user])

    return (
        <>
            <div className="footer-section">
                {/* <hr /> */}
                <button onClick={handleClickOpenUnsubscribe}>
                    Elimina Account
                </button>
            </div>

            {/* DIALOG UNSUBSCRIBE */}
            <Dialog
                open={openUnsubscribe}
                onClose={handleCloseUnsubscribe}
                aria-labelledby="form-dialog-title"
                className="small-dialog"
            >
                <DialogTitle id="form-dialog-title">
                    Elimina account
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermando eliminerai il tuo account in modo definitivo
                        e tutti i tuoi dati verrano persi.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUnsubscribe()} color="primary">
                        Conferma
                    </Button>
                    <Button onClick={handleCloseUnsubscribe} color="secondary">
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UnsubscribeAccount
