import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authRoles } from '../auth/authRoles'
import PdfDesign from 'app/components/Homepage/KVGeneretor/Pdfdesign'
import PdfREC from 'app/components/Homepage/KVGeneretor/PdfREC'
import PreviewCardJob from 'app/components/Homepage/ProfileComponents/JobCard/PreviewCardJob'
import PdfCandidato from 'app/components/Homepage/KVGeneretor/Pdfcandidato'
import Profile from 'app/components/Homepage/Profile'

const PdfRoute = [
    {
        path: '/popup',
        component: PdfDesign,
        auth: authRoles.candidate,
    },
    {
        path: '/Pdf_Offer',
        component: PdfREC,
        auth: authRoles.recruiter,
    },
]

export default PdfRoute
