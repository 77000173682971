import React, { useRef, useState, useEffect, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
// scroll orizzontale
import Slider from 'react-slick'
import { Badge, TextField } from '@material-ui/core'
import EmojiPickerMart from './ProfileComponents/Emoji/EmojiPickerMart'
import KebabMenuChat from './ProfileComponents/KebabMenuChat/KebabMenuChat.jsx'
import TitleDataTree from '../Tables/title_table_container'
import Closebtnblue from '../Icons/Closebtnblue'
import Skeleton from '@material-ui/lab/Skeleton'
import { Link } from 'react-router-dom'
import { CompleteFormOffer } from './CompleteForm/CompleteFormOffer'

function MatchPage() {
    const [open, setOpen] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [offers, setOffers] = useState([])
    const [getCards, setGetCards] = useState(true)
    const [appState, setAppState] = useState(false)
    const [appMessage, setAppMessage] = useState(false)
    const [hsList, setHsList] = useState([])
    const [langList, setLangList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [certList, setCertList] = useState([])
    const [expList, setExpList] = useState([])
    const [message, setMessage] = useState('')
    const [showEmoji, setShowEmoji] = useState(false)
    const initOffer = {
        recruiter_guid: '',
        offer_guid: '',
        company_name: '',
        job_name: '',
        sector: [],
        location: '',
        driving_license: '',
        job_percentage: 0,
        isced: '',
        ral: 0,
        ral_type: true,
        protected_category: 'false',
        photo_url: '',
        description: '',
        start_job_date: '',
        job_contract: '',
        desc_company: '',
        job_type: '',

        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)
    const {
        recruiter_guid,
        offer_guid,
        company_name,
        job_name,
        sector,
        location,
        driving_license,
        job_percentage,
        isced,
        ral,
        ral_type,
        protected_category,
        photo_url,
        description,
        start_job_date,
        job_contract,
        desc_company,
        job_type,
        created,
        lastUpdate,
    } = offerInfo

    const { user } = useAuth()
    const {
        messages,
        unreaded,
        matches,
        sendMessage,
        getMessages,
        getUnreadedMessages,
        getNewMatches,
        updateReadMessage,
    } = useFirestore()

    const computeCandidate = async () => {
        try {
            const response = await ApiService.get_candmatches(user.uid)
            const body = await response.json()
            console.log(body)

            setOffers(body)
            setGetCards(false)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_offerbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setOfferInfo(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    async function getData() {
        await getOfferInfo()
        setAppState(false)
    }

    const handleClickOpen = (event, scrollType) => {
        event.preventDefault()
        setOpen(true)
        setScroll(scrollType)
        setAppState(true)
    }

    const onMessagingOpen = (event, guid) => {
        event.preventDefault()
        setOpenMessage(!openMessage)
        if (openMessage === false) {
            setOfferInfo({ ...offerInfo, offer_guid: guid })
            setAppMessage(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
        // setOfferInfo(initOffer)
    }

    const divToScroll = useRef()
    const scrollToBottom = () => {
        if (messages && openMessage) {
            divToScroll.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const getMessagesbyguid = useCallback(async () => {
        await getOfferInfo()
        await getMessages(offer_guid, user.uid)
        // await updateReadMessage(offer_guid, user.uid, offer_guid)
        scrollToBottom()
        setAppMessage(false)
    }, [offerInfo, user])

    const sendMessage2Recruiter = (event) => {
        event.preventDefault()
        if (message) {
            sendMessage(
                offer_guid,
                user.uid,
                user.uid,
                recruiter_guid,
                message,
                user.displayName
            )
            setMessage('')
            scrollToBottom()
        }
        if (showEmoji) setShowEmoji(false)
    }

    let getValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                return obj
            }
            if (obj.children) {
                let result = getValue(obj.children, val)
                if (result) {
                    return result
                }
            }
        }
        return undefined
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.uid && getCards) {
            computeCandidate()
            getUnreadedMessages(user?.uid)
            getNewMatches(user?.uid)
            scrollToBottom()
        }
    }, [user, getCards])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (openMessage) updateReadMessage(offer_guid, user.uid, recruiter_guid)

        if (messages) scrollToBottom()
    }, [messages])

    useEffect(() => {
        if (appMessage) getMessagesbyguid()
    }, [appMessage])

    useEffect(() => {
        if (unreaded) console.log('messages: ', unreaded)
    }, [unreaded])

    useEffect(() => {
        if (matches) console.log('matches: ', matches)
    }, [matches])

    // per scroll orizzontale
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 2,
        arrows: true,
        centerMode: false,
        variableWidth: false,

        className: 'avatar-container',
        responsive: [
            {
                breakpoint: 1350, // define the breakpoint
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 950, // define another breakpoint
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // define another breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 400, // define another breakpoint
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <div className="Content-scroll-sticky">
            <main className="match-page">
                <section className="padding-inline ">
                    <Slider {...settings}>
                        {getCards
                            ? Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                      key={index}
                                      className="d-flex flex-column align-center chat-avatar"
                                  >
                                      <Badge
                                          badgeContent={0}
                                          color="default"
                                          anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left',
                                          }}
                                      >
                                          <Skeleton
                                              variant="circle"
                                              width={80}
                                              height={80}
                                              style={{ marginBottom: '1rem' }}
                                          />
                                      </Badge>
                                      <div>
                                          <Skeleton variant="text" width={80} />
                                          <Skeleton
                                              variant="text"
                                              width={100}
                                          />
                                      </div>
                                  </div>
                              ))
                            : offers.length > 0
                            ? offers.map((character, index) => (
                                  <div
                                      key={index}
                                      className="d-flex flex-column align-center chat-avatar"
                                  >
                                      <Badge
                                          onClick={(e) =>
                                              onMessagingOpen(e, character[0])
                                          }
                                          badgeContent={
                                              // Extract the latest unread item for the given character
                                              unreaded
                                                  .filter(
                                                      (x) =>
                                                          x.id === character[7]
                                                  )
                                                  ?.sort(
                                                      (a, b) =>
                                                          b.timestamp.seconds -
                                                          a.timestamp.seconds
                                                  )[0]?.number
                                          }
                                          //   color={
                                          //       // Determine badge color based on whether the number is greater than 0
                                          //       unreaded
                                          //           .filter(
                                          //               (x) =>
                                          //                   x.id === character[7]
                                          //           )
                                          //           ?.sort(
                                          //               (a, b) =>
                                          //                   b.timestamp.seconds -
                                          //                   a.timestamp.seconds
                                          //           )[0]?.number > 0
                                          //           ? 'secondary'
                                          //           : 'default'
                                          //   }
                                          color="secondary"
                                          variant={
                                              unreaded.filter(
                                                  (x) => x.id === character[7]
                                              )?.length <= 0 &&
                                              matches.filter(
                                                  (x) => x.id === character[0]
                                              ).length > 0
                                                  ? 'dot'
                                                  : ''
                                          }
                                          max={999}
                                          anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left',
                                          }}
                                      >
                                          <img
                                              src={
                                                  character[6] !== null &&
                                                  character[6] !== '' &&
                                                  character[6] !== 'None'
                                                      ? character[6]
                                                      : '/images/prof.png'
                                              }
                                              alt="Immagine profilo"
                                          />
                                      </Badge>
                                      <div
                                          onClick={(e) =>
                                              onMessagingOpen(e, character[0])
                                          }
                                      >
                                          <p className="bold">{character[2]}</p>
                                          <p>{character[3]}</p>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </Slider>

                    {!getCards && offers.length == 0 && (
                        <div className="m2">
                            <div className="placeholder-card">
                                <div className="bg-el"></div>
                                <div className="d-flex flex-column justify-center align-center text-center h-100">
                                    <p className="accent-text-color">
                                        Non hai ancora ricevuto nessun like
                                    </p>
                                    <Link to="/jump">Trova un match!</Link>
                                </div>
                            </div>
                        </div>
                    )}

                    {openMessage && (
                        <div className="speech-wrapper d-flex flex-column h-100">
                            <div className="header-chat">
                                <div className="d-flex space-between">
                                    <div className="d-flex flex-column">
                                        <h2>{company_name}</h2>
                                        <h3>{job_name}</h3>
                                        <button
                                            className="primary-text-color align-self-start"
                                            onClick={(e) =>
                                                handleClickOpen(e, 'paper')
                                            }
                                        >
                                            {' '}
                                            VEDI L'OFFERTA COMPLETA &nbsp;{' '}
                                        </button>
                                    </div>
                                    <KebabMenuChat
                                        offer_guid={offer_guid}
                                        candidate_guid={user.uid}
                                        setAppState={setGetCards}
                                        setOpenMessage={setOpenMessage}
                                    />
                                </div>
                            </div>

                            <div className="main-msgs-container">
                                <div className="msgs-container">
                                    {messages?.length > 0 ? (
                                        messages.map((msg, index) => {
                                            if (msg.userId === user.uid)
                                                return (
                                                    <div
                                                        key={index}
                                                        className="bubble-speech"
                                                    >
                                                        <div className="txt">
                                                            {/* <p className="name alt">+3545534534<span> Caio</span></p> */}
                                                            {/* <p className="name alt">Caio</p> */}

                                                            <p>
                                                                {
                                                                    user.displayName
                                                                }
                                                            </p>
                                                            <p className="message pre-space hyphens">
                                                                {msg.message}
                                                            </p>
                                                            <p className="timestamp">
                                                                {msg.timestamp
                                                                    .toDate()
                                                                    .toLocaleTimeString(
                                                                        [],
                                                                        {
                                                                            timeStyle:
                                                                                'short',
                                                                        }
                                                                    )}
                                                            </p>
                                                            {/* <span className="timestamp">10:22 pm</span> */}
                                                        </div>
                                                        <div className="bubble-arrow"></div>
                                                    </div>
                                                )
                                            else
                                                return (
                                                    <div
                                                        key={index}
                                                        className="bubble-speech alt"
                                                    >
                                                        <div className="txt">
                                                            {/* <p className="name">TIzio</p> */}
                                                            <p className="name alt">
                                                                {
                                                                    msg.displayName
                                                                }
                                                            </p>
                                                            <p className="message pre-space hyphens">
                                                                {msg.message}
                                                            </p>
                                                            <p className="timestamp">
                                                                {msg.timestamp
                                                                    .toDate()
                                                                    .toLocaleTimeString(
                                                                        [],
                                                                        {
                                                                            timeStyle:
                                                                                'short',
                                                                        }
                                                                    )}
                                                            </p>
                                                            {/* <span className="timestamp">10:20 pm</span> */}
                                                        </div>
                                                        <div className="bubble-arrow alt"></div>
                                                    </div>
                                                )
                                        })
                                    ) : (
                                        <div />
                                    )}

                                    {/* <small className='daystamp'>4 giugno 2023</small> */}
                                    {/* <div className="or-line"><small className='newMsg'>Nuovo messaggio</small></div> */}

                                    <div ref={divToScroll}></div>
                                </div>
                            </div>

                            <form onSubmit={sendMessage2Recruiter}>
                                <div className="footer-chat">
                                    <div className="d-flex align-end send-msg-box">
                                        <EmojiPickerMart
                                            message={message}
                                            setMessage={setMessage}
                                            showEmoji={showEmoji}
                                            setShowEmoji={setShowEmoji}
                                        />

                                        <TextField
                                            // id="filled-basic"
                                            // label="Scrivi qui il tuo messaggio"
                                            placeholder="Scrivi qui il tuo messaggio"
                                            variant="filled"
                                            name="message"
                                            value={message}
                                            onChange={(event) =>
                                                setMessage(event.target.value)
                                            }
                                            fullWidth
                                            color="primary"
                                            multiline
                                            onClick={() => setShowEmoji(false)}
                                            // rows={1}
                                            height="auto"
                                        />

                                        <button
                                            className="send-btn"
                                            type="submit"
                                            onClick={scrollToBottom}
                                        >
                                            <svg
                                                width="53"
                                                height="35"
                                                viewBox="0 0 53 35"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M53 0L0 3.99571L12.7554 11.4966L33.6889 7.81855L18.759 16.5833L30.6298 35L53 0Z"
                                                    fill="#EA6C3A"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </section>
                <CompleteFormOffer
                    guid={offer_guid}
                    open={open}
                    setOpen={setOpen}
                    actions={false}
                />
            </main>
        </div>
    )
}

export default MatchPage
