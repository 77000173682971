import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    FormGroup,
} from '@material-ui/core'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import ApiService from 'app/ApiService'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'

const SectionTitles = [
    <>
        Tipo <span className="lowercase">di impiego</span>
    </>,
    <>
        Quale <span className="lowercase"> retribuzione offri? </span>
    </>,
]

function OnboardingInfoJobLast({
    userInfo,
    offer,
    getOffer,
    stepOnboarding,
    setStepOnboarding,
}) {
    const [stepOnboardingInfo, setStepOnboardingInfo] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    const initOffer = {
        recruiter_guid: userInfo?.recruiter_guid || '',
        offer_guid: '',
        company_name: userInfo?.company_name || '',
        job_name: '',
        sector: userInfo?.sector,
        location: '',
        driving_license: [],
        job_type: '',
        job_contract: '',
        protected_category: 'False',
        job_percentage: 0,
        isced: '',
        ral: 800,
        ral_type: true,
        description: '',
        hs_weight: 30,
        ss_weight: 5,
        title_weight: 15,
        exp_weight: 50,
        start_job_date: '',
        created: new Date(),
        lastUpdate: new Date(),
        active: true,
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)
    const {
        recruiter_guid,
        offer_guid,
        job_name,
        sector,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        job_percentage,
        isced,
        ral,
        ral_type,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        created,
        start_job_date,
        lastUpdate,
        active,
    } = offerInfo

    const [minSalary, setMinSalary] = useState(0)
    const [maxSalary, setMaxSalary] = useState(5000)
    const [isDragging, setIsDragging] = useState(false)
    const radius = 45
    const circumference = 2 * Math.PI * radius
    const svgRef = useRef(null)
    const progress = ((ral - minSalary) / (maxSalary - minSalary)) * 100
    const strokeDashoffset = circumference - (progress / 100) * circumference
    const radToDeg = (rad) => (rad * 180) / Math.PI

    const jobContractOptions = [
        'Indeterminato',
        'Determinato',
        'Apprendistato',
        'Tirocinio',
        'Partita IVA',
    ]

    const handleMouseDown = (event) => {
        setIsDragging(true)
        updateSlider(event)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const handleMouseMove = (event) => {
        if (isDragging) {
            updateSlider(event)
        }
    }
    const updateSlider = (event) => {
        const rect = svgRef.current.getBoundingClientRect()
        const centerX = rect.left + rect.width / 2
        const centerY = rect.top + rect.height / 2
        const clientX = event.clientX || event.touches[0].clientX
        const clientY = event.clientY || event.touches[0].clientY
        const angle = Math.atan2(clientY - centerY, clientX - centerX)
        const angleDeg = radToDeg(angle) + 90
        let newAngle = (angleDeg + 360) % 360
        const maxAngle = 320
        if (newAngle > maxAngle) {
            newAngle = maxAngle
        }
        let newSalary =
            minSalary + (maxSalary - minSalary) * (newAngle / maxAngle)
        if (ral_type === 'false') {
            newSalary = Math.round(newSalary / 500) * 500
        } else {
            newSalary = Math.round(newSalary / 50) * 50
        }
        setOfferInfo({ ...offerInfo, ral: newSalary })
    }

    const handleSalaryTypeChange = (type) => {
        if (type === false) {
            setMinSalary(0)
            setMaxSalary(100000)
            setOfferInfo({ ...offerInfo, ral_type: type, ral: 24000 })
        } else {
            setMinSalary(0)
            setMaxSalary(5000)
            setOfferInfo({ ...offerInfo, ral_type: type, ral: 800 })
        }
    }

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove)
            window.addEventListener('mouseup', handleMouseUp)
            window.addEventListener('touchmove', handleMouseMove)
            window.addEventListener('touchend', handleMouseUp)
        } else {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('touchend', handleMouseUp)
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('touchend', handleMouseUp)
        }
    }, [isDragging])

    async function handleNext() {
        if (stepOnboardingInfo >= SectionTitles.length - 1) {
            const res = await ApiService.create_offer(offerInfo)
            if (!res.ok) return //show error
            else await getOffer()
            setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
        }
        setStepOnboardingInfo((prevActiveStep) =>
            prevActiveStep !== SectionTitles.length - 1
                ? prevActiveStep + 1
                : prevActiveStep
        )
        setSubmit(false)
    }

    const handleJobContractChange = (event) => {
        setOfferInfo({
            ...offerInfo,
            job_contract: event.target.value,
        })
    }

    const validate = () => {
        const errors = {}

        switch (stepOnboardingInfo) {
            case 0:
                if (!job_contract)
                    errors.job_contract = 'Devi scegliere una ozione'
                if (!job_type)
                    errors.job_type =
                        'Devi scegliere una tipologia di contratto'

                break
            // case 1:
            //     if (ral)
            //         errors.location = 'Questo campo non può essere nullo'
            //     break
        }
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        setOfferInfo({
            ...offerInfo,
            recruiter_guid: userInfo?.recruiter_guid,
            company_name: userInfo?.company_name,
            sector: userInfo?.sector,
        })
    }, [userInfo])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo(offer)
            if (offer?.ral_type === false) {
                setMinSalary(0)
                setMaxSalary(100000)
            } else {
                setMinSalary(0)
                setMaxSalary(5000)
            }
        }
    }, [offer])

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [stepOnboardingInfo])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="onboarding-grid">
                <h3
                    className="m1"
                    style={{
                        fontWeight: '700',
                        textAlign: 'center',
                        marginTop: '2rem',
                        textTransform: 'capitalize',
                    }}
                >
                    {SectionTitles[stepOnboardingInfo]}
                </h3>
                {(() => {
                    switch (stepOnboardingInfo) {
                        case 0:
                            return (
                                <>
                                    <div
                                        className="input-container"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                        >
                                            <FormControl
                                                component="fieldset"
                                                className="radio-groups mt1 signup"
                                            >
                                                {/* <FormLabel
                                                    component="h3"
                                                    style={{
                                                        fontWeight: '700',
                                                        textAlign: 'center',
                                                        marginBottom: '0rem',
                                                        marginTop: '2rem',
                                                        fontSize: '1.125rem',
                                                    }}
                                                >
                                                    Tipo di impiego
                                                </FormLabel> */}
                                                <p className="input-error onboarding">
                                                    {formErrors.job_type}
                                                </p>
                                                <div className="switchOnBoarding signup">
                                                    <div
                                                        onClick={() =>
                                                            setOfferInfo({
                                                                ...offerInfo,
                                                                job_percentage: 100,
                                                            })
                                                        }
                                                        style={{
                                                            padding:
                                                                '10px 20px',
                                                            border: '1px solid #ccc',
                                                            width: '10rem',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            backgroundColor:
                                                                job_percentage ===
                                                                100
                                                                    ? '#ea6c3a'
                                                                    : 'transparent',
                                                            color:
                                                                job_percentage ===
                                                                100
                                                                    ? 'white'
                                                                    : 'black',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Tempo Pieno
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setOfferInfo({
                                                                ...offerInfo,
                                                                job_percentage: 50,
                                                            })
                                                        }
                                                        style={{
                                                            padding:
                                                                '10px 20px',
                                                            border: '1px solid #ccc',
                                                            width: '10rem',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            backgroundColor:
                                                                job_percentage ===
                                                                50
                                                                    ? '#ea6c3a'
                                                                    : 'transparent',
                                                            color:
                                                                job_percentage ===
                                                                50
                                                                    ? 'white'
                                                                    : 'black',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Part-time
                                                    </div>
                                                </div>
                                            </FormControl>
                                        </FormControl>

                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                        >
                                            <h3
                                                style={{
                                                    margin: '2rem auto 1rem',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Che tipo di contratto offri?
                                            </h3>
                                            <p
                                                className="input-error"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {formErrors.job_contract}
                                            </p>
                                            <FormGroup
                                                row
                                                className="jobcontract"
                                                style={{
                                                    display: 'grid',
                                                    flexDirection: 'row',
                                                    gap: '1rem 0',
                                                }}
                                            >
                                                {jobContractOptions.map(
                                                    (contract) => (
                                                        <FormControlLabel
                                                            key={contract}
                                                            control={
                                                                <Radio
                                                                    checked={
                                                                        job_contract ===
                                                                        contract
                                                                    }
                                                                    onChange={
                                                                        handleJobContractChange
                                                                    }
                                                                    value={
                                                                        contract
                                                                    }
                                                                    name="job_contract"
                                                                />
                                                            }
                                                            label={contract}
                                                        />
                                                    )
                                                )}
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )
                        case 1:
                            return (
                                <>
                                    <div className="input-container nomr">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <svg
                                                ref={svgRef}
                                                height={radius * 2 + 20}
                                                width={radius * 2 + 20}
                                                onMouseDown={handleMouseDown}
                                                onTouchStart={handleMouseDown}
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                }}
                                            >
                                                <circle
                                                    stroke="#d3d3d3"
                                                    fill="transparent"
                                                    strokeWidth="12"
                                                    r={radius}
                                                    cx={radius + 10}
                                                    cy={radius + 10}
                                                />
                                                <circle
                                                    stroke="#ea6c3a"
                                                    fill="transparent"
                                                    strokeWidth="14"
                                                    strokeDasharray={
                                                        circumference
                                                    }
                                                    strokeDashoffset={
                                                        strokeDashoffset
                                                    }
                                                    strokeLinecap="round"
                                                    r={radius}
                                                    cx={radius + 10}
                                                    cy={radius + 10}
                                                    style={{
                                                        transition:
                                                            'stroke-dashoffset 0.1s',
                                                        transform:
                                                            'rotate(-90deg)',
                                                        transformOrigin:
                                                            '50% 50%',
                                                    }}
                                                />
                                                {/* <text
                                                    x="50%"
                                                    y="45%"
                                                    dominantBaseline="middle"
                                                    textAnchor="middle"
                                                    fontSize="16"
                                                    fontWeight="bold"
                                                    fill="#333"
                                                >
                                                    Minimo
                                                </text> */}
                                                <text
                                                    x="50%"
                                                    y="53%"
                                                    dominantBaseline="middle"
                                                    textAnchor="middle"
                                                    fontSize="24"
                                                    fontWeight="bold"
                                                    fill="#333"
                                                >
                                                    {new Intl.NumberFormat(
                                                        'de-DE'
                                                    ).format(ral)}
                                                    €
                                                </text>
                                            </svg>

                                            <FormControl
                                                component="fieldset"
                                                className="radio-groups mt1 signup"
                                            >
                                                <FormLabel
                                                    required
                                                    component="h3"
                                                    style={{
                                                        fontWeight: '700',
                                                        textAlign: 'center',
                                                        marginBottom: '0rem',
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    Preferisci parlare di
                                                </FormLabel>
                                                <div className="switchOnBoarding signup">
                                                    <div
                                                        onClick={() =>
                                                            handleSalaryTypeChange(
                                                                true
                                                            )
                                                        }
                                                        style={{
                                                            padding:
                                                                '10px 20px',
                                                            border: '1px solid #ccc',
                                                            width: '10rem',
                                                            height: 'fit-content',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            backgroundColor:
                                                                ral_type ===
                                                                true
                                                                    ? '#ea6c3a'
                                                                    : 'transparent',
                                                            color:
                                                                ral_type ===
                                                                true
                                                                    ? 'white'
                                                                    : 'black',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Mensile Netto
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            handleSalaryTypeChange(
                                                                false
                                                            )
                                                        }
                                                        style={{
                                                            padding:
                                                                '10px 20px',
                                                            border: '1px solid #ccc',
                                                            width: '10rem',
                                                            height: 'fit-content',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            backgroundColor:
                                                                ral_type ===
                                                                false
                                                                    ? '#ea6c3a'
                                                                    : 'transparent',
                                                            color:
                                                                ral_type ===
                                                                false
                                                                    ? 'white'
                                                                    : 'black',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Annuo Lordo
                                                    </div>
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div
                                        className="input-container salarypart"
                                        style={{
                                            marginTop: '3rem',
                                            minWidth: 'max-content',
                                            marginBottom: '2rem',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <p style={{ display: 'flex' }}>
                                            <CheckRoundedIcon /> Le usiamo solo
                                            per filtrare i candidati e farti
                                            risparmiare tempo.
                                        </p>
                                        <p style={{ display: 'flex' }}>
                                            <CheckRoundedIcon /> Se hai dubbi,
                                            un importo più alto non ti fa
                                            perdere buone opportunità.
                                        </p>
                                    </div>
                                </>
                            )
                    }
                })()}
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingInfoJobLast
