import React from 'react'
import QRCode from 'react-qr-code'

const QRComponent = () => {
    return (
        <QRCode
            value="https://kangrats.com"
            size={150}
            bgColor="transparent"
            fgColor="black"
        />
    )
}

export default QRComponent
