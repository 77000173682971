import React, { useState, useRef, useEffect } from 'react'
import { Button, InputLabel } from '@material-ui/core'
import LikesIcon from '../Icons/LikesIcon'
import MatchIcon from '../Icons/MatchIcon'
import TroviamoUnLavoratoreIcon from '../Icons/TroviamoUnLavoratoreIcon'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import useFirestore from 'app/hooks/useFirestore'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import { CircularProgress } from '@material-ui/core'

function NotificationSetting({ setOpen }) {
    const [switchState, setSwitchState] = useState({
        inAppLike: true,
        emailLike: true,
        inAppMatch: true,
        emailMatch: true,
        inAppMsg: true,
        emailMsg: true,
        newsLetter: true,
    })
    const [loading, setLoading] = useState(false)

    const notificationIcons = {
        Like: <LikesIcon />,
        Match: <TroviamoUnLavoratoreIcon />,
        Messaggi: <MatchIcon />,
        Newsletter: <StarBorderRoundedIcon />,
    }

    const { user } = useAuth()
    const { getNotificationPreferences, setNotificationPreferences } =
        useFirestore()

    const getData = async () => {
        try {
            const prf = await getNotificationPreferences(user?.uid)
            if (prf) setSwitchState(prf)
        } catch (error) {
            console.error('Error fetching preferences:', error)
        }
    }

    const setPref = async () => {
        setLoading(true)
        const prf = await setNotificationPreferences(user?.uid, switchState)
        const res = await ApiService.subscribe_candidate({
            candidate_guid: user?.uid,
            isSubscribe: switchState.newsLetter,
        })
        setOpen(false)
        setLoading(false)
    }

    const handleSwitchChange = (key, value) => {
        setSwitchState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    useEffect(() => {
        if (user !== null) {
            getData()
        }
    }, [user])

    return (
        <div className="notification-setting-page">
            <h2>Impostazioni notifiche</h2>
            {['Like', 'Match', 'Messaggi', 'Newsletter'].map((item, idx) => {
                const inAppKey =
                    item === 'Messaggi'
                        ? 'inAppMsg'
                        : item === 'Newsletter'
                        ? 'newsLetter'
                        : `inApp${item}`
                const emailKey =
                    item === 'Messaggi'
                        ? 'emailMsg'
                        : item === 'Newsletter'
                        ? 'newsLetter'
                        : `email${item}`

                return (
                    <div className="notification-row" key={idx}>
                        <div className="notification-icon">
                            {notificationIcons[item]}
                            <h3>{item}</h3>
                        </div>
                        <div className="notification-options">
                            {/* In-App Notifications */}
                            {item !== 'Newsletter' && (
                                <div className="switch-row">
                                    <div className="switch-label">In App</div>
                                    <div className="switchOnBoarding">
                                        <div
                                            onClick={() =>
                                                handleSwitchChange(
                                                    inAppKey,
                                                    true
                                                )
                                            }
                                            className={`switchOption ${
                                                switchState[inAppKey] === true
                                                    ? 'active'
                                                    : 'inactive'
                                            }`}
                                        >
                                            Si
                                        </div>
                                        <div
                                            onClick={() =>
                                                handleSwitchChange(
                                                    inAppKey,
                                                    false
                                                )
                                            }
                                            className={`switchOption ${
                                                switchState[inAppKey] === false
                                                    ? 'active'
                                                    : 'inactive'
                                            }`}
                                        >
                                            No
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="switch-row">
                                <div className="switch-label">Emails</div>
                                <div className="switchOnBoarding">
                                    <div
                                        onClick={() =>
                                            handleSwitchChange(emailKey, true)
                                        }
                                        className={`switchOption ${
                                            switchState[emailKey] === true
                                                ? 'active'
                                                : 'inactive'
                                        }`}
                                    >
                                        Si
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleSwitchChange(emailKey, false)
                                        }
                                        className={`switchOption ${
                                            switchState[emailKey] === false
                                                ? 'active'
                                                : 'inactive'
                                        }`}
                                    >
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div
                className="button-group"
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '2rem',
                    marginTop: '2rem',
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => setPref()}
                    disabled={loading}
                    className="btn-primary"
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    {loading ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                left: '10%',
                            }}
                            color="inherit"
                            size={25}
                        />
                    ) : null}
                    Salva
                </Button>
            </div>
        </div>
    )
}

export default NotificationSetting
