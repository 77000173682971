import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState } from 'react'
import ApiService from 'app/ApiService'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import years_ranges from '../../Tables/years_ranges_recruiter'

const SectionTitles = [' Quanta esperienza è richiesta in questo ruolo?']

function OnboardingWorkExpRequired({
    offer,
    stepOnboarding,
    setStepOnboarding,
}) {
    // const [stepOnboardingWorkExp, setStepOnboardingWorkExp] = useState(0);
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [requiredExperience, setRequiredExperience] = useState('not-answered')
    const [appState, setAppState] = useState(false)
    const [years_exp, setYearsExp] = useState('')
    const initOfferInfo = {
        offer_guid: '',
        job_title: '',
        sector: [],
        years_required: 0,
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initOfferInfo)

    const {
        offer_guid,
        job_title,
        sector,
        years_required,
        created,
        lastUpdate,
    } = offerInfo

    const getExpList = async () => {
        try {
            const response = await ApiService.get_offersexpbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            if (body?.length > 0) {
                let expInfo = body[0]
                setOfferInfo({
                    ...offerInfo,
                    id: expInfo.id,
                    job_title: expInfo.job_title,
                    sector: expInfo.sector,
                    years_required: expInfo.years_required,
                    // isDesired: expInfo.isDesired,
                    lastUpdate: new Date(),
                })

                // if (!expInfo.isDesired) setRequiredExperience('yes')
                // else setRequiredExperience('no')

                const monthDifference = expInfo.years_required * 12

                switch (true) {
                    case monthDifference < 6:
                        setYearsExp(
                            years_ranges.filter((x) => x.months < 6)[0].label
                        )
                        break
                    case monthDifference >= 6 && monthDifference < 12:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 6 && x.months < 12
                            )[0].label
                        )
                        break
                    case monthDifference >= 12 && monthDifference < 24:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 12 && x.months < 24
                            )[0].label
                        )
                        break
                    case monthDifference >= 24 && monthDifference < 60:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 24 && x.months < 60
                            )[0].label
                        )
                        break
                    case monthDifference >= 60 && monthDifference < 120:
                        setYearsExp(
                            years_ranges.filter(
                                (x) => x.months >= 12 && x.months < 24
                            )[0].label
                        )
                        break
                    case monthDifference >= 120:
                        setYearsExp(
                            years_ranges.filter((x) => x.months >= 120)[0].label
                        )
                        break
                }
            }
            // else setRequiredExperience('no')
        } catch (err) {
            console.error(err.message)
        }
    }

    // const onChangePrevExp = (value) => {
    //     setRequiredExperience(value)
    //     if (value === 'no') {
    //         setOfferInfo({
    //             ...offerInfo,
    //             years_required: 1 / 12,
    //             isDesired: true,
    //         })
    //         setYearsExp('')
    //     } else {
    //         setOfferInfo({
    //             ...offerInfo,
    //             isDesired: false,
    //         })
    //     }
    // }

    const onSelect = (event) => {
        const months = years_ranges.filter(
            (x) => x.label === event.target.value
        )[0].months
        setYearsExp(event.target.value)
        setOfferInfo({
            ...offerInfo,
            years_required: months / 12,
        })
    }

    async function handleNext() {
        // if (requiredExperience === 'yes')
        {
            const res = await ApiService.create_offerexp(offerInfo)
            if (!res.ok) return
            else await ApiService.create_offerhsbyjobTitle(offerInfo)
        }
        // else if (offerInfo?.id) {
        //     const res = await ApiService.delete_offerexp(offerInfo?.id)
        //     if (!res.ok) return
        //     //add method to delete all skills!
        // }
        setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
        // else error
    }

    const validate = () => {
        const errors = {}
        // if (requiredExperience === 'not-answered')
        //     errors.required_exp = 'Questo campo non può essere nullo'
        // if (requiredExperience === 'yes' && !job_title)
        //     errors.job_title = 'Questo campo non può essere nullo'
        // if (requiredExperience === "yes" && !sector.length > 0) errors.sector = "Questo campo non può essere nullo"
        if (!years_required)
            errors.years_required = 'Questo campo non può essere nullo'
        // console.log(errors)
        return errors
    }

    async function getData() {
        await getExpList()
        setAppState(false)
    }

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo({
                ...offerInfo,
                offer_guid: offer?.offer_guid,
                job_title: offer?.job_name,
                sector: offer?.sector ?? [],
            })
            setAppState(true)
        }
    }, [offer])

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className="onboarding-grid">
                    <h2 style={{ marginTop: '0' }}>{SectionTitles[0]}</h2>

                    <div className="input-container" style={{ gap: '0.5rem' }}>
                        {/* <FormControl
                            component="fieldset"
                            className="m1 column "
                            style={{ marginBottom: '0' }}
                        >
                            <h4 component="legend" className='text-center'> E' richiesta esperienza pregressa?</h4> 

                            <div className="swichOnBoarding">
                                <div
                                    onClick={() => onChangePrevExp('yes')}
                                    style={{
                                        padding: '10px 20px',
                                        border: '1px solid #ccc',
                                        width: '6rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor:
                                            requiredExperience === 'yes'
                                                ? '#ea6c3a'
                                                : 'transparent',
                                        color:
                                            requiredExperience === 'yes'
                                                ? 'white'
                                                : 'black',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Si
                                </div>
                                <div
                                    onClick={() => onChangePrevExp('no')}
                                    style={{
                                        padding: '10px 20px',
                                        border: '1px solid #ccc',
                                        width: '6rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor:
                                            requiredExperience === 'no'
                                                ? '#ea6c3a'
                                                : 'transparent',
                                        color:
                                            requiredExperience === 'no'
                                                ? 'white'
                                                : 'black',
                                        cursor: 'pointer',
                                    }}
                                >
                                    No
                                </div>
                            </div>

                            <p className="input-error">
                                {formErrors.required_exp}
                            </p>
                        </FormControl> */}

                        {/* {requiredExperience === 'yes' && ( */}
                        <>
                            {/* <div>
                                    <h4
                                        component="legend"
                                        className="text-center"
                                        style={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        {' '}
                                        In quale ruolo?
                                    </h4>
                                    <Autocomplete
                                        parentValue={job_title}
                                        onChange={(newValue) =>
                                            setOfferInfo({
                                                ...offerInfo,
                                                job_title: newValue,
                                            })
                                        }
                                        label={'Ruolo o mansione'}
                                        apiRequest={ApiService.get_joblist}
                                        required
                                    />
                                    <p className="input-error">
                                        {formErrors.job_title}
                                    </p>
                                </div> */}
                            <FormControl
                                component="fieldset"
                                className="tags-check"
                            >
                                {/* <h4
                                        component="legend"
                                        className="text-center"
                                        style={{
                                            marginBottom: '0.5rem',
                                            fontWeight: '600',
                                        }}
                                    >
                                        Quanta esperienza è richiesta in questo
                                        ruolo?
                                    </h4> */}
                                <p className="input-error  text-center">
                                    {formErrors?.years_required}
                                </p>

                                <RadioGroup
                                    aria-label="Anni di esperienza"
                                    value={years_exp}
                                    onChange={onSelect}
                                    className="yearsexp"
                                >
                                    {years_ranges.map((category, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={category.label}
                                                control={<Radio />}
                                                label={category.label}
                                                className="yearsexpTag"
                                            />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </>
                        {/* )}
                        {requiredExperience === 'no' && (
                            <>
                                <div>
                                    <p>
                                        In questo modo potrai vedere profili che
                                        non hanno ancora maturato alcuna
                                        esperienza per questo ruolo
                                    </p>
                                </div>
                            </>
                        )}*/}
                    </div>
                </div>

                <OnboardingButton
                    stepOnboarding={stepOnboarding}
                    setStepOnboarding={setStepOnboarding}
                    setSubmit={setSubmit}
                    submit={isSubmit}
                />
            </form>
        </>
    )
}

export default OnboardingWorkExpRequired
