// import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import App from './app/App'
import * as Sentry from '@sentry/react'

// import './app/Styles/Main.css'

// cssVars();

Sentry.init({
    dsn: 'https://2b9788094c078d485e0bf8d4620df138@o4508189581180928.ingest.de.sentry.io/4508189585178704',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        // 'localhost',
        'https://kangrats.com',
        'https://kangrats-front-prod-dev.azurewebsites.net',
        // /^https:\/\/yourserver\.io\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
})

// ReactDOM.render(<App />, document.getElementById("app"));
const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(<App />)
// ReactDOM.render(<App />, document.getElementById('root'))

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
