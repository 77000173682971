import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { NavLink } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import { authRoles } from 'app/auth/authRoles'
import TroviamoUnLavoratoreIcon from '../Icons/TroviamoUnLavoratoreIcon'
import LikesIcon from '../Icons/LikesIcon'
import MatchIcon from '../Icons/MatchIcon'
import Badge from '../././../Layouts/Badge'

export const NavBottomFixed = ({ showlikeBadge, showmatchBadge }) => {
    // const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png")
    const { role } = useAuth()

    const navLinks = [
        {
            to: authRoles.recruiter.includes(role)
                ? '/jump-recruiter'
                : '/jump',
            icon: <TroviamoUnLavoratoreIcon stroke="#4b4c55" />,
            text: authRoles.recruiter.includes(role)
                ? 'Trova Persone'
                : 'Trova Lavoro',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/likes-recruiter'
                : '/likes',
            icon: showlikeBadge ? (
                <Badge color="secondary">
                    <LikesIcon stroke="#4b4c55" />
                </Badge>
            ) : (
                <LikesIcon stroke="#4b4c55" />
            ),
            text: 'Like',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/match-recruiter'
                : '/match',
            icon: showmatchBadge ? (
                <Badge color="secondary" sx={{ top: '0 !important' }}>
                    <MatchIcon />
                </Badge>
            ) : (
                <MatchIcon />
            ),
            text: 'Messaggi',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/profile-recruiter'
                : '/profile',

            icon: (
                // <img
                //     src="/images/prof.png"
                //     alt="Immagine profilo"
                //     style={{
                //         borderRadius: '50%',
                //         width: '2.5rem',
                //         height: '2.5rem',
                //         marginTop: '0.5rem',
                //         marginBottom: '0.5rem',
                //     }}
                // />
                <svg
                    className="profileAfatarprogressphone"
                    width="50"
                    height="50"
                    viewBox="0 0 35 50"
                    xmlns="http://www.w3.org/2000/svg"
                    id="profileIcon"
                    sx={{
                        color: '#FF6D00',
                        position: 'relative',
                        width: '8rem',
                        height: 'auto',
                    }}
                >
                    <path
                        className="activeClass stroke-dark fill-dark"
                        d="M15.5455 22.0186C19.2063 22.0186 22.6475 23.4484 25.2353 26.0438C27.8232 28.6391 29.2495 32.0911 29.2495 35.7625V36.1532H1.84138V35.7625C1.84138 32.0911 3.26702 28.6398 5.85486 26.0438C8.44341 23.4484 11.8847 22.0186 15.5455 22.0186ZM15.5455 20.1719C6.95969 20.1719 0 27.1518 0 35.7625V37.9999H31.0909V35.7625C31.0909 27.1518 24.1312 20.1719 15.5455 20.1719Z"
                    />
                    <path
                        className="activeClass stroke-dark fill-dark"
                        d="M15.5814 1.84673C19.6034 1.84673 22.876 5.12893 22.876 9.16262C22.876 13.1963 19.6034 16.4785 15.5814 16.4785C11.5594 16.4785 8.28669 13.1963 8.28669 9.16262C8.28669 5.12893 11.5594 1.84673 15.5814 1.84673ZM15.5814 0C10.536 0 6.44531 4.10258 6.44531 9.16262C6.44531 14.2227 10.536 18.3252 15.5814 18.3252C20.6267 18.3252 24.7174 14.2227 24.7174 9.16262C24.7174 4.10258 20.6267 0 15.5814 0Z"
                    />
                </svg>
            ),
            text: 'Profilo',
        },
    ]

    return (
        <Box sx={{ width: '100%', position: 'sticky', bottom: 0 }}>
            <nav className="nav-bottom">
                <ul className="nav-links-bottom">
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <NavLink to={link.to} className="jump-link">
                                {link.icon}
                                <span className="text-ellipsis-1">
                                    {link.text}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </Box>
    )
}
