import React, { useState, useEffect } from 'react'
import history from 'history.js'
import useAuth from '../../hooks/useAuth'
import validator from 'validator'

// password mui
import {
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Link,
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import CloseIcon from '../Icons/CloseIcon'
import { fontSize } from '@mui/system'
import { fontWeight } from '@mui/system'

function SignupForm() {
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setSubmit] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    })
    const [isInstagramWebView, setIsInstagramWebView] = useState(false)
    const [termsAccepted, setTermsAccepted] = useState(false)

    const {
        username,
        firstName,
        creditCard,
        mobile,
        password,
        confirmPassword,
        gender,
        date,
        email,
    } = userInfo

    const passwordOptions = {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
        pointsPerUnique: 1,
        pointsPerRepeat: 0.5,
        pointsForContainingLower: 10,
        pointsForContainingUpper: 10,
        pointsForContainingNumber: 10,
        pointsForContainingSymbol: 10,
    }
    const { register, signInWithGoogle, signInWithFacebook } = useAuth()

    // per password mui
    const [showPassword, setShowPassword] = React.useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleChange = (event) => {
        event.persist()
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const handleFormSubmit = async () => {
        try {
            const res = await register(email, password)
            //Gestire errore in fase di registrazione
            if (res === 'ok') history.push('/well-done')
            else if (res.includes('email-already-in-use'))
                setFormErrors({ email: 'Email già in uso' })
            else
                setFormErrors({
                    email: 'Qualcosa è andato storto... Riprovare in seguito.',
                })
        } catch (e) {
            setFormErrors({
                email: 'Qualcosa è andato storto... Riprovare in seguito.',
            })
        }
    }

    const handleGoogleSingIn = async (event) => {
        try {
            const res = await signInWithGoogle()
            if (res) history.push('/choose-account')
            else
                setFormErrors({
                    ...formErrors,
                    email: 'Qualcosa è andato storto... Riprovare in seguito.',
                })
        } catch (e) {
            setFormErrors({
                ...formErrors,
                email: 'Qualcosa è andato storto... Riprovare in seguito.',
            })
        }
    }

    const handleFacebookSingIn = async (event) => {
        try {
            const res = await signInWithFacebook()
            if (res) history.push('/choose-account')
            else
                setFormErrors({
                    ...formErrors,
                    email: 'Qualcosa è andato storto... Riprovare in seguito.',
                })
        } catch (e) {
            setFormErrors({
                ...formErrors,
                email: 'Qualcosa è andato storto... Riprovare in seguito.',
            })
        }
    }

    const goback = () => {
        try {
            history.push('/start')
        } catch (e) {
            console.log(e)
        }
    }

    const validate = () => {
        const errors = {}
        if (!email) errors.email = 'Questo campo non può essere nullo'
        else if (!validator.isEmail(email))
            errors.email = 'Inserire una email in formato valido'
        if (!password) errors.password = 'Questo campo non può essere nullo'
        else if (!validator.isStrongPassword(password))
            errors.password = `La password deve contenere almeno: 8 caratteri, 1 lettera maiuscola, 1 lettera minuscola, 1 numero, 1 carattere speciale tra !.-_?^|&£$%/()`
        // console.log(errors)
        if (!confirmPassword)
            errors.confirmPassword = 'Questo campo non può essere nullo'
        else if (confirmPassword !== password)
            errors.confirmPassword = 'Le due password non corrispondono'
        if (!termsAccepted)
            errors.termsAccepted =
                'Per iscriversi alla piattaforma è necessario visualizzare e accettare i nostri contratti di utilizzo'
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleFormSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked)
    }
    const detectInstagramWebView = () => {
        const userAgent =
            navigator.userAgent || navigator.vendor || window.opera
        setIsInstagramWebView(/Instagram/.test(userAgent))
    }

    useEffect(() => {
        detectInstagramWebView()
    }, [])

    const handleClosePopup = () => {
        setIsInstagramWebView(false)
    }

    const GoogleIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="24"
            height="24"
        >
            <defs>
                <path
                    id="A"
                    d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                />
            </defs>
            <clipPath id="B">
                <use xlinkHref="#A" />
            </clipPath>
            <g transform="matrix(.727273 0 0 .727273 -.954545 -1.45455)">
                <path d="M0 37V11l17 13z" clipPath="url(#B)" fill="#fbbc05" />
                <path
                    d="M0 11l17 13 7-6.1L48 14V0H0z"
                    clipPath="url(#B)"
                    fill="#ea4335"
                />
                <path
                    d="M0 37l30-23 7.9 1L48 0v48H0z"
                    clipPath="url(#B)"
                    fill="#34a853"
                />
                <path
                    d="M48 48L17 24l-4-3 35-10z"
                    clipPath="url(#B)"
                    fill="#4285f4"
                />
            </g>
        </svg>
    )

    const FacebookIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="24"
            height="24"
            stroke="none"
            fill="#fff"
        >
            <path
                d="M44.6368 47.2443c1.44 0 2.6075-1.1675 2.6075-2.608V2.6072C47.2443 1.1668 46.0768 0 44.6368 0H2.6072A2.607 2.607 0 0 0 0 2.6072v42.0296c0 1.44 1.1668 2.6075 2.6072 2.6075h42.0296z"
                fill="#3c5a99"
            />
            <path d="M32.0187 47.1981v-18.431h6.0944l.9124-7.1831h-7.0068v-4.5862c0-2.0787.5684-3.4962 3.506-3.4962l3.7465-.0015V7.0747c-.648-.0871-2.872-.2834-5.4595-.2834-5.402 0-9.0945 3.3473-9.0945 9.4948v5.2975h-6.1148v7.1827h6.1098v18.4313h7.307z" />
        </svg>
    )

    return (
        <>
            {isInstagramWebView && (
                <div className="popup-overlay">
                    <div className="popup">
                        <p>
                            Sei su Instagram! Per una migliore esperienza,
                            visita <strong>kangrats.com</strong> sul tuo browser
                            da PC o telefono.
                        </p>
                        <button
                            onClick={handleClosePopup}
                            className="popup-button"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            <div className="overlay"></div>

            <main className="main-container-login">
                <div className="login-container">
                    <div className="d-flex space-between align-centersign">
                        <h1>Inserisci i tuoi dati</h1>
                        <button onClick={goback} className="close-btn">
                            <CloseIcon />
                        </button>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setSubmit(true)
                        }}
                    >
                        {/* INPUT FATTI SENZA MUI */}
                        {/* <div className='psw-container input-container'>
                        <input required type={"email"} onChange={handleChange} value={email} name="email" id="choose-email" />
                        <label className={email && 'filled'} htmlFor={"Email"}>
                            {"Email"}
                        </label>
                    </div>
                    <div className='psw-container input-container'>
                        <input required type={passwordType} onChange={handleChange} value={password} name="password" id="choose-password" />
                        <button  className='eye-password'
                            onClick={togglePassword} 
                        >
                            { passwordType === "password" ? <img src="./images/closed-eye.png" /> :<img src="./images/open-eye.png" /> }
                        </button>
                        <label className={password && 'filled'} htmlFor={"password"}>
                            {"Password"}
                        </label>
                    </div>
                    <div className='psw-container input-container'>
                        <input required type={passwordConfType} onChange={handleChange} value={confirmPassword} name="confirmPassword" id="conf-password" />
                        <button  className='eye-password'
                            onClick={togglePasswordConf} 
                        >
                            { passwordConfType === "password" ? <img src="./images/closed-eye.png" /> :<img src="./images/open-eye.png" /> }
                        </button>
                        <label className={confirmPassword && 'filled'} htmlFor={"password"}>
                            {"Password"}
                        </label>
                    </div> */}

                        {/* INPUT TRAMITE MUI */}
                        {/* <StylesProvider injectFirst>                         */}
                        <div>
                            <p className="input-error">{formErrors.email}</p>
                            <TextField
                                // id="filled-basic"
                                label="Email *"
                                variant="filled"
                                fullWidth
                                name="email"
                                color="primary"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p className="input-error">{formErrors.password}</p>
                            <FormControl
                                sx={{ m: 1, width: '25ch' }}
                                variant="filled"
                                fullWidth
                            >
                                <InputLabel htmlFor="filled-adornment-password">
                                    Password *
                                </InputLabel>
                                <FilledInput
                                    // id="filled-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div>
                            <p className="input-error">
                                {formErrors.confirmPassword}
                            </p>
                            <FormControl
                                // sx={{ m: 1, width: '25ch' }}
                                variant="filled"
                                fullWidth
                            >
                                <InputLabel htmlFor="filled-adornment-password">
                                    Conferma Password *
                                </InputLabel>
                                <FilledInput
                                    // id="filled-adornment-password"
                                    // type={showPassword ? 'text' : 'password'}
                                    type={'password'}
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    // endAdornment={
                                    // <InputAdornment position="end">
                                    //     <IconButton
                                    //     aria-label="toggle password visibility"
                                    //     onClick={handleClickShowPassword}
                                    //     onMouseDown={handleMouseDownPassword}
                                    //     edge="end"
                                    //     >
                                    //     {showPassword ? <VisibilityOff /> : <Visibility />}
                                    //     </IconButton>
                                    // </InputAdornment>
                                    // }
                                />
                            </FormControl>
                        </div>
                        {/* </StylesProvider>  */}

                        <p className="input-error">
                            {formErrors.termsAccepted}
                        </p>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsAccepted}
                                    onChange={handleTermsChange}
                                />
                            }
                            label={
                                <div className="linksSignkangrats">
                                    <span className="spanlinks">
                                        Ho letto e accetto il
                                    </span>{' '}
                                    <Link
                                        href="https://kangrats.notion.site/Limyra-srl-Kangrats-Termini-e-Condizioni-efb3b725a09e42c5a220a6d9b0373f8f?pvs=73"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Contratto di licenza
                                    </Link>
                                    <span className="spanlinks">, l'</span>
                                    <Link
                                        href="https://kangrats.notion.site/Limyra-srl-Kangrats-Privacy-Policy-e9e2afe0f1df41468ee4aa33c89f5a8f?pvs=73"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Informativa sulla privacy
                                    </Link>
                                    <span className="spanlinks"> e l'</span>
                                    <Link
                                        href="https://kangrats.notion.site/Informativa-Cookies-Kangrats-4926e4f61e454f578023e21844686558?pvs=4"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Informativa sulla Cookie{' '}
                                    </Link>
                                    <span className="spanlinks">
                                        di Kangrats
                                    </span>
                                </div>
                            }
                        />
                        <button
                            className="primary-btn btn big-border-radius"
                            type="submit"
                            // disabled={!termsAccepted}
                        >
                            Accetta e iscriviti
                        </button>
                        <div>
                            <p className="or-line">
                                <span>o connettiti con</span>
                            </p>
                        </div>
                        <div className="linksSignkangrats">
                            <span className="spanlinks">
                                Cliccando su “Continua”, accetti il
                            </span>{' '}
                            <Link
                                href="https://kangrats.notion.site/Limyra-srl-Kangrats-Termini-e-Condizioni-efb3b725a09e42c5a220a6d9b0373f8f?pvs=73"
                                target="_blank"
                                title="kangrats.com"
                            >
                                Contratto di licenza
                            </Link>
                            <span className="spanlinks">, l'</span>
                            <Link
                                href="https://kangrats.notion.site/Limyra-srl-Kangrats-Privacy-Policy-e9e2afe0f1df41468ee4aa33c89f5a8f?pvs=73"
                                target="_blank"
                                title="kangrats.com"
                            >
                                Informativa sulla privacy
                            </Link>
                            <span className="spanlinks"> e l'</span>
                            <Link
                                href="https://kangrats.notion.site/Informativa-Cookies-Kangrats-4926e4f61e454f578023e21844686558?pvs=4"
                                target="_blank"
                                title="kangrats.com"
                            >
                                Informativa sulla Cookie{' '}
                            </Link>
                            <span className="spanlinks">di Kangrats</span>
                        </div>
                        <div className="login-methods space-evenly">
                            <Button
                                className="btn"
                                variant="contained"
                                onClick={handleGoogleSingIn}
                                style={{
                                    backgroundColor: '#fff',
                                    color: 'black',
                                    margin: '10px 0',
                                    border: '1px solid',
                                    borderRadius: '3.125rem',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    boxShadow: 'none',
                                    padding: '10px',
                                    textTransform: 'none',
                                }}
                                fullWidth
                            >
                                <GoogleIcon />
                                Continua con Google
                            </Button>
                            <Button
                                className="btn"
                                variant="contained"
                                onClick={handleFacebookSingIn}
                                style={{
                                    backgroundColor: '#fff',
                                    color: 'black',
                                    margin: '10px 0',
                                    border: '1px solid',
                                    borderRadius: '3.125rem',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    boxShadow: 'none',

                                    textTransform: 'none',
                                    padding: '10px 30px',
                                }}
                                fullWidth
                            >
                                <FacebookIcon />
                                Continua con Facebook
                            </Button>
                            {/* <div><img src="./images/apple-logo.jpg" alt="" /></div> */}
                        </div>
                    </form>
                </div>
            </main>
        </>
    )
}

export default SignupForm
