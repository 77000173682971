import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation, Route } from 'react-router-dom'
import AppContext from 'app/contexts/AppContext'
import useAuth from 'app/hooks/useAuth'
import useJWTAuth from 'app/hooks/useJWTAuth'
import DashboardLayout from 'app/Layouts/DashboardLayout'
import { authRoles } from './authRoles'
import LoadingAnimation from 'app/components/Animation/LoadingAnimation'
import Landscape from 'app/components/Landscape/Landscape'

const getUserRoleAuthStatus = (pathname, role, routes) => {
    const matched = routes.find((r) => {
        // console.log(r.path)
        if (r.path?.includes('offer_guid'))
            return pathname.includes('offertaTab') //to improve
        else return r.path === pathname
    })

    const authenticated =
        matched && matched.auth && matched.auth.length
            ? matched.auth.includes(role)
            : false
    // console.log(matched, role)
    return authenticated
}

const AuthGuard = ({ component: Component, ...rest }) => {
    const [isPortrait, setIsPortrait] = useState(true)

    const {
        isAuthenticated,
        isInitialised,
        role,
        onboarded,
        // user
    } = useAuth()

    const { isJWTAuthenticated } = useJWTAuth()

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()

    const { routes } = useContext(AppContext)
    const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        role,
        routes
    )
    // let authenticated = isAuthenticated && isUserRoleAuthenticated;

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE
    // let authenticated = isAuthenticated
    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    useEffect(() => {
        const handleOrientationChange = (e) => {
            const portrait = e.matches
            setIsPortrait(portrait)
        }

        const mediaQuery = window.matchMedia('(orientation: portrait)')
        mediaQuery.addEventListener('change', handleOrientationChange)

        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange)
        }
    }, [])

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isPortrait) {
                    return (
                        //   <div className="loading-component">
                        //     <p>Loading...</p>
                        //   </div>
                        <div className="app-swipe d-flex flex-column justify-center align-center">
                            <div className="d-flex flex-column justify-center align-center">
                                <Landscape />
                            </div>
                        </div>
                    )
                }

                if (!isInitialised) {
                    return (
                        //   <div className="loading-component">
                        //     <p>Loading...</p>
                        //   </div>
                        <div className="app-swipe d-flex flex-column justify-center align-center">
                            <div className="d-flex flex-column justify-center align-center">
                                <LoadingAnimation />
                            </div>
                        </div>
                    )
                }
                // debugger;
                if (
                    isAuthenticated &&
                    isUserRoleAuthenticated &&
                    onboarded &&
                    isJWTAuthenticated
                )
                    return (
                        // HO MODIFICATO IL LAYOUT LINKATO DELLE PAGINE INTERNE
                        <DashboardLayout>
                            <Component {...props} />
                        </DashboardLayout>
                    )
                else if (
                    isAuthenticated &&
                    role === '' &&
                    (onboarded === undefined || !onboarded) &&
                    isJWTAuthenticated
                ) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/choose-account',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                } else if (
                    isAuthenticated &&
                    role &&
                    (onboarded === undefined || !onboarded) &&
                    isJWTAuthenticated
                ) {
                    return (
                        <Redirect
                            to={{
                                pathname: authRoles.candidate.includes(role)
                                    ? '/signup-candidato'
                                    : 'signup-recruiter',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                } else if (
                    isAuthenticated &&
                    !isUserRoleAuthenticated &&
                    onboarded &&
                    isJWTAuthenticated
                ) {
                    return (
                        <Redirect
                            to={{
                                pathname: authRoles.candidate.includes(role)
                                    ? '/jump'
                                    : 'jump-recruiter',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/start',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                }
            }}
        />
    )
}

export default AuthGuard
