import React, { useEffect, useRef, useState } from 'react'
import useAuth from 'app/hooks/useAuth'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    DialogTitle,
    FormControlLabel,
    Button,
} from '@material-ui/core'
import validator from 'validator'

function ModifyData() {
    const [formErrors, setFormErrors] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const descriptionElementRef = useRef(null)
    const [isSubmit, setSubmit] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        oldpassword: null,
        password: '',
        confirmPassword: '',
    })

    const { oldpassword, password, confirmPassword, email } = userInfo

    const { user, updateUserPassword } = useAuth()

    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserInfo({ ...userInfo, [name]: value })
    }

    const handleSubmit = async () => {
        const res = await updateUserPassword(email, password, oldpassword)
        if (res.includes('invalid-login-credentials'))
            setFormErrors({
                oldpassword: 'Email o password corrente sbagliati',
            })
        // else
        //     setFormErrors({
        //         email: 'Qualcosa è andato storto... Riprovare in seguito.',
        //     })
    }

    const validate = () => {
        const errors = {}
        if (!email) errors.email = 'Questo campo non può essere vuoto'
        else if (!validator.isEmail(email))
            errors.email = 'Inserire una email in formato valido'
        if (
            user?.providerData.filter((x) => x.providerId === 'password')
                ?.length > 0 &&
            !oldpassword
        )
            errors.oldpassword = 'Questo campo non può essere vuoto'
        if (!password) errors.password = 'Questo campo non può essere vuoto'
        else if (!validator.isStrongPassword(password))
            errors.password = `La password deve contenere almeno: 8 caratteri, 1 lettera maiuscola, 1 lettera minuscola, 1 numero, 1 carattere speciale tra !.-_?^|&£$%/()`
        else if (oldpassword && password === oldpassword)
            errors.password = 'La nuova password è uguale a quella vecchia'
        if (!confirmPassword)
            errors.confirmPassword = 'Questo campo non può essere vuoto'
        else if (confirmPassword !== password)
            errors.confirmPassword = 'Le due password non corrispondono'
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit])

    useEffect(() => {
        if (user) {
            setUserInfo({ ...userInfo, email: user?.email })
        }
    }, [user])

    return (
        <>
            <div
                className="d-flex space-between dialog-title"
                style={{ display: 'contents' }}
            >
                <DialogTitle style={{ paddingInline: 0 }}>
                    Modifica password
                </DialogTitle>
                {/* <Button
                    onClick={handleClose}
                    color="primary"
                    className="close-dialog"
                >
                    <CloseIcon />
                </Button> */}
            </div>

            <div style={{ width: '75%', margin: 'auto' }}>
                <TextField
                    label="Indirizzo mail corrente"
                    variant="filled"
                    name="email"
                    value={email}
                    fullWidth
                    required
                    className="m1"
                    color="primary"
                    onChange={handleChange}
                />
                <p className="input-error">{formErrors.email}</p>

                {user?.providerData.find(
                    (x) => x.providerId === 'password'
                ) && (
                    <>
                        <TextField
                            label="Password corrente"
                            variant="filled"
                            name="oldpassword"
                            type="password"
                            fullWidth
                            value={oldpassword}
                            required={
                                user?.providerData.filter(
                                    (x) => x.providerId === 'password'
                                )?.length > 0
                            }
                            className="m1"
                            color="primary"
                            onChange={handleChange}
                        />
                        <p className="input-error">{formErrors.oldpassword}</p>
                    </>
                )}
                <FormControl
                    sx={{ m: 1, width: '25ch' }}
                    variant="filled"
                    className="m1"
                    fullWidth
                >
                    <InputLabel htmlFor="filled-adornment-password">
                        Nuova Password *
                    </InputLabel>
                    <FilledInput
                        // id="filled-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <p className="input-error">{formErrors.password}</p>

                <TextField
                    label="Ripeti nuova password"
                    variant="filled"
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    required
                    className="m1"
                    color="primary"
                    onChange={handleChange}
                />
                <p className="input-error">{formErrors.confirmPassword}</p>
                {/* <p style={{ textAlign: 'justify' }}>
                        Una conferma verrà inviata a questo account. Clicca sul
                        link di conferma per verificare e aggiungere questo
                        indirizzo email.
                    </p> */}
            </div>

            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '2rem',
                        marginTop: '2rem',
                    }}
                >
                    <Button
                        variant="contained"
                        className="btn-primary"
                        onClick={() => setSubmit(true)}
                        style={{ marginTop: '20px', color: '#fff' }}
                    >
                        Salva
                    </Button>
                </div>
            </div>
            {/* </Dialog> */}
        </>
    )
}

export default ModifyData
