import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    CircularProgress,
    TextField,
} from '@material-ui/core'
import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import { format } from 'date-fns'
import SectorDataTree from '../../Tables/ateco_table'
import ApiService from 'app/ApiService'
// react dropdown
// import Container from '../../TreeSelect/DropdownContainer'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import Sectors from '../../Tables/sectors_simplified'
import Chip from '@material-ui/core/Chip'
import EditFieldDialog from './FooterProfile/EditField'

import { styled } from '@mui/material/styles'
function WorkExperience({ explist, candidateguid, getExpList, loading }) {
    const descriptionElementRef = useRef(null)
    // per dialog
    const [open, setOpen] = useState(false)
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [loadingButton, setLoadingButton] = useState(false)
    // per dialog di conferma (vuoi eliminare definitivamente?)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    // user exp
    const [expList, setExpList] = useState([])
    const [listObjects, setListObjects] = useState([])
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const initUserInfo = {
        candidate_guid: candidateguid || '',
        job_title: '',
        company_name: '',
        location: '',
        sector: [],
        current_job: false,
        job_date_start: '',
        job_date_end: '',
        job_percentage: 100,
        description: '',
        ateco_description: '',
        company_description: '',
        freelance: false,
        isDesired: false,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        job_title,
        company_name,
        location,
        sector,
        current_job,
        job_date_start,
        job_date_end,
        job_percentage,
        description,
        isDesired,
    } = userInfo

    const handleClickOpen = () => () => {
        // chiudo le card in caso ce ne sono di aperte, se no è un problema coi dati
        const newListObjects = listObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setListObjects(newListObjects)

        setDialogText('Aggiungi')
        setOpen(true)
        setUserInfo({
            ...initUserInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClickUpdateDialogOpen = (expInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        setUserInfo({
            ...userInfo,
            id: expInfo.id,
            job_title: expInfo.job_title,
            company_name: expInfo.company_name,
            location: expInfo.location,
            sector: JSON.parse(expInfo.sector),
            current_job: expInfo.current_job,
            job_date_start: new Date(expInfo.job_date_start),
            job_date_end: new Date(expInfo.job_date_end),
            job_percentage: expInfo.job_percentage,
            description: expInfo.description,
            isDesired: expInfo.isDesired,
            lastUpdate: new Date(),
        })
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        // resetValue(IscedDataTree)
        resetValue(SectorDataTree)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const onChecked = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.checked,
        })
        console.log(userInfo)
    }

    const handleDateChangeStart = (date) => {
        setUserInfo({ ...userInfo, job_date_start: date })
    }

    const handleDateChangeEnd = (date) => {
        setUserInfo({ ...userInfo, job_date_end: date })
    }

    const onSectorSelect = (event, value) => {
        setUserInfo({
            ...userInfo,
            sector: value,
        })
    }

    const handleAddExp = async () => {
        setLoadingButton(true)
        const res = await ApiService.create_candexp(userInfo)
        if (res.ok) getExpList()
        handleClose()
        setLoadingButton(false)
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candexp(userInfo.id)
        if (res.ok) getExpList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        setLoadingButton(true)
        const res = await ApiService.update_candexp(userInfo)
        if (res.ok) getExpList()
        handleClose()
        setLoadingButton(false)
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }
    const handleFieldChange = (event) => {
        setIsEdited(true)
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }
    const toggleOpen = (index) => {
        const newListObjects = listObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setListObjects(newListObjects)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        setExpList(explist)

        const newListObjects = explist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setListObjects(newListObjects)
    }, [explist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}
        if (!job_percentage)
            errors.job_percentage = 'Questo campo non può essere nullo'
        // if (!job_lv4) errors.job_lv4 = "Questo campo non può essere nullo"
        if (!company_name)
            errors.company_name = 'Questo campo non può essere nullo'
        if (!job_title) errors.job_title = 'Questo campo non può essere nullo'
        if (!location) errors.location = 'Questo campo non può essere nullo'
        // if (!sector.length > 0) errors.sector = "Questo campo non può essere nullo"
        if (!job_date_start)
            errors.job_date_start = 'Questo campo non può essere nullo'
        if (!job_date_end && !current_job)
            errors.job_date_end = 'Questo campo non può essere nullo'
        if (
            job_date_start &&
            new Date(job_date_start).toString() === 'Invalid Date'
        )
            errors.job_date_start = 'Inserisci una data valida '
        if (
            job_date_end &&
            new Date(job_date_end).toString() === 'Invalid Date'
        )
            errors.job_date_end = 'Inserisci una data valida '
        if (job_date_start > new Date())
            errors.job_date_start =
                'La data non può essere successiva al giorno corrente'
        if (!current_job && job_date_end > new Date())
            errors.job_date_end =
                'La data non può essere successiva al giorno corrente'
        else if (job_date_end && job_date_end < job_date_start)
            errors.job_date_end =
                'La data di fine non può essere inferiore alla data di inizio'
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddExp()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (sector.length > 0) {
            sector.map((sec) => findValue(SectorDataTree, sec))
        }
        // if (isced) findValue(IscedDataTree, isced)
    }, [userInfo])

    const CustomInputLabel = styled(InputLabel)(({ theme }) => ({}))
    const renderForm = (index) => {
        // console.log(index)
        return (
            <div className="dropdown-form">
                <div className="d-flex align-center m1">
                    <h3 className="m0">
                        Descrivi questa esperienza, ci aiuterà a presentarti
                        meglio.
                    </h3>
                    <button onClick={handleDialogClose} className="close-card">
                        <CloseIcon />
                    </button>
                </div>

                <div className="dialog-layout">
                    <div>
                        <FormControl
                            variant="filled"
                            fullWidth
                            className="layoutBox"
                        >
                            <div>
                                <div>
                                    {/* <TextField
                            label="Ruolo"
                            variant="filled"
                            name="job_title"
                            value={job_title}
                            onChange={onChange}
                            fullWidth
                            required
                            color="primary"
                        /> */}

                                    <Autocomplete
                                        parentValue={job_title}
                                        onChange={(newValue) =>
                                            setUserInfo({
                                                ...userInfo,
                                                job_title: newValue,
                                                handleFieldChange,
                                            })
                                        }
                                        label={
                                            'Il tuo ruolo (es. cuoco, operaio, impiegato amministrativo..)'
                                        }
                                        apiRequest={ApiService.get_joblist}
                                        required
                                    />
                                    <p className="input-error">
                                        {formErrors.job_title}
                                    </p>
                                </div>
                            </div>
                        </FormControl>
                    </div>

                    {/* <div>
                        <p className="input-error">{formErrors.job_lv4}</p>
                        <Autocomplete
                            id="grouped-demo"
                            options={JobTitles}
                            groupBy={(option) => option.lev1_name}
                            getOptionLabel={(option) => option.lev4_name}
                            value={
                                JobTitles.find((x) => x.lev4_id === job_lv4) ||
                                null
                            }
                            onChange={onSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Famiglia professionale"
                                />
                            )}
                        />
                    </div> */}

                    {/* <FormControlLabel
                        label="Attività svolta come libero professionista"
                        control={
                            <Checkbox
                                checked={freelance}
                                onChange={onChecked}
                                name="freelance"
                                color="primary"
                                value={freelance}
                            />
                        }
                    /> */}
                </div>

                <div className="dialog-layout m0">
                    <div>
                        <TextField
                            label="Nome dell’azienda"
                            variant="filled"
                            name="company_name"
                            value={company_name}
                            onChange={handleFieldChange}
                            fullWidth
                            required
                            color="primary"
                        />
                        <p className="input-error">{formErrors.company_name}</p>
                    </div>
                    <div className="dialog-layout PartTempoBox">
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <CustomInputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Part time/tempo pieno
                                </CustomInputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={job_percentage}
                                    onChange={handleFieldChange}
                                    inputProps={{
                                        name: 'job_percentage',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />

                                    <option value={100}>Tempo pieno</option>
                                    <option value={50}>Part-time</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div>
                        <TextField
                            //  id="filled-basic"
                            label="Sede del lavoro"
                            variant="filled"
                            name="location"
                            value={location}
                            onChange={handleFieldChange}
                            fullWidth
                            required
                            color="primary"
                        />
                        <p className="input-error">{formErrors.location}</p>
                    </div>

                    <div>
                        {/* <Container
                            data={SectorDataTree}
                            onChange={onSectorSelect}
                            value={sector}
                            className="mdl-demo hide"
                            mode="multiSelect"
                            texts={{
                                placeholder: "Indicaci il settore dell'azienza",
                                noMatches: 'Nessun risultato',
                            }}
                        /> */}

                        <MuiAutocomplete
                            multiple
                            className="select-arr"
                            limitTags={4}
                            id="grouped-demo"
                            filterSelectedOptions
                            disableCloseOnSelect
                            noOptionsText={'Nessun risultato'}
                            options={Sectors}
                            getOptionLabel={(option) => option}
                            value={sector || null}
                            onChange={onSectorSelect}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option}
                                        className="custom-tag"
                                        {...getTagProps({ index })}
                                        style={{
                                            padding: '0.3rem 0.5rem',
                                            backgroundColor: '#f0f0f3',
                                            borderRadius: '0.65rem',
                                            marginRight: '0.5rem',
                                            fontSize: '0.9375rem',
                                            marginBottom: '4px',
                                            marginTop: '8px',
                                        }}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    className="no-outline"
                                    label="Settore dell’azienda"
                                    placeholder="Digita qui "
                                    onChange={handleFieldChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            minWidth: 200,
                                        },
                                    }}
                                />
                            )}
                        />
                        <p className="input-error">{formErrors.sector}</p>
                    </div>

                    <div className="hideOnMobile" />
                </div>

                {/* <div className="tags-container">
                    {sector &&
                        sector?.map((jobSectorSelected, index) => (
                            <div
                                className="d-flex align-center tags new-box"
                                key={index}
                                style={{
                                    backgroundColor: '#FFF',
                                    border: 'black',
                                }}
                            >
                                <p>{jobSectorSelected}</p>
                                <button
                                    onClick={(e) => {
                                        handleDeleteSector(e, index)
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        ))}
                </div> */}

                {/* <div className="dialog-layout m0">
                    <TextField
                        label="Descrivi la tua attività o azienda per rendere la tua esperienza più interessante (facoltativo)"
                        variant="filled"
                        fullWidth
                        color="primary"
                        name="company_description"
                        value={company_description}
                        onChange={onChange}
                        multiline
                        rows={3}
                        inputProps={{ maxLength: 500 }}
                        // placeholder="Descrivi la tua attività o azienda"
                    />
                </div> */}

                {/* <div className='hideOnMobile' /> */}
                <div className="dialog-layout dateboxes">
                    <div className="custom-input-padding">
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={itLocale}
                        >
                            <KeyboardDatePicker
                                sx={{
                                    width: '100%',
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#979797',
                                    }, //styles the label
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover > fieldset': {
                                            borderColor: '#C7C8CD',
                                        },
                                    },
                                }}
                                views={['year', 'month']}
                                variant="filled"
                                margin="normal"
                                label="Data di inizio impiego"
                                format="MM/yyyy"
                                value={job_date_start || null}
                                onChange={handleDateChangeStart}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk
                                fullWidth
                                required
                                disableFuture
                                invalidDateMessage=""
                                maxDateMessage=""
                                minDateMessage=""
                            />
                        </MuiPickersUtilsProvider>
                        <p className="input-error">
                            {formErrors.job_date_start}
                        </p>
                    </div>
                    {!current_job ? (
                        <div className="custom-input-padding">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={itLocale}
                            >
                                <KeyboardDatePicker
                                    views={['year', 'month']}
                                    margin="normal"
                                    label="Data di fine impiego"
                                    format="MM/yyyy"
                                    value={job_date_end || null}
                                    onChange={handleDateChangeEnd}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk
                                    fullWidth
                                    required
                                    disableFuture
                                    invalidDateMessage=""
                                    maxDateMessage=""
                                    minDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                            <p className="input-error">
                                {formErrors.job_date_end}
                            </p>
                        </div>
                    ) : (
                        <div className="hideOnMobile" />
                    )}
                </div>
                <FormControlLabel
                    className="checkboxdate"
                    control={
                        <Checkbox
                            checked={current_job}
                            onChange={onChecked}
                            name="current_job"
                            color="primary"
                            value={current_job}
                        />
                    }
                    label="Sto ancora lavorando qui"
                />

                <div className="dialog-layout">
                    <div>
                        <TextField
                            label="Descrivi la tua attività/mansione/responsabilità (facoltativo)"
                            variant="filled"
                            fullWidth
                            color="primary"
                            name="description"
                            value={description}
                            onChange={handleFieldChange}
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 500 }}
                            // placeholder="Descrizione percorso (facoltativo)"
                        />
                        <p
                            className={
                                description?.length < 500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 500 massimo
                        </p>
                    </div>
                </div>

                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                    disabled={loadingButton}
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    {loadingButton ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                left: '5%',
                            }}
                            color="inherit"
                            size={25}
                        />
                    ) : null}
                    {dialogText}
                </Button>
            </div>
        )
    }

    return (
        <>
            <section>
                <div>
                    <div className="section-title">
                        <h2>Raccontaci dei lavori che hai fatto.</h2>
                        <button
                            className="icon-btn"
                            hidden={
                                open || listObjects.some((item) => item.open)
                            }
                            onClick={handleClickOpen()}
                        >
                            <AddIcon />
                        </button>
                    </div>

                    {open && renderForm()}

                    {!loading &&
                        (expList.length === 0 ||
                            expList.every((x) => x.isDesired)) &&
                        !open && (
                            <div className="p1">
                                <div className="placeholder-card">
                                    <div className="bg-el"></div>
                                    <div className="d-flex flex-column justify-center align-center text-center h-100">
                                        <p className="accent-text-color">
                                            Inserisci le tue esperienze
                                            professionali per avere più successo
                                        </p>
                                        <button
                                            onClick={handleClickOpen()}
                                            className="primary-text-color"
                                        >
                                            Inizia qui
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    <div className="box-preview">
                        {loading ? (
                            <div className="card-preview new-box">
                                <div
                                    className="section-lightGray"
                                    id="skeltonExperience"
                                >
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="50%"
                                            height="35px"
                                        />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="70%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="90%" />
                                    </div>

                                    <div>
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            expList.map((exp, index) => {
                                if (!exp.isDesired)
                                    return (
                                        <div
                                            className="card-preview new-box"
                                            key={index}
                                        >
                                            {listObjects[index].open ? (
                                                renderForm(index)
                                            ) : (
                                                <div className="lightgray-studies">
                                                    <div className="section-lightGray studies">
                                                        <div className="d-flex actions-btns">
                                                            <button
                                                                onClick={handleClickUpdateDialogOpen(
                                                                    exp,
                                                                    index
                                                                )}
                                                                className="icon-btn"
                                                            >
                                                                <EditIcon />
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    handleClickOpenConfirm(
                                                                        exp.id
                                                                    )
                                                                }
                                                                className="icon-btn"
                                                            >
                                                                <BinIcon />
                                                            </button>
                                                        </div>
                                                        <h3 className="bold text-ellipsis-2">
                                                            {exp.job_title}
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <div className="Experince">
                                                            <div className="Exp-left">
                                                                <h3>
                                                                    {exp.company_name ? (
                                                                        <>
                                                                            🏢{' '}
                                                                            {
                                                                                exp.company_name
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </h3>
                                                                {JSON.parse(
                                                                    exp.sector
                                                                ).length > 0 ? (
                                                                    <p>
                                                                        🏭{' '}
                                                                        {JSON.parse(
                                                                            exp.sector
                                                                        ).map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) => (
                                                                                <span
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item
                                                                                    }
                                                                                    <br />
                                                                                </span>
                                                                            )
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    <p></p>
                                                                )}
                                                                {exp.location ? (
                                                                    <p>
                                                                        📍{' '}
                                                                        {
                                                                            exp.location
                                                                        }
                                                                    </p>
                                                                ) : null}
                                                            </div>
                                                            <div className="Exp-right">
                                                                <p>
                                                                    ⏱️{' '}
                                                                    {exp.job_percentage ===
                                                                    '100'
                                                                        ? 'Tempo Pieno'
                                                                        : 'Part Time'}
                                                                </p>
                                                                <div className="exp-tempo">
                                                                    📅
                                                                    <div className="Exp-dates">
                                                                        <p>
                                                                            {' '}
                                                                            Data
                                                                            inizio:{' '}
                                                                            {format(
                                                                                new Date(
                                                                                    exp.job_date_start
                                                                                ),
                                                                                'MM/yyyy'
                                                                            )}{' '}
                                                                        </p>
                                                                        <p>
                                                                            Data
                                                                            fine:{' '}
                                                                            {!exp.current_job
                                                                                ? format(
                                                                                      new Date(
                                                                                          exp.job_date_end
                                                                                      ),
                                                                                      'MM/yyyy'
                                                                                  )
                                                                                : 'In corso'}{' '}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>{exp.description}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                            })
                        )}
                    </div>
                </div>
            </section>

            {/* DIALOG PER ELIMINARE LA CARD */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="primary">
                        Elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>

            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default WorkExperience
