import React, { Fragment, useState } from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core'
import { useEffect } from 'react'
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import itLocale from 'date-fns/locale/it';
// import DateFnsUtils from '@date-io/date-fns';
import title_type_onboarding from '../../Tables/title_type_onboarding'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import './OnboardingEdu.css'

const SectionTitles = [
    'Qual è il tuo titolo di studio più rilevante?',
    // "Dove e quando hai studiato?"
]

function OnboardingEducation({
    stepOnboarding,
    setStepOnboarding,
    skipDetails,
    setSkipDetails,
}) {
    const [stepOnboardingEducation, setStepOnboardingEducation] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [appState, setAppState] = useState(false)

    const initUserInfo = {
        candidate_guid: '',
        eqf_label: '',
        eqf_value: 0,
        title_name: '',
        parent_title: '',
        other_title: '',
        school: '',
        erasmus: 'false',
        erasmus_description: '',
        publications: 'false',
        publications_description: '',
        graduation_grade: null,
        laude: 'false',
        current_title: false,
        title_date_start: '',
        title_date_end: '',
        description: '',
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        eqf_label,
        eqf_value,
        title_name,
        parent_title,
        other_title,
        school,
        erasmus,
        erasmus_description,
        publications,
        publications_description,
        graduation_grade,
        laude,
        current_title,
        title_date_start,
        title_date_end,
        description,
        created,
        lastUpdate,
    } = userInfo

    const {
        user,
        // user
    } = useAuth()

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_candtitlebyguid(
                candidate_guid
            )
            const body = await response.json()
            console.log(body)

            if (body?.length > 0) {
                let titleInfo = body[0]
                setUserInfo({
                    ...userInfo,
                    id: titleInfo.id,
                    title_name: titleInfo.title_name || '',
                    parent_title: titleInfo.parent_title,
                    school: titleInfo.school,
                    eqf_label: titleInfo.eqf_label,
                    eqf_value: titleInfo.eqf_value,
                    other_title: titleInfo.other_title,
                    erasmus: titleInfo.erasmus.toString(),
                    erasmus_description: titleInfo.erasmus_description,
                    // publications: titleInfo[9].toString(),
                    // publications_description: titleInfo[10],
                    graduation_grade: titleInfo.graduation_grade,
                    // laude: titleInfo[10],
                    current_title: titleInfo.current_title,
                    title_date_start: new Date(titleInfo.title_date_start),
                    title_date_end: new Date(titleInfo.title_date_end),
                    description: titleInfo.description,
                    lastUpdate: new Date(),
                })

                const filterLabel = title_type_onboarding.showMore.filter(
                    (x) => x.label === titleInfo.eqf_value
                )
                if (filterLabel.length <= 0) setShowMore(true)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    // per eqf: media, superirore, laure...
    const onEqfSelect = (event) => {
        const selectedValue = event.target.value

        if (selectedValue === userInfo.eqf_label) {
            setUserInfo({
                ...userInfo,
                eqf_label: '',
                eqf_value: null,
                other_title: null,
                title_name: null,
                parent_title: null,
                school: '',
                erasmus: 'false',
                erasmus_description: '',
                publications: 'false',
                publications_description: '',
                graduation_grade: null,
                laude: 'false',
                current_title: false,
                title_date_start: '',
                title_date_end: '',
                description: '',
                lastUpdate: new Date(),
            })
            setSkipDetails({ ...skipDetails, skip_titles: false })
        } else {
            const val =
                title_type_onboarding.show.filter(
                    (x) => x.label === selectedValue
                )[0] ||
                title_type_onboarding.showMore.filter(
                    (x) => x.label === selectedValue
                )[0]

            if (val) {
                if (val.value >= 3) {
                    setUserInfo({
                        ...userInfo,
                        eqf_label: val.label,
                        eqf_value: val.value,
                        school: '',
                        erasmus: 'false',
                        erasmus_description: '',
                        publications: 'false',
                        publications_description: '',
                        graduation_grade: null,
                        laude: 'false',
                        current_title: false,
                        title_date_start: '',
                        title_date_end: '',
                        description: '',
                        lastUpdate: new Date(),
                    })
                } else {
                    setUserInfo({
                        ...userInfo,
                        eqf_label: val.label,
                        eqf_value: val.value,
                        other_title: null,
                        title_name: null,
                        parent_title: null,
                        school: '',
                        erasmus: 'false',
                        erasmus_description: '',
                        publications: 'false',
                        publications_description: '',
                        graduation_grade: null,
                        laude: 'false',
                        current_title: false,
                        title_date_start: '',
                        title_date_end: '',
                        description: '',
                        lastUpdate: new Date(),
                    })
                }

                if (val.value <= 2) {
                    setSkipDetails({ ...skipDetails, skip_titles: true })
                } else {
                    setSkipDetails({ ...skipDetails, skip_titles: false })
                }
            }
        }
    }

    const onSelect = (value) => {
        console.log(value)
        setUserInfo({
            ...userInfo,
            other_title: value,
        })
        console.log(userInfo)
    }

    async function handleNext() {
        // if (skipDetails.skip_titles) {
        const res = await ApiService.create_candtitle(userInfo)
        if (res.ok) {
            setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
            // setStepOnboardingEducation((prevActiveStep) => (prevActiveStep !== SectionTitles.length - 1 ? prevActiveStep + 1 : prevActiveStep))
        }
    }

    const validate = () => {
        const errors = {}
        switch (stepOnboardingEducation) {
            case 0:
                if (!eqf_label) errors.eqf_label = 'Scegli uno tra questi campi'
                if (eqf_value >= 3 && !other_title)
                    errors.other_title = 'Questo campo non può essere nullo'
                break
            // case 1:
            //     if (!eqf_label) errors.eqf_label = "Questo campo non può essere nullo"
            //     if (!school) errors.school = "Questo campo non può essere nullo"
            //     if (!other_title) errors.other_title = "Questo campo non può essere nullo"
            //     // if (!other_title) errors.other_title = "Questo campo non può essere nullo"
            //     if (!title_date_start) errors.title_date_start = "Questo campo non può essere nullo"
            //     if (!title_date_end && !current_title) errors.title_date_end = "Questo campo non può essere nullo"
            //     if (title_date_start && new Date(title_date_start).toString() === 'Invalid Date') errors.title_date_start = "Inserisci una data valida "
            //     if (title_date_end && new Date(title_date_end).toString() === 'Invalid Date') errors.title_date_end = "Inserisci una data valida "
            //     else if (title_date_start > new Date()) errors.title_date_start = "La data non può essere successiva al giorno corrente"
            //     else if (title_date_end > new Date()) errors.title_date_end = "La data non può essere successiva al giorno corrente"
            //     else if (title_date_end && title_date_end < title_date_start) errors.title_date_end = "La data di fine non può essere infeririore alla data di inizio"
            //     break;
        }
        return errors
    }

    async function getData() {
        await getTitleList()
        setAppState(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            } else setSubmit(false)
        }
    }, [isSubmit, userInfo])

    // // fa la submit solo alla pagina 2 di istruzione?
    // // se sovrascrivo "scuola media" non funziona perchè in pagina 1 il button non effettua submit?
    // useEffect(() => {
    //     if (userInfo.eqf_value <= 2) {
    //         console.log(userInfo.eqf_value)
    //         setSkipDetails({ ...skipDetails, skip_titles: true })
    //     }
    // }, [])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({ ...userInfo, candidate_guid: user?.uid })
            setAppState(true)
        }
    }, [user])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="onboarding-grid">
                <h2 className="smallheader noMrgMob">
                    {SectionTitles[stepOnboardingEducation]}
                </h2>

                {(() => {
                    switch (stepOnboardingEducation) {
                        case 0:
                            return (
                                <Fragment>
                                    <p
                                        className="input-error text-center onboarding"
                                        style={{ fontWeight: '400' }}
                                    >
                                        {formErrors.eqf_label}
                                    </p>

                                    <FormControl
                                        component="fieldset"
                                        className="tags-check"
                                        style={{ width: '100%' }}
                                    >
                                        {/* <FormLabel component="legend">Istruzione</FormLabel> */}
                                        <RadioGroup
                                            aria-label="Istruzione"
                                            name="istruzione"
                                            value={eqf_label}
                                            onChange={onEqfSelect}
                                            onClick={(event) =>
                                                onEqfSelect(event)
                                            }
                                        >
                                            <div className="container-Study">
                                                <div className="first-choice-container">
                                                    <FormControlLabel
                                                        key={
                                                            title_type_onboarding
                                                                .show[0].label
                                                        }
                                                        value={
                                                            title_type_onboarding
                                                                .show[0].label
                                                        }
                                                        control={<Radio />}
                                                        label={
                                                            title_type_onboarding
                                                                .show[0].label
                                                        }
                                                        className="large-button"
                                                    />
                                                </div>
                                                <div className="educationOtheChoice">
                                                    <div className="scuole-and-triennale-container">
                                                        <FormControlLabel
                                                            key={
                                                                title_type_onboarding
                                                                    .show[1]
                                                                    .label
                                                            }
                                                            value={
                                                                title_type_onboarding
                                                                    .show[1]
                                                                    .label
                                                            }
                                                            control={<Radio />}
                                                            label={
                                                                title_type_onboarding
                                                                    .show[1]
                                                                    .label
                                                            }
                                                            className="scuole-medie-button"
                                                        />
                                                        <FormControlLabel
                                                            key={
                                                                title_type_onboarding
                                                                    .show[2]
                                                                    .label
                                                            }
                                                            value={
                                                                title_type_onboarding
                                                                    .show[2]
                                                                    .label
                                                            }
                                                            control={<Radio />}
                                                            label={
                                                                title_type_onboarding
                                                                    .show[2]
                                                                    .label
                                                            }
                                                            className="laurea-triennale-button"
                                                        />
                                                    </div>

                                                    <div className="other-choices-container">
                                                        <FormControlLabel
                                                            key={
                                                                title_type_onboarding
                                                                    .show[3]
                                                                    .label
                                                            }
                                                            value={
                                                                title_type_onboarding
                                                                    .show[3]
                                                                    .label
                                                            }
                                                            control={<Radio />}
                                                            label={
                                                                title_type_onboarding
                                                                    .show[3]
                                                                    .label
                                                            }
                                                            className="custom-radio-label"
                                                        />
                                                        <div>
                                                            <button
                                                                onClick={() => {
                                                                    setShowMore(
                                                                        !showMore
                                                                    )
                                                                }}
                                                                className={
                                                                    showMore
                                                                        ? 'underline-text'
                                                                        : 'underline-text'
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        'transparent',
                                                                    border: 'none',
                                                                    boxShadow:
                                                                        'none',
                                                                    outline:
                                                                        'none',
                                                                    color: '#4b4c55',
                                                                    fontweight:
                                                                        '600',
                                                                    gap: '0',
                                                                }}
                                                            >
                                                                Altro{' '}
                                                                {showMore ? (
                                                                    <ArrowDropUpIcon />
                                                                ) : (
                                                                    <ArrowDropDownIcon />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <button onClick={() => { setShowMore(!showMore) }}>Altro  {showMore ? "\u2BC5" : "\u2BC6"}  </button> */}
                                            <div className="show-more-grid">
                                                {showMore &&
                                                    title_type_onboarding.showMore.map(
                                                        (title) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={
                                                                        title.label
                                                                    }
                                                                    value={
                                                                        title.label
                                                                    }
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    label={
                                                                        title.label
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    )}
                                            </div>
                                        </RadioGroup>
                                    </FormControl>

                                    {eqf_value >= 3 && (
                                        <div
                                            className="input-container "
                                            style={{ gap: '0' }}
                                        >
                                            <h2
                                                className="noMrgMob"
                                                style={{ marginBottom: '0' }}
                                            >
                                                Cosa hai studiato?
                                            </h2>
                                            <div>
                                                <p className="input-error text-center onboarding">
                                                    {formErrors.other_title}
                                                </p>
                                                <Autocomplete
                                                    parentValue={other_title}
                                                    onChange={onSelect}
                                                    label={'Percorso di studi'}
                                                    apiRequest={(newValue) =>
                                                        ApiService.get_titlelist(
                                                            newValue,
                                                            eqf_value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            )
                        // case 1:
                        //     return (
                        //         <>
                        //             <div className="input-container">
                        //                 {/* <div>
                        //                     <p className='input-error'>{formErrors.other_title}</p>
                        //                     <TextField
                        //                         label="Nome completo titolo"
                        //                         variant="filled"
                        //                         name="other_title"
                        //                         value={other_title}
                        //                         onChange={onChange}
                        //                         fullWidth
                        //                         required
                        //                         color="primary"
                        //                     />
                        //                 </div> */}

                        //                 <div>
                        //                     <p className="input-error">
                        //                         {formErrors.other_title}
                        //                     </p>
                        //                     {/* <Autocomplete
                        //                         id="grouped-demo"
                        //                         options={TitleDataTree.sort((a, b) =>
                        //                             a.parent_label > b.parent_label ? 1 : -1
                        //                         ).filter((x) => x.eqf.includes(eqf_value))}
                        //                         groupBy={(option) => option.parent_label}
                        //                         getOptionLabel={(option) => option.label}
                        //                         value={
                        //                             TitleDataTree.find(
                        //                                 (x) =>
                        //                                     x.key === title_name &&
                        //                                     x.eqf.includes(eqf_value)
                        //                             ) || null
                        //                         }
                        //                         onChange={onSelect}
                        //                         renderInput={(params) => (
                        //                             <TextField
                        //                                 {...params}
                        //                                 required
                        //                                 label="Seleziona titolo"
                        //                             />
                        //                         )}
                        //                     /> */}
                        //                     <Autocomplete
                        //                         parentValue={other_title}
                        //                         onChange={onSelect}
                        //                         label={"Scrivi qui il titolo completo (es. ITIS, Liceo Classico, ...)"}
                        //                         apiRequest={(newValue) => ApiService.get_titlelist(newValue, eqf_value)}
                        //                         required
                        //                     />
                        //                 </div>

                        //                 <div>
                        //                     <p className='input-error'>{formErrors.school}</p>
                        //                     <TextField
                        //                         label="Nome istituto"
                        //                         variant="filled"
                        //                         name="school"
                        //                         value={school}
                        //                         onChange={onChange}
                        //                         fullWidth
                        //                         required
                        //                         color="primary"
                        //                     />
                        //                 </div>

                        //                 <FormControlLabel
                        //                     control={
                        //                         <Checkbox
                        //                             checked={current_title}
                        //                             onChange={onChecked}
                        //                             value={current_title}
                        //                             name="current_title"
                        //                         />
                        //                     }
                        //                     label="Sto attualmente studiando qui"
                        //                 />

                        //                 <div className="custom-input-padding">
                        //                     <p className='input-error'>{formErrors.title_date_start}</p>
                        //                     <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                        //                         <KeyboardDatePicker
                        //                             views={['year', 'month']}
                        //                             margin="normal"
                        //                             label="Data inizio"
                        //                             format="MM/yyyy"
                        //                             value={title_date_start || null}
                        //                             onChange={handleDateChangeStart}
                        //                             KeyboardButtonProps={{
                        //                                 'aria-label': 'change date',
                        //                             }}
                        //                             autoOk
                        //                             fullWidth
                        //                             required
                        //                             disableFuture
                        //                             invalidDateMessage=''
                        //                             maxDateMessage=""
                        //                             minDateMessage=""
                        //                         />
                        //                     </MuiPickersUtilsProvider>
                        //                 </div>

                        //                 <div className={current_title ? "custom-input-padding  opacity-low" : "custom-input-padding"}  >
                        //                     <p className='input-error'>{formErrors.title_date_end}</p>
                        //                     <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                        //                         <KeyboardDatePicker
                        //                             views={['year', 'month']}
                        //                             margin="normal"
                        //                             label="Data fine"
                        //                             format="MM/yyyy"
                        //                             value={title_date_end || null}
                        //                             onChange={handleDateChangeEnd}
                        //                             KeyboardButtonProps={{
                        //                                 'aria-label': 'change date',
                        //                             }}
                        //                             autoOk
                        //                             fullWidth
                        //                             required
                        //                             disableFuture
                        //                             invalidDateMessage=''
                        //                             maxDateMessage=""
                        //                             minDateMessage=""
                        //                             disabled={current_title}
                        //                         />
                        //                     </MuiPickersUtilsProvider>
                        //                 </div>

                        //                 <TextField
                        //                     label="Voto finale"
                        //                     variant="filled"
                        //                     name="graduation_grade"
                        //                     type='text'
                        //                     value={graduation_grade || ''}
                        //                     onChange={onChange}
                        //                     fullWidth
                        //                     color="primary"
                        //                 />
                        //             </div>
                        //         </>
                        //     )
                    }
                })()}
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingEducation
