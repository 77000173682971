import { FormControl, InputLabel, Select, TextField } from '@material-ui/core'
import React, { useEffect, useRef, useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FotoProfilo from './crop/utils/FotoProfilo'
import CloseIcon from 'app/components/Icons/CloseIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import StorageContext from '../../../contexts/FirebaseStorageContext'
import { Autocomplete } from '@material-ui/lab'
import Sectors from '../../Tables/sectors_simplified'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@mui/material/CircularProgress'
import ProfileCompletion from './ProfileCompletion/ProfileCompletionRecruiter'
import EditFieldDialog from './FooterProfile/EditField'
import ApiService from 'app/ApiService'
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import { useHistory } from 'react-router-dom'

function GradientCircularProgress({ value, imageSrc }) {
    const classes = useStyles()

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {/* Background Circle */}
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    color: 'gray',
                }}
                size="9rem"
                thickness={3}
            />
            {/* Gradient Progress */}
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: '#ea6c3a',
                    position: 'absolute',
                    left: 0,
                    borderRadius: '50%',
                    width: '9rem !important',
                    height: '9rem  !important',
                    [`& .MuiCircularProgress-circle`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size="9rem"
                thickness={3}
            />

            {/* Center Image */}
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={imageSrc}
                    alt="Profile"
                    style={{
                        borderRadius: '50%',
                        width: '75%',
                        height: '75%',
                        objectFit: 'cover',
                        position: 'absolute',
                        margin: 'auto',
                        left: '1%',
                        right: '1%',
                    }}
                />

                {value === 100 && (
                    <CheckCircleIcon className={classes.customIcon} />
                )}
            </Box>

            {value !== 100 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: -5,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        background: '#ea6c3a',
                        width: '2rem',
                        borderRadius: '5px',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
const useStyles = makeStyles(() => ({
    customIcon: {
        backgroundColor: '#FFF',
        fontSize: '1.5rem',
        borderRadius: '150px',
    },
}))

function BioCompany({
    user,
    userInfo,
    onChange,
    onNodeSelect,
    handleSubmit,
    onClose,
    setUserInfo,
    loading,
}) {
    const { photoURL, setPhotoURL } = useContext(StorageContext)
    const [localPhotoURL, setLocalPhotoURL] = useState(photoURL)
    //per dialog
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setSubmit] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [offerInfo, setOfferInfo] = useState({})
    const [hsList, setHsList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [expList, setExpList] = useState([])
    const [langList, setLangList] = useState([])
    const [appState, setAppState] = useState(false)
    const [computeScore, setComputeScore] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressChecks, setProgressChecks] = useState({
        photo: false,
        education: false,
        exp: false,
        soft: false,
        hard: false,
        langs: false,
        bio: false,
    })
    // PER CROP

    const [photo, setPhoto] = useState()
    const {
        recruiter_guid,
        name,
        surname,
        birth_date,
        company_name,
        headquarters,
        phone,
        gender,
        email,
        size,
        description,
        sector,
        photo_url,
        webpage,
        facebook,
        instagram,
        linkedin,
        created,
        lastUpdate,
    } = userInfo

    const {
        offer_guid,
        job_name,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        isced,
        ral,
        ral_type,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        start_job_date,
        offer_description,
        active,
        isAvailable,
    } = offerInfo

    // console.log(userInfo)
    useEffect(() => {
        setLocalPhotoURL(photoURL)
    }, [photoURL])

    const { uploadPhoto } = useContext(StorageContext)
    useEffect(() => {
        setLocalPhotoURL(photoURL)
    }, [photoURL])

    const handleClickOpen = (scrollType) => () => {
        setOpen(true)
        setScroll(scrollType)
    }

    const handleClose = () => {
        setPhoto(null)
        if (user?.photoURL) {
            setPhotoURL(user.photoURL)
            setLocalPhotoURL(user.photoURL)
        } else {
            setPhotoURL('images/prof.png')
            setLocalPhotoURL('images/prof.png')
        }
        onClose()
        setOpen(false)
        setFormErrors({})
    }

    const updatePhoto = async (event) => {
        event.preventDefault()
        if (photo) {
            const res = await uploadPhoto(photo)
            if (res) {
                setUserInfo({ ...userInfo, photo_url: res })
                setPhotoURL(res)
                setLocalPhotoURL(res)
            }
        }
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}

        if (!company_name)
            errors.company_name = 'Questo campo non può essere nullo'
        if (!headquarters)
            errors.headquarters = 'Questo campo non può essere nullo'
        // if (!size) errors.size = 'Questo campo non può essere nullo'

        return errors
    }

    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_activeofferbyguid(
                recruiter_guid
            ) //verificare che offer_guid appartenga al recruiter corretto!
            const body = await response.json()
            console.log(body)

            setOfferInfo({
                ...offerInfo,
                offer_guid: body.offer_guid,
                job_name: body.job_name,
                location: body.location,
                geolocation: body.geolocation,
                driving_license: body.driving_license,
                job_contract: body.job_contract,
                job_type: body.job_type,
                protected_category: body?.protected_category,
                ral: body.ral,
                ral_type: body?.ral_type || false,
                // photo_url: body.photo_url,
                description: body.description,
                active: body?.active,
                start_job_date: body.start_job_date
                    ? new Date(body.start_job_date)
                    : null,
                distance_filter: body.distance_filter,
                isAvailable: body.isAvailable,
                isFindable: body.isFindable,
                offer_description: body.description,
            })

            setAppState(true)
        } catch (err) {
            console.error(err.message)
            // setOfferInfo(null)
        }
    }

    const getHsList = async () => {
        try {
            const response = await ApiService.get_offershsbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setHsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getSsList = async () => {
        try {
            const response = await ApiService.get_offersssbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_offerstitlebyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        try {
            const response = await ApiService.get_offersexpbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLangList = async () => {
        const response = await ApiService.get_offersLangbyguid(offer_guid)
        const body = await response.json()
        // console.log(body)

        try {
            setLangList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    function findObjectWithFewestEmptyProperties(objects) {
        if (objects.length === 0) return null // Handle empty array

        return objects.reduce((bestObject, currentObject) => {
            const countEmpty = (obj) =>
                Object.values(obj).filter(
                    (value) => value === null || value === ''
                ).length

            // If bestObject is null (initial case), start with currentObject
            if (!bestObject) return currentObject

            // Compare the number of empty elements in the current object to the best object found so far
            return countEmpty(currentObject) < countEmpty(bestObject)
                ? currentObject
                : bestObject
        }, null)
    }

    function calculateProgress() {
        let progressScore = 0
        if (photo_url) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                photo: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                photo: false,
            }))
        }

        if (description) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                description: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                description: false,
            }))
        }

        if (sector?.length > 0) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                sector: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                sector: false,
            }))
        }

        let bioCheck = 0
        if (start_job_date) {
            progressScore += 1
            bioCheck += 1
        }

        if (offer_description) {
            progressScore += 1
            bioCheck += 1
        }

        if (bioCheck >= 2) {
            setProgressChecks((prevState) => ({
                ...prevState,
                bio: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                bio: false,
            }))
        }

        const data = findObjectWithFewestEmptyProperties(expList)
        let dataCheck = 0
        if (data) {
            if (data.job_title) {
                dataCheck += 1
                progressScore += 1
            }
            if (data.sector?.length > 0) {
                dataCheck += 1
                progressScore += 1
            }
        }
        if (dataCheck >= 2) {
            setProgressChecks((prevState) => ({
                ...prevState,
                exp: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                exp: false,
            }))
        }

        const edudata = findObjectWithFewestEmptyProperties(titleList)
        let eduCheck = 0
        if (edudata) {
            if (edudata.eqf_label) {
                eduCheck += 1
                progressScore += 1
            }

            if (edudata.other_title) {
                eduCheck += 1
                progressScore += 1
            }
        }
        if (eduCheck >= 2) {
            setProgressChecks((prevState) => ({
                ...prevState,
                education: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                education: false,
            }))
        }

        if (ssList.length >= 5) {
            progressScore += 5
            setProgressChecks((prevState) => ({
                ...prevState,
                soft: true,
            }))
        } else {
            progressScore += ssList.length
            setProgressChecks((prevState) => ({
                ...prevState,
                soft: false,
            }))
        }

        if (hsList.length >= 5) {
            progressScore += 5
            setProgressChecks((prevState) => ({ ...prevState, hard: true }))
        } else {
            progressScore += hsList.length
            setProgressChecks((prevState) => ({ ...prevState, hard: false }))
        }

        if (langList.length > 0) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                langs: true,
            }))
        } else
            setProgressChecks((prevState) => ({
                ...prevState,
                langs: false,
            }))

        setProgress(progressScore)
        setComputeScore(false)
    }

    const setGeoLocationInfo = (place, geolocation) => {
        // console.log(geolocation)
        setUserInfo({
            ...userInfo,
            headquarters: place,
        })
    }

    async function getData() {
        // await getOfferInfo()
        await getExpList()
        await getTitleList()
        // await getCertList()
        await getSsList()
        await getLangList()
        await getHsList()
        setAppState(false)
        setComputeScore(true)
    }

    useEffect(() => {
        if (computeScore) calculateProgress()
    }, [computeScore])

    useEffect(() => {
        if (user?.photoURL) {
            setLocalPhotoURL(user.photoURL)
            setPhotoURL(user.photoURL)
        }
    }, [user, setPhotoURL])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleSubmit()
                handleClose()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.photoURL) setPhotoURL(user.photoURL)
    }, [user])

    useEffect(() => {
        // if (sector) sector.map((sec) => findValue(SectorDataTree, sec))
        setUserInfo(userInfo)
        if (!isEdited) getOfferInfo()
    }, [userInfo])

    useEffect(() => {
        if (appState) {
            getData()
        }
    }, [appState])

    const handleFieldChange = (e) => {
        setIsEdited(true)
        onChange(e)
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleSectorChange = (newSectors) => {
        setIsEdited(true)
        setUserInfo({ ...userInfo, sector: newSectors })
    }

    const SimpleAutocomplete = ({ options, onChange }) => {
        const handleSelect = (value) => {
            if (value) {
                onChange([...sector, value])
            }
        }

        const handleDelete = (optionToDelete) => {
            onChange(sector.filter((option) => option !== optionToDelete))
        }

        return (
            <div>
                <div className="d-flex flex-wrap">
                    {sector.map((option, index) => (
                        <div key={index} className="new-box tags-container">
                            <div
                                className="d-flex align-center tags "
                                style={{ background: '#f0f0f3' }}
                            >
                                <p>{option}</p>
                                <button
                                    className="icon-btn"
                                    onClick={() => handleDelete(option)}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <Autocomplete
                    options={options}
                    freeSolo
                    onChange={(event, newValue) => handleSelect(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Scrivi una parola chiave"
                        />
                    )}
                />
            </div>
        )
    }
    const history = useHistory()
    const handleNavigateToPdf = () => {
        history.push('/Pdf_Offer', {
            offerInfo,
            userInfo,
            expList,
            hsList,
            ssList,
            langList,
            titleList,
        })
    }

    return (
        <>
            <article className="profile-card section-lightGray">
                <div
                    className="d-flex align-center"
                    style={{ position: 'relative' }}
                >
                    <button
                        className="icon-btn shift-icon"
                        onClick={handleClickOpen('paper')}
                        style={{ position: 'absolute', right: '0' }}
                    >
                        <EditIcon />
                    </button>
                    <div>
                        {loading ? (
                            <Skeleton variant="circle">
                                <img alt="" className="profile-photo" />
                            </Skeleton>
                        ) : (
                            <>
                                <GradientCircularProgress
                                    value={Math.round((progress / 20) * 100)}
                                    imageSrc={localPhotoURL}
                                />
                            </>
                        )}
                    </div>
                    <ul>
                        {/* <li>{name} {surname}</li> */}

                        {loading ? (
                            <div>
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={110} />
                                <Skeleton variant="text" width={120} />
                                <Skeleton variant="text" width={130} />
                                <Skeleton variant="text" width={210} />
                            </div>
                        ) : (
                            <div>
                                <>
                                    {headquarters && <li>📌 {headquarters}</li>}
                                    {size && <li>🏭 {`${size} dipendenti`}</li>}
                                    <li>
                                        {sector && (
                                            <>
                                                🗂{' '}
                                                {sector?.map((sec, index) => (
                                                    <span key={index}>
                                                        {sec}{' '}
                                                    </span>
                                                ))}
                                            </>
                                        )}
                                    </li>
                                    {loading ? (
                                        <div className="buttons-Candidate rec">
                                            <Skeleton
                                                variant="rectangular"
                                                width={180}
                                                height={50}
                                                style={{ marginRight: 16 }}
                                            />
                                            <Skeleton
                                                variant="rectangular"
                                                width={180}
                                                height={50}
                                                style={{ marginRight: 16 }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                progress === 100
                                                    ? 'button-candidate rec'
                                                    : 'buttons-Candidate rec'
                                            }
                                        >
                                            {progress < 100 && (
                                                <ProfileCompletion
                                                    progressChecks={
                                                        progressChecks
                                                    }
                                                />
                                            )}
                                            {/* <button
                                                className="btn-navigate-popup pdf-button"
                                                onClick={handleNavigateToPdf}
                                            >
                                                Scarica KV
                                            </button> */}
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                    </ul>
                </div>
                <div>
                    {loading ? (
                        <div>
                            {/* Skeleton for the paragraph text */}
                            <Skeleton variant="text" width={300} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={280} />
                        </div>
                    ) : (
                        <p className="pre-space hyphens">{description}</p>
                    )}
                </div>

                <Dialog
                    open={open}
                    onClose={handleDialogClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className="MUIDialog"
                >
                    <div className="d-flex space-between dialog-title profile-page">
                        <DialogTitle id="scroll-dialog-title">
                            Presentazione Azienda
                        </DialogTitle>
                        <Button
                            onClick={() => {
                                setSubmit(false)
                                handleDialogClose()
                            }}
                            color="primary"
                            className="close-dialog"
                        >
                            <CloseIcon />
                        </Button>
                    </div>

                    <DialogContent dividers={scroll === 'paper'}>
                        <div className="mt1">
                            <FotoProfilo
                                file={photo}
                                photoUrl={localPhotoURL}
                                setPhotoURL={setPhotoURL}
                                setFile={setPhoto}
                                {...{ photoURL }}
                            />
                        </div>
                        <h3 style={{ marginTop: '3rem' }}>Dati azienda</h3>
                        <div className="dialog-layout">
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Nome azienda"
                                    variant="filled"
                                    value={company_name}
                                    name="company_name"
                                    fullWidth
                                    required
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {formErrors.company_name}
                                </p>
                            </div>

                            <div>
                                <PlaceAutocomplete
                                    className="input-onboarding"
                                    setSetlected={setGeoLocationInfo}
                                    defaultValue={headquarters}
                                    label={'Sede principale azienda'}
                                />
                                <p className="input-error">
                                    {formErrors.headquarters}
                                </p>
                            </div>

                            <div>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="filled-age-native-simple">
                                        Numero dipendenti
                                    </InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        required
                                        value={size}
                                        onChange={handleFieldChange}
                                        inputProps={{
                                            name: 'size',
                                            // id: 'filled-age-native-simple',
                                        }}
                                    >
                                        <option
                                            value=""
                                            disabled
                                            hidden
                                            defaultValue
                                        ></option>
                                        <option value="0-9">1-9</option>
                                        <option value="10-49">10-49</option>
                                        <option value="50-249">50-249</option>
                                        <option value="250+">250+</option>
                                    </Select>
                                </FormControl>
                                <p className="input-error">{formErrors.size}</p>
                            </div>
                        </div>
                        <div className="sectorRecCompany">
                            <h3>SETTORE</h3>
                            <p>
                                Scegli quelle che ti servono dal menu a tendina.
                            </p>
                            <div
                                className="dialog-layout m0"
                                style={{ gridTemplateColumns: '1fr' }}
                            >
                                <SimpleAutocomplete
                                    options={Sectors}
                                    onChange={handleSectorChange}
                                />
                                <div className="hideOnMobile"></div>
                            </div>
                        </div>
                        <h3>Altre informazioni</h3>
                        <div className="dialog-layout">
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Sito Web"
                                    variant="filled"
                                    value={webpage}
                                    name="webpage"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Facebook"
                                    variant="filled"
                                    value={facebook}
                                    name="facebook"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Instagram"
                                    variant="filled"
                                    value={instagram}
                                    name="instagram"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Linkedin"
                                    variant="filled"
                                    value={linkedin}
                                    name="linkedin"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        {/* <div className="options-selected-container">
                        {sector.map((jobSectorSelected, index) => (
                            <div
                                className="d-flex align-center tags new-box grayTags"
                                key={index}
                            >
                                <p>{jobSectorSelected}</p>
                                <button
                                    className="tag-closebtn"
                                    onClick={(e) => {
                                        handleDeleteSector(e, index)
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        ))}
                    </div> */}
                        <div>
                            <h3>Raccontaci la tua azienda</h3>
                            {/* <div>
                        <TextareaAutosize
                            // minRows={100}
                            className='textarea'
                            // maxRows={5}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua."
                        />
                    </div> */}
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label=""
                                    variant="filled"
                                    name="description"
                                    value={description}
                                    fullWidth
                                    type="text"
                                    // required
                                    color="primary"
                                    multiline
                                    rows={4}
                                    className="smallp"
                                    onChange={handleFieldChange}
                                    placeholder="Descrivici la tua azienda"
                                    inputProps={{ maxLength: 1500 }}
                                    style={{ borderRadius: '0' }}
                                />
                                <p
                                    className={
                                        description?.length < 1500
                                            ? 'ml-auto maxLength'
                                            : 'ml-auto maxLength completed'
                                    }
                                >
                                    <span>{description?.length}</span> / 1500
                                    massimo
                                </p>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={
                                async (e) => {
                                    e.preventDefault()
                                    await updatePhoto(e)
                                    setSubmit(true)
                                    // handleClose()
                                }
                                // handleSubmit(e);
                                // setOpen(false);}
                            }
                            color="primary"
                        >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog>
                {confirmOpen && (
                    <EditFieldDialog
                        open={confirmOpen}
                        onClose={handleConfirmClose}
                    />
                )}
            </article>
        </>
    )
}

export default BioCompany
