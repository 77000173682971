import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextareaAutosize,
    TextField,
    Switch,
} from '@material-ui/core'
import React, { useEffect, useRef, useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import PdfDesign from '../KVGeneretor/Pdfdesign'
import { Link } from 'react-router-dom'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import ApiService from 'app/ApiService'
import FotoProfilo from './crop/utils/FotoProfilo'
import useStorage from 'app/hooks/useStorage'
import useAuth from 'app/hooks/useAuth'
import PhoneInput from 'react-phone-input-2'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Skeleton from '@material-ui/lab/Skeleton'
// import useGeoLocation from 'app/hooks/useGeoLocation';
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import validator from 'validator'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import EditIcon from 'app/components/Icons/EditIcon'
import EditIcon2 from '@material-ui/icons/Edit'
import Popover from '@material-ui/core/Popover'
import StorageContext from '../../../contexts/FirebaseStorageContext'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ProfileCompletion from './ProfileCompletion/ProfileCompletion'
import { makeStyles } from '@material-ui/core/styles'
import EditFieldDialog from './FooterProfile/EditField'
import Popper from '@material-ui/core/Popper'
import { useHistory } from 'react-router-dom'
import Pdfcandidato from '../KVGeneretor/Pdfcandidato'

const patenteData = [
    'Auto/Moto',
    'Camion',
    'Rimorchio',
    'Autobus',
    'Muletto',
    'Gru',
    'Nautica',
]

const useStyles = makeStyles(() => ({
    customIcon: {
        backgroundColor: '#FFF',
        fontSize: '1.5rem',
        borderRadius: '150px',
    },
}))

function GradientCircularProgress({ value, imageSrc }) {
    const classes = useStyles()

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {/* Background Circle */}
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    color: 'gray',
                }}
                size="9rem"
                thickness={3}
            />
            {/* Gradient Progress */}
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: '#ea6c3a',
                    position: 'absolute',
                    left: 0,
                    borderRadius: '50%',
                    width: '9rem !important',
                    height: '9rem  !important',
                    [`& .MuiCircularProgress-circle`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size="9rem"
                thickness={3}
            />

            {/* Center Image */}
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={imageSrc}
                    alt="Profile"
                    style={{
                        borderRadius: '50%',
                        width: '75%',
                        height: '75%',
                        objectFit: 'cover',
                        position: 'absolute',
                        margin: 'auto',
                        left: '1%',
                        right: '1%',
                    }}
                />

                {value === 100 && (
                    <CheckCircleIcon className={classes.customIcon} />
                )}
            </Box>

            {value !== 100 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: -5,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        background: '#ea6c3a',
                        width: '2rem',
                        borderRadius: '5px',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

function PresentazioneDialog({
    user,
    userinfo,
    getUserInfo,
    loading,
    progress,
    progressChecks,
    setOpenPreview,
    expList,
    hsList,
    ssList,
    langList,
    titleList,
    certList,
}) {
    const { photoURL, setPhotoURL } = useContext(StorageContext)
    const [localPhotoURL, setLocalPhotoURL] = useState(photoURL)
    const { uploadPhoto } = useContext(StorageContext)
    const [open, setOpen] = useState(false)
    const [updateState, setUpdateState] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElPoper, setAnchorElPoper] = useState(null)
    const [protectedCategory, setProtectedCategory] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClosePopover = () => {
        setAnchorEl(null)
        setAnchorElPoper(null)
    }
    const handleClick = (event) => {
        setAnchorElPoper(event.currentTarget)
    }
    const openPopover = Boolean(anchorEl)
    const openPoper = Boolean(anchorElPoper)
    const skillsPopover = open ? 'simple-popover' : undefined
    const locationPoper = open ? 'simple-poper' : undefined

    //fine
    const [userInfo, setUserInfo] = useState({
        candidate_guid: '',
        name: '',
        surname: '',
        phone: '',
        gender: '',
        summary: '',
        birth_date: '',
        email: '',
        location: '',
        geolocation: {},
        photo_url: '',
        description: '',
        driving_license: [],
        ral: '',
        ral_type: true,
        protected_category: 'false',
        created: '',
        lastUpdate: '',
        lastLocationUpdate: '',
    })
    // PER CROP
    const [photo, setPhoto] = useState()
    // per dialog di conferma (vuoi chiudere senza salvare?)
    const descriptionElementRef = useRef(null)
    const [openConfirm, setOpenConfirm] = useState(false)

    const { updateUserName } = useAuth()
    const history = useHistory()

    const {
        candidate_guid,
        name,
        surname,
        phone,
        gender,
        summary,
        birth_date,
        email,
        location,
        geolocation,
        photo_url,
        description,
        driving_license,
        ral,
        ral_type,
        protected_category,
        created,
        lastUpdate,
        lastLocationUpdate,
    } = userInfo

    // const { currentLocation } = useGeoLocation()

    // const getUserInfo = async () => {
    //     const response = await ApiService.get_candidatebyId(candidate_guid);
    //     const body = await response.json();
    //     console.log(body)

    //     try {
    //         setUserInfo({...userInfo,
    //             candidate_guid: body.candidate_guid,
    //             name: body.name,
    //             surname: body.surname,
    //             phone: body.phone,
    //             gender: body.gender,
    //             birth_date:  new Date(body.birth_date),
    //             email: body.email,
    //             location: body.location,
    //             description: body.description,
    //             driving_license: body.driving_license,
    //             ral: body.ral,
    //             created: body.created,
    //             lastUpdate: body.lastUpdate
    //         });
    //         // setUserInfo({...userInfo, body});
    //         setAppState(false)
    //         //console.log("HEY " ,userPosts.data);
    //         //setAppState({ loading: true });
    //         // console.log("appstate: " ,userPosts);
    //     } catch (err) {
    //         console.error(err.message);
    //         // setAppState({ loading: false });
    //     }
    // };

    useEffect(() => {
        setLocalPhotoURL(photoURL)
        setUserInfo({ ...userInfo, photo_url: user.photoURL })
    }, [photoURL])

    const onChange = (event) => {
        event.preventDefault()
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const onSelect = (event) => {
        event.preventDefault()
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value.sort(),
        })
        console.log(userInfo)
    }

    const changeNum = (num) => {
        setUserInfo({
            ...userInfo,
            phone: num,
        })
        console.log(userInfo)
    }

    const setGeoLocationInfo = (place, geolocation) => {
        setUserInfo({
            ...userInfo,
            location: place,
            geolocation: geolocation,
        })
    }

    const handleClickOpenConfirm = () => {
        setOpenConfirm(true)
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    const handleDateChange = (date) => {
        // se digiti con la tastiera la data non so perchè
        // ti segna il giorno prima rispetto a quello che hai digitato
        // con questo si risolve
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }

        setUserInfo({ ...userInfo, birth_date: date })
    }

    const handleClickOpen = (scrollType) => () => {
        setOpen(true)
        setUserInfo({ ...userInfo, lastUpdate: new Date() })
        setScroll(scrollType)
    }

    const handleClose = () => {
        setPhoto(null)
        if (user?.photoURL) {
            setPhotoURL(`${user.photoURL}.jpg`)
            setUserInfo({ ...userInfo, photo_url: user.photoURL })
        } else setPhotoURL('images/prof.png')
        getUserInfo()
        setOpen(false)
        setFormErrors({})
    }

    const handleSubmit = async () => {
        if (photo) {
            const url = await uploadPhoto(photo)
            // console.log(url)
            setUserInfo({ ...userInfo, photo_url: url })
            // console.log(userInfo)
        }
        await updateUserName(name)
        setUpdateState(true)
        // const res = await ApiService.update_candidate(userInfo)
        // if (res.ok) getUserInfo()
        setOpen(false)
    }

    const updateData = async () => {
        const res = await ApiService.update_candidate(userInfo)
        if (res.ok) getUserInfo()
        setUpdateState(false)
    }

    const calculate_age = () => {
        const today = new Date()
        const birthDate = new Date(birth_date)
        var age_now = today.getFullYear() - birthDate.getFullYear()
        var m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--
        }
        // console.log(age_now);
        return age_now
    }

    const computeDate = () => {
        if (!lastLocationUpdate) return true
        const today = new Date()
        const lastUpt = new Date(lastLocationUpdate)
        const timespan = today - lastUpt
        if (timespan > 30 * 24 * 60 * 60 * 1000) return true
        else return false
    }

    const validate = () => {
        const errors = {}

        if (!name) errors.name = 'Questo campo non può essere nullo'
        if (!surname) errors.surname = 'Questo campo non può essere nullo'
        if (!birth_date) errors.birth_date = 'Questo campo non può essere nullo'
        if (birth_date && new Date(birth_date).toString() === 'Invalid Date')
            errors.birth_date = 'Inserisci una data valida '
        else if (birth_date && new Date(birth_date) > new Date())
            errors.birth_date =
                'La data di nascita non può essere successiva al giorno corrente'
        // else if (new Date().getFullYear() - new Date(birth_date).getFullYear() < 16) errors.birth_date = "Devi avere almeno 16 anni di età per iscriverti"
        else if (
            birth_date &&
            Math.floor(
                (new Date().getTime() -
                    new Date(
                        birth_date.getFullYear(),
                        birth_date.getMonth(),
                        birth_date.getDate()
                    ).getTime()) /
                    31557600000
            ) < 18
        )
            errors.birth_date =
                'Devi avere almeno 16 anni di età per iscriverti'
        else if (
            birth_date &&
            new Date().getFullYear() - new Date(birth_date).getFullYear() > 100
        )
            errors.birth_date = 'Inserisci una data valida'
        if (!gender) errors.gender = 'Questo campo non può essere nullo'
        if (!location) errors.location = 'Questo campo non può essere nullo'
        if (!phone) errors.phone = 'Questo campo non può essere nullo'
        else if (!validator.isMobilePhone(phone))
            errors.phone = 'Il formato di numero inserito non è valido'
        return errors
    }

    // useEffect(() => {
    //     if (currentLocation)
    //     {
    //         // console.log("current location: ", currentLocation)
    //         setUserInfo({
    //           ...userInfo,
    //           geolocation: {
    //             'lat': currentLocation?.latitude,
    //             'lon': currentLocation?.longitude
    //         }
    //         })
    //     }
    // }, [currentLocation])

    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() - 16)

    const handleSwitchChange = (event) => {
        setProtectedCategory(event.target.checked)
    }
    const handleFieldChange = (e) => {
        setIsEdited(true)
        onChange(e)
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleNavigateToPopup = () => {
        history.push('/popup', {
            userInfo,
            expList,
            hsList,
            ssList,
            langList,
            titleList,
            certList,
        })
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        // if (user !== null)
        // {
        //     setUserInfo({
        //   ...userInfo,
        //   email: user.email,
        //   candidate_guid: user.uid
        // })
        // setAppState(true)

        if (user?.photoURL) {
            setLocalPhotoURL(user.photoURL)
            setPhotoURL(user.photoURL)
            setUserInfo({ ...userInfo, photo_url: user.photoURL })
        }
    }, [user, setPhotoURL])

    useEffect(() => {
        setUserInfo(userinfo)
    }, [userinfo])

    useEffect(() => {
        if (updateState) updateData()
    }, [updateState])

    return (
        <>
            <article className="profile-card section-lightGray">
                <div
                    className="d-flex align-center"
                    style={{ position: 'relative' }}
                >
                    <button
                        className="icon-btn shift-icon"
                        style={{ position: 'absolute', right: 0 }}
                        onClick={handleClickOpen('paper')}
                    >
                        <EditIcon />
                    </button>
                    <div>
                        {loading ? (
                            <Skeleton variant="circle">
                                <img alt="" className="profile-photo" />
                            </Skeleton>
                        ) : (
                            <>
                                <GradientCircularProgress
                                    value={progress}
                                    imageSrc={localPhotoURL}
                                />
                            </>
                        )}
                    </div>

                    <ul>
                        {/* <li>{name} {surname}</li> */}

                        {loading ? (
                            <div>
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={110} />
                                <Skeleton variant="text" width={120} />
                                <Skeleton variant="text" width={130} />
                                <Skeleton variant="text" width={210} />
                            </div>
                        ) : (
                            <div>
                                <>
                                    <li>
                                        <h4>{summary}</h4>
                                    </li>
                                    <li>
                                        📌{' '}
                                        {location
                                            ? location.split(',')[0]
                                            : 'Non Specificato'}
                                    </li>
                                    <li>
                                        🚘 Patenti:{' '}
                                        {driving_license?.length > 0
                                            ? driving_license.join(', ')
                                            : 'Non Specificato'}
                                    </li>
                                    <li>
                                        💰 Aspettativa salariale:{' '}
                                        {ral_type
                                            ? 'Mensile (netto)'
                                            : 'Annuo (lordo)'}{' '}
                                        {ral ? ral + ' €' : 'Non Specificato'}
                                    </li>
                                </>
                                {/* <li>{calculate_age()} Anni</li>
                                <li>
                                    Sesso:{' '}
                                    {gender === 'male'
                                        ? 'Uomo'
                                        : gender === 'female'
                                        ? 'Donna'
                                        : 'Non Specificato'}
                                </li>
                                <li>
                                    Categoria Protetta:{' '}
                                    {protected_category &&
                                    protected_category === 'True'
                                        ? 'Sì'
                                        : 'No'}
                                </li> */}
                                {/* <div className='tags-container'> */}
                                {/*<div className="tags-column">
                                    <li className="tags">
                                        {location
                                            ? location
                                            : 'Non Specificato'}
                                    </li>
                                    <li className="tags">
                                        Patente{' '}
                                        {driving_license?.length > 0
                                            ? driving_license.join(', ')
                                            : 'Non Specificato'}
                                    </li>
                                    <li className="tags">
                                        💰 Aspettativa salariale:
                                        {ral_type
                                            ? 'Mensile (netto)'
                                            : 'Annuo (lordo)'}{' '}
                                        {ral ? ral + ' €' : 'Non Specificato'}
                                    </li>
                                </div>
                                */}
                            </div>
                        )}
                    </ul>
                </div>
                <div>
                    {loading ? (
                        <div className="buttons-Candidate">
                            {/* Skeleton for the buttons */}
                            <Skeleton
                                variant="rectangular"
                                width={180}
                                height={50}
                                style={{ marginRight: 16 }}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={180}
                                height={50}
                                style={{ marginRight: 16 }}
                            />
                        </div>
                    ) : (
                        <div
                            className={
                                progress === 100
                                    ? 'button-candidate'
                                    : 'buttons-Candidate'
                            }
                        >
                            <div></div>
                            {progress !== 100 && (
                                <ProfileCompletion
                                    progressChecks={progressChecks}
                                />
                            )}
                            <div
                                className="btn-profile blue"
                                onClick={() => setOpenPreview(true)}
                            >
                                <RemoveRedEyeIcon />
                                Visualizza anteprima
                            </div>
                            {/* <Pdfcandidato
                                userInfo={userinfo}
                                expList={expList}
                                hsList={hsList}
                                ssList={ssList}
                                langList={langList}
                                titleList={titleList}
                                certList={certList}
                            /> */}

                            {/* <button
                                className="btn-navigate-popup pdf-button"
                                onClick={handleNavigateToPopup}
                                style={{ display: 'none' }}
                            >
                                Scarica KV
                            </button> */}
                        </div>
                    )}

                    {loading ? (
                        <div>
                            {/* Skeleton for the paragraph text */}
                            <Skeleton variant="text" width={300} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={280} />
                        </div>
                    ) : (
                        <p className="pre-space hyphens">{description}</p>
                    )}
                </div>

                <Dialog
                    open={open}
                    onClose={handleDialogClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className="MUIDialog"
                >
                    <div className="d-flex space-between dialog-title profile-page">
                        <DialogTitle
                        // id="scroll-dialog-title"
                        >
                            Presentazione
                        </DialogTitle>

                        <Button
                            onClick={() => {
                                //*handleClickOpenConfirm*/
                                handleDialogClose()
                            }}
                            color="primary"
                            className="close-dialog"
                        >
                            <Closebtnblue />
                        </Button>

                        {/* DIALOG PER CHIUDERE SENZA SALVARE
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="confirm-dialog small-dialog"
                        >
                            <DialogTitle
                            // id="alert-dialog-title"
                            >
                                {'Sicuro?'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                // id="alert-dialog-description"
                                >
                                    Confermi di voler uscire senza salvare le
                                    modifiche?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        handleCloseConfirm()
                                        handleClose()
                                    }}
                                    color="primary"
                                >
                                    Conferma
                                </Button>
                                <Button
                                    onClick={handleCloseConfirm}
                                    color="secondary"
                                    autoFocus
                                >
                                    Annulla
                                </Button>
                            </DialogActions>
                        </Dialog> */}
                    </div>

                    <DialogContent dividers={scroll === 'paper'}>
                        {loading ? (
                            <Skeleton
                                variant="circle"
                                width={180}
                                height={180}
                            />
                        ) : (
                            <>
                                <FotoProfilo
                                    file={photo}
                                    photoUrl={photoURL}
                                    setPhotoURL={setPhotoURL}
                                    setFile={setPhoto}
                                    {...{ photoURL }}
                                />
                            </>
                        )}
                        {loading ? (
                            <Skeleton variant="text" width="50%" />
                        ) : (
                            <h3>Dati anagrafici</h3>
                        )}

                        <div
                            className="dialog-layout"
                            style={{ marginBottom: '1rem' }}
                        >
                            {loading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                />
                            ) : (
                                <div>
                                    <TextField
                                        // id="filled-basic"
                                        label="Nome"
                                        variant="filled"
                                        name="name"
                                        fullWidth
                                        required
                                        color="primary"
                                        value={name}
                                        onChange={handleFieldChange}
                                    />
                                    <p className="input-error">
                                        {formErrors.name}
                                    </p>
                                </div>
                            )}

                            {loading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                />
                            ) : (
                                <div>
                                    <TextField
                                        //  id="filled-basic"
                                        value={surname}
                                        label="Cognome"
                                        name="surname"
                                        variant="filled"
                                        fullWidth
                                        required
                                        color="primary"
                                        onChange={handleFieldChange}
                                    />
                                    <p className="input-error">
                                        {formErrors.surname}
                                    </p>
                                </div>
                            )}

                            {loading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                />
                            ) : (
                                <div className="custom-input-padding">
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={itLocale}
                                    >
                                        <KeyboardDatePicker
                                            margin="normal"
                                            // id="date-picker-dialog"
                                            // placeholder="Data di nascita"
                                            label="Data di nascita"
                                            format="dd/MM/yyyy"
                                            value={birth_date || null}
                                            autoOk
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            locale="it-IT"
                                            fullWidth
                                            required
                                            disableFuture
                                            invalidDateMessage=""
                                            maxDate={new Date()}
                                            maxDateMessage=""
                                            minDateMessage=""
                                        />
                                    </MuiPickersUtilsProvider>
                                    <p className="input-error">
                                        {formErrors.birth_date}
                                    </p>
                                </div>
                            )}

                            {loading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                />
                            ) : (
                                <div>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel
                                            required
                                            htmlFor="filled-age-native-simple"
                                        >
                                            Genere
                                        </InputLabel>
                                        <Select
                                            native
                                            fullWidth
                                            value={gender || ''}
                                            name="gender"
                                            onChange={handleFieldChange}
                                            inputProps={{
                                                name: 'gender',
                                                // id: 'filled-age-native-simple',
                                            }}
                                        >
                                            <option
                                                hidden
                                                aria-label="None"
                                                value={null}
                                            />
                                            <option value="male">Uomo</option>
                                            <option value="female">
                                                Donna
                                            </option>
                                            <option value="other">
                                                Preferisco non specificare
                                            </option>
                                        </Select>
                                    </FormControl>
                                    <p className="input-error">
                                        {formErrors.gender}
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="dialog-layout">
                            {loading ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                />
                            ) : (
                                <div>
                                    <TextField
                                        // id="filled-basic"
                                        label="Sommario"
                                        variant="filled"
                                        name="summary"
                                        value={summary}
                                        placeholder="Ad esempio: addetto vendite con esperienza"
                                        // helperText="Inserisci il tuo ruolo, chi sei, cosa fai"
                                        onChange={handleFieldChange}
                                        fullWidth
                                        color="primary"
                                        inputProps={{ maxLength: 60 }}
                                    />
                                    <p
                                        className={
                                            summary?.length < 60
                                                ? 'ml-auto maxLength'
                                                : 'ml-auto maxLength completed'
                                        }
                                    >
                                        <span>{summary?.length}</span> / 60
                                        massimo
                                    </p>
                                </div>
                            )}
                        </div>

                        {loading ? (
                            <Skeleton variant="text" width={100} />
                        ) : (
                            <h3>Cerco lavoro</h3>
                        )}

                        <div className="dialog-layout">
                            {/* <TextField
                                // id="filled-basic" 
                                label="Città"
                                variant="filled"
                                name="location"
                                value={location}
                                onChange={onChange}
                                required
                                fullWidth
                                color="primary"
                            /> */}
                            <div>
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                    />
                                ) : (
                                    <div className="input-select d-flex">
                                        <div
                                            style={{
                                                width: '-webkit-fill-available',
                                            }}
                                        >
                                            <PlaceAutocomplete
                                                setSetlected={
                                                    setGeoLocationInfo
                                                }
                                                defaultValue={location}
                                                label={'Località'}
                                                isCandidate={true}
                                                disabled={!computeDate()}
                                            />
                                            <p className="input-error">
                                                {formErrors.location}
                                            </p>
                                        </div>

                                        <div className="popover">
                                            <button
                                                aria-describedby={locationPoper}
                                                onClick={handleClick}
                                            >
                                                ?
                                            </button>
                                            <Popover
                                                id={locationPoper}
                                                open={openPoper}
                                                anchorEl={anchorElPoper}
                                                onClose={handleClosePopover}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <p>
                                                    Puoi cambiare la località
                                                    una volta ogni 30 giorni
                                                </p>
                                            </Popover>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="input-select d-flex">
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                    />
                                ) : (
                                    <TextField
                                        value={ral}
                                        label="con salario minimo"
                                        name="ral"
                                        variant="filled"
                                        fullWidth
                                        color="primary"
                                        onChange={handleFieldChange}
                                        className="custom-radius-text"
                                    />
                                )}
                                {loading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                    />
                                ) : (
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        className="custom-radius-select"
                                    >
                                        {/* <InputLabel htmlFor="salario-select">salario</InputLabel> */}
                                        <Select
                                            native
                                            fullWidth
                                            value={ral_type || 'false'}
                                            name="ral_type"
                                            onChange={handleFieldChange}
                                            inputProps={{
                                                name: 'ral_type',
                                                // name: '',
                                                // id: '',
                                            }}
                                        >
                                            {/* <option hidden aria-label="None" value={null} />                                     */}
                                            <option value={false}>
                                                Annuo (lordo)
                                            </option>
                                            <option value={true}>
                                                Mensile (netto)
                                            </option>
                                        </Select>
                                    </FormControl>
                                )}

                                <div className="popover">
                                    <button
                                        aria-describedby={skillsPopover}
                                        onClick={handleClickPopover}
                                    >
                                        ?
                                    </button>
                                    <Popover
                                        id={skillsPopover}
                                        open={openPopover}
                                        anchorEl={anchorEl}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <p>
                                            il salario ci servirà a proporti
                                            offerte/candidati in linea con le
                                            tue aspettative
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <Skeleton variant="text" width={100} />
                        ) : (
                            <h3>Altre informazioni</h3>
                        )}

                        <div className="dialog-layout">
                            {loading ? (
                                <>
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                    />
                                </>
                            ) : (
                                <>
                                    <FormControl>
                                        <InputLabel id="demo-mutiple-checkbox-label">
                                            Patenti in mio possesso
                                        </InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={driving_license || null}
                                            onChange={onSelect}
                                            name="driving_license"
                                            // input={<Input />
                                            renderValue={(selected) =>
                                                selected.join(',  ')
                                            }
                                            // MenuProps={MenuProps}
                                        >
                                            {patenteData.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            driving_license?.indexOf(
                                                                name
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={name}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <div id="mui-phone" className="userdata">
                                        <PhoneInput
                                            country={'it'}
                                            preferredCountries={['it']}
                                            value={phone}
                                            onChange={changeNum}
                                            placeholder={'Recapito telefonico*'}
                                        />
                                        <p className="input-error">
                                            {formErrors.phone}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>

                        {/* radio mui */}
                        {loading ? (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={50}
                            />
                        ) : (
                            <FormControlLabel
                                className="protected"
                                control={
                                    <Switch
                                        checked={protectedCategory}
                                        onChange={handleSwitchChange}
                                        className="switch-slider protected"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked':
                                                {
                                                    color: '#FFFFFF',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'rgba(255, 255, 255, 0.08)',
                                                    },
                                                },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                {
                                                    backgroundColor: '#F06031',
                                                },
                                            '& .MuiSwitch-switchBase': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: '#EEEEEE',
                                            },
                                            '& .MuiSwitch-thumb': {
                                                boxShadow: 'none',
                                            },
                                        }}
                                    />
                                }
                                label="Appartengo a una categoria protetta"
                                labelPlacement="end"
                                sx={{
                                    justifyContent: 'space-between',
                                    '.MuiFormControlLabel-label': {
                                        color: '#4b4c55',
                                        fontWeight: '600',
                                    },
                                }}
                            />
                        )}

                        {loading ? (
                            <Skeleton variant="text" width={100} />
                        ) : (
                            <h3>La mia biografia</h3>
                        )}
                        {loading ? (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={50}
                            />
                        ) : (
                            <div>
                                <TextField
                                    // id="filled-basic"
                                    label="Descriviti"
                                    variant="filled"
                                    name="description"
                                    value={description}
                                    onChange={handleFieldChange}
                                    fullWidth
                                    color="primary"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 1500 }}
                                />
                                <p
                                    className={
                                        description?.length < 1500
                                            ? 'ml-auto maxLength'
                                            : 'ml-auto maxLength completed'
                                    }
                                >
                                    <span>{description?.length}</span> / 1500
                                    massimo
                                </p>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {/* <Button 
                            onClick={handleSubmit}
                            color="primary">
                            Salva
                        </Button> */}
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setSubmit(true)
                            }}
                            color="primary"
                        >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog>
                {confirmOpen && (
                    <EditFieldDialog
                        open={confirmOpen}
                        onClose={handleConfirmClose}
                    />
                )}
            </article>
        </>
    )
}

export default PresentazioneDialog
